<!--  -->
<template>
  <div class="card">
    <van-card v-for="user in userList" :key="user.key" :thumb="user.headimg" @click="goToUserInfo(user.uid)">
      <template #title>
        <div>
          <b>{{ user.nickName }}</b><van-tag class="tag_float_right " v-if="user.dataTime">
            {{ timeStamp(user.dataTime) }} </van-tag>
          <!-- <van-tag class="tag_float_right tag" color="#969799" plain type="primary">编号:{{ user.uid }}</van-tag> -->
        </div>
      </template>

      <template #tag>
        <van-tag class="tag" plain type="danger" v-if="user.tagTop">{{
          user.tagTop
        }}</van-tag>
        <van-tag plain type="danger">VIP{{ user.vip }}</van-tag>
      </template>

      <template #tags>
        <div class="info_fk">
          <van-tag class="tag_fk" plain type="primary">{{ user.age }}</van-tag>
          <van-tag class="tag_fk" plain type="primary">{{ user.height }}cm</van-tag>
          <van-tag class="tag_fk" plain type="primary">{{
            user.education
          }}</van-tag>
          <van-tag class="tag_fk" plain type="primary">{{
            user.workUnitType
          }}</van-tag>
          <van-tag class="tag_fk" plain type="primary">{{ user.job }}</van-tag>
        </div>
        <div class="info_fk" v-if="user.interest">
          <b class="tag">喜欢</b>
          <van-tag class="tag_fk" plain type="success" v-for="hobby in user.interest" :key="hobby">{{ hobby }}</van-tag>
        </div>
        <div class="info_fk" v-if="user.lookingFor">
          <b class="tag">寻找</b>
          <van-tag class="tag_fk" plain type="danger">{{
            user.lookingFor.ageRange
          }}</van-tag>
          <van-tag class="tag_fk" plain type="danger">{{
            user.lookingFor.heightRange
          }}</van-tag>
          <van-tag class="tag_fk" plain type="danger">{{
            user.lookingFor.annualIncomeRange
          }}</van-tag>
        </div>
      </template>
    </van-card>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import { Card } from "vant";
import { Tag } from "vant";

Vue.use(Tag);
Vue.use(Card);
export default {
  // import引入的组件需要注入到对象中才能使用
  name: "CardComponent",
  components: {},
  props: {
    userList: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    // 这里存放数据
    return {};
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    getList() { },

    goToUserInfo(uid) {
      this.$router.push({
        path: "/user/infoPage/InfoOne",
        query: { uid },
      });
    },
    //时间戳转 年月日时分秒
    timeStamp(time) {
      const date = new Date(time);
      const Y = date.getFullYear() + "-";
      const M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      const D = date.getDate() + " ";
      const h = date.getHours() + ":";
      const m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      const s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + ":" + s;
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    const label = document.querySelectorAll(".van-image__img");
    label.forEach((item) => {
      item.style.width = "auto";
    });

    const label2 = document.querySelectorAll(".van-card__content");
    label2.forEach((item) => {
      item.style.paddingLeft = "8px";
    });

    const label3 = document.querySelectorAll(".van-card");
    label3.forEach((item) => {
      item.style.fontSize = "18px";
    });
  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类

.info_fk {
  margin-top: 7px;
}

.tag_fk {

  //从第二条开始才进行边距
  &:nth-of-type(n + 2) {
    margin-left: 5px;
  }
}

.tag {
  margin-right: 5px;
}

.tag_float_right {
  float: right;
}
</style>