import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import baseConfig from './common/config/baseConfig';
import VueWechatTitle from 'vue-wechat-title'; 
import VantRequestPlugin from './common/util/js/vant-request'; // 引入插件


Vue.use(VueWechatTitle);
Vue.config.productionTip = false;
Vue.use(VantRequestPlugin); // 注册插件
Vue.prototype.$baseConfig = baseConfig; // 挂载到Vue.prototype上

// 根据当前环境加载对应的配置文件
(async () => {
  try {

    //定义$config变量
    const envConfig = await import(`./common/config/config.${process.env.NODE_ENV}`);
    Vue.prototype.$config = envConfig.default; // 确保使用默认导出
    console.log(envConfig);

    new Vue({
      render: h => h(App),
      router, // 注册路由器vue-router
      store, // 使用上vuex
    }).$mount('#app');

  } catch (error) {
    console.error('Failed to load config file:', error);
  }
})();

