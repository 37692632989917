<!--活动支付金额页面  -->
<template>
  <div class="">
    <div class="card">
      <van-card
        :key="orderInfo.orderId"
        num="1"
        :price="orderInfo.totalAmount"
        :desc="orderInfo.remark"
        :title="orderInfo.title"
        :thumb="orderInfo.img"
      >
        <template #tags>
          <div>订单编号：{{ orderInfo.orderSn }}</div>
          <div>下单时间：{{ formatTime(orderInfo.createdAt) }}</div>
        </template>
      </van-card>
    </div>

    <div>
      <van-submit-bar
        :disabled="button_disabled"
        :price="getMoney(orderInfo.totalAmount)"
        button-text="点击支付订单"
        tip-icon="info-o"
        @submit="onSubmit"
      />
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import wx from "weixin-js-sdk"; //微信sdk依赖
import { SubmitBar } from "vant";
import { Toast } from "vant";
import { reqWeixinPaySendOrder, reqSelOrder } from "@/api/api"; 
import util from "@/common/util/js/util";
import { Card } from "vant";

Vue.use(Card);
Vue.use(SubmitBar);
Vue.use(Toast);
export default {
  //name填入本组件名字
  name: "PayPage",
  // components: {《组件名称》},
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      enrollmentId: "",
      button_disabled: false,
      orderId: "",
      orderInfo: {},
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    onSubmit() {
      this.button_disabled = true;
      //创建预付订单，使用预付订单prepay_id生成支付签名，发起微信支付请求
      this.createPrepayOrder(this.orderId);
    },
    //判断是否提交过微信预付订单，没有则创建预付订单
    async createPrepayOrder(orderId) {
      //判断是否提交过微信预付订单
      // 创建预付订单，使用预付订单prepay_id生成支付签名，发起微信支付请求
      const params = {
        orderId: orderId,
      }; //请求参数
      const result = await reqWeixinPaySendOrder(params);
      if (result.code === 0) {
        //创建预付订单成功，发起微信支付请求
        console.log(result.data);
        this.wechatPay(result.data);
      }
    },

    wechatPay(data) {
      //发起微信支付
      wx.config({
        debug: false, // 开启调试模式
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timeStamp.toString(), // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名
        jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
      });
      wx.ready(function () {
        wx.chooseWXPay({
          timestamp: data.timeStamp.toString(), // 支付签名时间戳
          nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
          package: data.packageStr, // 统一支付接口返回的prepay_id参数值，提交格式如：
          //prepay_id: "prepay_id="+data.prepay_id, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***（注意：需要urlencode转码）
          signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: data.paySign, // 支付签名
          success: function (res) {
            // 支付成功后的回调函数
            console.log(res);
            Toast.fail(res);
          },
          fail: function (res) {
            //失败回调函数
            console.log(res);
            Toast.fail(res);
          },
        });
      });
      wx.error(function (res) {
        /*alert("config信息验证失败");*/
        console.log(res);
        Toast.fail(res);
      });
    },
    async getOrderInfo(orderId) {
      const params = {
        orderId:orderId,
      }; //请求参数
      const result = await reqSelOrder(params);
      if (result.code === 0) {
        console.log("成功:", result.data);
        //把查到数据添加到当前页面数据对象中,转码
        if (result.data.records.length > 0) {
          result.data.records.forEach((element) => {
            element.key = element.orderId;
            //处理是充值vip订单还是活动订单
            if (element.payType == 0) {
              element.title = "充值VIP";
              element.img = require("./img/vip.svg");
            } else {
              element.title = "活动报名";
              element.img = require("./img/huodong.svg");
            }
            this.orderInfo = element;
          });
        }
      }
    },
    getMoney(money) {
      return money * 100;
    },
    formatTime(time) {
      return util.timeStamp(time); // 在方法中引用 util
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    //从传入的参数中获取到提交的活动订单id
    this.orderId = this.$route.query.orderId;
    console.log(this.orderId);
    this.getOrderInfo(this.orderId);
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
</style>