<!-- 认证上传图片 -->
<template>
    <div class='authPage'>
        <div class="authPage_content">
            <div class="authPage_content_title">点击照片框上传
                <b>{{ this.authTypeText }}</b>
                认证审核照片，供审核员官网审核查询。</div>
            <van-uploader v-model="fileList" multiple :after-read="afterRead" :before-delete="beforeDelete" :max-count="1" />
            <div class="authPage_content_btn"><van-button type="info" :disabled="!(status === 1 || status === 3)"  @click="SubmitEvent">{{authMap[status]}}</van-button> </div>
            <div class="authPage_content_tip">(温馨提示：请上传证件编号，姓名清晰照片，否则审核不通过</div>
            <div class="authPage_content_tip">审核通过后，将获得相应特权</div>
            <div class="authPage_content_tip">审核不通过，可重新上传</div>
            <div class="authPage_content_tip">审核将在24小时内处理</div>
            <div class="authPage_content_tip">审核结果将以微信提示形式通知您</div>
            <div class="authPage_content_tip">个人所得税提供app页面截图，内容包含公司和收入</div>
            <div class="authPage_content_tip">其他信息提供证件图片内容包含姓名、证件号等信息)</div>
        </div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from 'vue';
import { Uploader } from 'vant';
import { Button } from 'vant';
import { Dialog } from 'vant';
import { reqSelAuthOrderLog,reqDelAuthUserImg,reqUploadAuthUserImg } from "@/api/api";
import { sendImage } from "@/api/http";
import compressFile from "@/common/util/js/imageUtils.js";
import { authMap } from "@/common/constants/constants.js";
Vue.use(Button);
Vue.use(Uploader);
export default {
    //name填入本组件名字
    name: 'authPage',
    // components: {《组件名称》},
    // import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return {
            authObject: [],
            fileList: [],
            authType: '',
            authTypeText:"",
            status : 0,
            authMap: authMap,
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        
        SubmitEvent() {
            console.log(this.fileList);
            if (this.fileList.length > 0) {
                this.SubmitEvent();
                Dialog({ message: '功能调试中' });
            } else {
                Dialog({ message: '请上传审核图片' });
            }
        },



    beforeDelete(file) {  
      console.log("删除图片",file);
      this.delImg(this.authType); 
 
      return true;
    },
    
     async afterRead(files) {  
       // 检查文件是否为数组，如果不是则转换为数组
      if (!Array.isArray(files)) {
        files = [files];
      }
  
      for (const file of files) {
   
        file.status = "uploading";
        file.message = "上传中...";

        // 压缩图片
        console.log("file压缩开始");
        const file_compress = await compressFile(file.file);
        console.log("file压缩完成");

        // 上传图片至服务器
        const formData = new FormData();
        formData.append("image", file_compress, file.name);
        formData.append("authType", this.authType); 
        
        let timeoutId = setTimeout(() => {
          file.status = "failed";
          file.message = "上传失败";
        }, 1000);

        sendImage(
          this.$config.apiUrl + "/api/resources/authUser/upload/img", // 请求地址
          formData
        )
          .then((res) => {
            console.log("res", res);
            if (res.code == 0) {
              clearTimeout(timeoutId); // 清除定时器
              file.status = "done";
              file.message = "上传成功";
            }
          })
          .catch((error) => {
            console.error("上传失败", error);
            clearTimeout(timeoutId); // 清除定时器
            file.status = "failed";
            file.message = "上传失败";
          });
      }
    },


    //获取数据方法
    async getData(authType) { 
      const params = {
        authType: authType, //认证类型 
      }; //请求参数
      const result = await reqSelAuthOrderLog(params);
      console.log("查询客户信息:", result.code);
      if (result.code === 0) {
        console.log("成功:", result.data);
        //把查到数据添加到当前页面数据对象中,转码
        if (result.data?.data?.length > 0) {
            result.data.records.forEach((element) => {
                this.fileList.plus(element.imageUrl);
                this.status = element.status;
                return this.authObject;

            });
         
        }
      }
    },
    //删除图片方法
    async delImg(authType) { 
      const params = {
        authType: authType, //认证类型 
      }; //请求参数
      const result = await reqDelAuthUserImg(params);
      console.log("查询客户信息:", result.code);
      if (result.code === 0) { 
        console.log("删除成功:", result.data);
      }
    },
    //上传图片方法
    async uploadImg(authType) { 
      const params = {
        authType: authType, //认证类型 
      }; //请求参数
      const result = await reqUploadAuthUserImg(params);
      console.log("查询客户信息:", result.code);
      if (result.code === 0) { 
        console.log("上传成功:", result.data);
      }
    },

  
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.authType = this.$route.query.authType;
        if(this.authType=='1'){
            this.authTypeText="个人所得税";
        }else if(this.authType=='4'){
            this.authTypeText="房子";
        }else if(this.authType=='3'){
            this.authTypeText="车子";
        }else if(this.authType=='2'){
            this.authTypeText="学历";
        }
        //根据路由传值获取认证类型
        this.getData(this.authType);

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.authPage_content_title {
    margin: 10px 10px;
}

.authPage_content {
    text-align: center;
}
</style>