<!-- 我的活动 -->
<template>
    <div class="myActivity">
        <van-tabs v-model="active">
            <van-tab title="报名通过/进行中的">
                <van-list v-model="success_loading" :finished="success_finished" finished-text="没有更多了"
                    @load="success_onLoad">
                    <div class="card">
                        <van-card v-for="item in success_list" :key="item.activityDto.activityId"
                            :title="item.activityDto.title" :thumb="item.activityDto.activityImageUrl">
                            <template #price>
                                <div><span class="van-card__price-currency">¥</span>
                                    <span class="van-card__price-integer">{{ item.activityDto.totalFee }}</span>
                                    <span class="van-card__price-decimal">
                                        <span v-if="item.activityEnrollmentFunctionDto.paymentStatus == 1">
                                            (已支付定金¥{{ item.activityDto.deposit }}元)</span>
                                        <span v-else-if="item.activityEnrollmentFunctionDto.paymentStatus == 2">
                                            (已全额支付)</span>
                                        <span
                                            v-else-if="item.activityEnrollmentFunctionDto.paymentStatus == 3">(已退款)</span>
                                    </span>
                                </div>
                            </template>
                            <template #desc>
                                <div>{{ item.activityDto.activityDetails }}</div>
                                <div>活动开始时间：{{ formatTime(item.activityDto.eventStartTime) }}</div>
                                <div>活动结束时间：{{ formatTime(item.activityDto.eventEndTime) }}</div>
                                <div>活动地点：{{ item.activityDto.activityAddress }}</div>
                            </template>
                            <template #footer>
                                <div>
                                    <div v-if="item.activityEnrollmentFunctionDto.paymentStatus == 0"><van-button
                                            type="info" size="mini">未支付，点击全额支付</van-button></div>
                                    <div v-else-if="item.activityEnrollmentFunctionDto.paymentStatus == 1">
                                        <van-button type="info" size="mini">点击支付¥{{
                                            Number((item.activityDto.totalFee -
                                                item.activityDto.deposit)).toFixed(2) }}尾款</van-button>
                                    </div>
                                    <div v-else-if="item.activityEnrollmentFunctionDto.paymentStatus == 2">
                                        <van-button type="primary" size="mini"
                                            @click="showQrcode(item.activityEnrollmentFunctionDto.code)">签到码</van-button>
                                    </div>
                                    <div v-else-if="item.activityEnrollmentFunctionDto.paymentStatus == 3">已退款</div>

                                </div>
                            </template>
                        </van-card>
                    </div>
                </van-list>
            </van-tab>
            <van-tab title="报名失败的">
                <van-list v-model="fail_loading" :finished="fail_finished" finished-text="没有更多了" @load="fail_onLoad">
                    <div class="card">
                        <van-card v-for="item in fail_list" :key="item.activityDto.activityId"
                            :title="item.activityDto.title" :thumb="item.activityDto.activityImageUrl">
                            <template #price>
                                <div><span class="van-card__price-currency">¥</span>
                                    <span class="van-card__price-integer">{{ item.activityDto.totalFee }}</span>
                                    <span class="van-card__price-decimal">
                                        <span v-if="item.activityEnrollmentFunctionDto.paymentStatus == 0">
                                            (未支付)</span>
                                        <span v-if="item.activityEnrollmentFunctionDto.paymentStatus == 1">
                                            (已支付定金¥{{ item.activityDto.deposit }}元)</span>
                                        <span v-else-if="item.activityEnrollmentFunctionDto.paymentStatus == 2">
                                            (已全额支付)</span>
                                        <span v-else-if="item.activityEnrollmentFunctionDto.paymentStatus == 3">
                                            (已退款)</span>
                                    </span>

                                </div>
                            </template>
                            <template #desc>
                                <div>{{ item.activityDto.activityDetails }}</div>
                                <div>活动开始时间：{{ formatTime(item.activityDto.eventStartTime) }}</div>
                                <div>活动结束时间：{{ formatTime(item.activityDto.eventEndTime) }}</div>
                                <div>活动地点：{{ item.activityDto.activityAddress }}</div>
                            </template>
                            <template #footer>
                                <div>
                                    <span v-if="item.activityEnrollmentFunctionDto.paymentStatus == 0">
                                        (未支付)</span>
                                    <span v-if="item.activityEnrollmentFunctionDto.paymentStatus == 1">
                                        (已支付定金¥{{ item.activityDto.deposit }}元,待退款)</span>
                                    <span v-else-if="item.activityEnrollmentFunctionDto.paymentStatus == 2">
                                        (已全额支付,待退款)</span>
                                    <span v-else-if="item.activityEnrollmentFunctionDto.paymentStatus == 3">
                                        (已退款)</span>
                                </div>
                            </template>
                        </van-card>
                    </div>
                </van-list>
            </van-tab>
        </van-tabs>

        <van-dialog v-model="showQrCode" title="签到码">
            <div class="center" v-if="showQrCode">
                <VueQR :text="qrCodeValue" :size="200" colorDark="#000000" colorLight="#ffffff"></VueQR>
            </div>
        </van-dialog>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import util from "@/common/util/js/util";
import { Tab, Tabs } from "vant";
import { Card } from 'vant';
import { List } from 'vant';
import { reqSelActivityEnrollment } from "@/api/api";
import { Button } from 'vant';
import VueQR from 'vue-qr';
import { Dialog } from 'vant';

Vue.use(Dialog);
Vue.use(Button);
Vue.use(List);
Vue.use(Card);
Vue.use(Tab);
Vue.use(Tabs);
export default {
    //name填入本组件名字
    name: "myActivityTbale",
    // components: {《组件名称》},
    // import引入的组件需要注入到对象中才能使用
    components: {
        VueQR
    },
    data() {
        // 这里存放数据
        return {
            active: 0,
            success_loading: false,
            success_finished: false,
            success_currentPage: 1,
            success_pageSize: 10,
            success_list: {},
            fail_loading: false,
            fail_finished: false,
            fail_list: {},
            fail_currentPage: 1,
            fail_pageSize: 10,
            qrCodeValue: "",
            showQrCode: false

        };
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        success_onLoad() {
            this.getSuccessData();
        },
        fail_onLoad() {
            this.getFailData();
        },
        async getSuccessData() {
            const params = {
                currentPage: this.success_currentPage,
                pageSize: this.success_pageSize,
                enrollmentStatus: 1,// 报名状态(0未支付，1已支付定金，2已全款支付，3审核通过,4审核拒绝)
            }; //请求参数
            const result = await reqSelActivityEnrollment(params);
            console.log("查询客户信息:", result.code);
            if (result.code === 0) {
                console.log("成功:", result.data);
                //把查到数据添加到当前页面数据对象中,转码
                if (result.data.records.length > 0) {
                    this.success_list = result.data.records;
                }
                //将数据赋值给当前页面数据对象
                this.total = result.data.total; //总条数
                console.log(this.total);
                //总条数/页面条数 向上取整 = 当前页码则
                console.log(Math.ceil(result.data.total / this.success_pageSize));
                console.log(this.success_currentPage);
                console.log(
                    Math.ceil(result.data.total / this.success_pageSize) == this.success_currentPage
                );
                if (
                    this.success_currentPage >= Math.ceil(result.data.total / this.success_pageSize)
                ) {
                    this.success_loading = false;
                    this.success_finished = true; // 没有更多数据了
                    console.log("没有更多数据了");
                }
            }
        },
        async getFailData() {
            const params = {
                currentPage: this.fail_currentPage,
                pageSize: this.fail_pageSize,
                enrollmentStatus: 2,// 报名状态(报名状态(0待审核，1审核通过，2审核拒绝))
            }; //请求参数
            const result = await reqSelActivityEnrollment(params);
            console.log("查询客户信息:", result.code);
            if (result.code === 0) {
                console.log("成功:", result.data);
                //把查到数据添加到当前页面数据对象中,转码
                if (result.data.records.length > 0) {
                    this.fail_list = result.data.records;
                }
                //将数据赋值给当前页面数据对象
                this.total = result.data.total; //总条数
                console.log(this.total);
                //总条数/页面条数 向上取整 = 当前页码则
                console.log(Math.ceil(result.data.total / this.fail_pageSize));
                console.log(this.fail_currentPage);
                console.log(
                    Math.ceil(result.data.total / this.fail_pageSize) == this.fail_currentPage
                );
                if (
                    this.fail_currentPage >= Math.ceil(result.data.total / this.fail_pageSize)
                ) {
                    this.fail_loading = false;
                    this.fail_finished = true; // 没有更多数据了
                    console.log("没有更多数据了");
                }
            }
        },
        formatTime(time) {
            return util.timeStamp(time); // 在方法中引用 util
        },

        showQrcode(code) {
            console.log("showQrcode:" + code);
            this.qrCodeValue = code;
            this.showQrCode = true; // 显示对话框
        },
        //判断是否登录若未登陆则跳转到登录页面
        showDialog() {
            Dialog.alert({
                message: "您未登录，请先登录！",
            }).then(() => {
                // on confirm
                this.$store.dispatch("recordUserInfo", null);
                //把当前路由地址发送给登录页
                this.$route.query.redirect = this.$route.fullPath;

                this.$router.push("/login");
            });
        },

    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        if (!this.$store.state.token) {
            this.showDialog();
            return;
        }
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() { },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类

//垂直居中
.text-ts {
    display: flex;
    align-items: center; // 垂直居中
}

.center {
    text-align: center;
}
</style>