<!-- 选择Tag标签 -->
<template>
  <div class="GridTag">
    <van-grid :border="false">
      <van-grid-item
        v-for="(item, index) in items"
        :key="index"
        :text="item.text"
        :default="item.default"
      >
        <span>
          <van-tag
            class="item-txt"
            :id="mark + '-tag-' + index"
            :color="getColor()"
            @click="handleClick(index)"
            plain
            >{{ item.text }}</van-tag
          >
        </span>
      </van-grid-item>
    </van-grid>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import { Grid, GridItem } from "vant";
import { Tag } from "vant";
import { Toast } from "vant";

Vue.use(Toast);
Vue.use(Tag);
Vue.use(Grid);
Vue.use(GridItem);
export default {
  //name填入本组件名字
  name: "GridTag",
  // components: {《组件名称》},
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    //展示列表
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    //用于唯一标识元素
    mark: {
      type: String,
      default: "",
    },
    //最多选择的个数
    max: {
      type: Number,
      default: 3,
    },
    //选中元素
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    // 这里存放数据
    return {
      colors: ["#ccc", "#7232dd"], // 两个颜色，点击时进行切换
      return_date: [],
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    getColor() {
      return "#ccc";
    },

    handleClick(index) {
      console.log(index);
      console.log(this);

      //通过index来获取当前点击对象id = tag- + index，修改DOM元素背景颜色
      let id = this.mark + "-tag-" + index;

      let tagDom = document.getElementById(id);
      console.log(id + "/" + tagDom.style.color);
      if (tagDom.style.color == "rgb(114, 50, 221)") {
        tagDom.style.color = "#ccc";
        tagDom.style.borderColor = "#ccc";
        //返回数组移除当前下标
        this.return_date = this.return_date.filter((item) => item != index);
        this.$emit("band-object", this.return_date); // 直接发送选择的值回父组件
        console.log(this.return_date);
      } else {
        if (this.return_date.length >= this.max) {
          Toast.fail("最多选择" + this.max + "个标签");
          this.return_date = this.return_date.filter((item) => item != index);
        } else {
          tagDom.style.color = "#7232dd";
          tagDom.style.borderColor = "#7232dd";
          this.return_date.push(index);
          console.log(this.return_date);
          this.$emit("band-object", this.return_date); // 直接发送选择的值回父组件
        }
      }
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() { 
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    console.log(this.selected);
    //判断当前元素是否存在于需要选中的元素数组里
    this.selected.forEach((item) => {
      //通过index来获取当前点击对象id = tag- + index，修改DOM元素背景颜色
      console.log(item + "/" + this.mark);
      let id = this.mark + "-tag-" + item;
      let tagDom = document.getElementById(id);
      tagDom.style.color = "#7232dd";
      tagDom.style.borderColor = "#7232dd";
      this.return_date.push(item);
    });
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.item-txt {
  //元素不能换行
  white-space: nowrap;
}
</style>