<!-- 编辑自我介绍 -->
<template>
  <div class="EditIntroduce">
    <div>
      <div class="m_introduce">自我介绍</div>
      <van-field
        v-model="self_introduction"
        rows="2"
        autosize
        type="textarea"
        maxlength="120"
        placeholder="请输入留自我介绍"
        show-word-limit
      />
      <div class="m_introduce">我的性格(选择6个)</div>
      <GridTag
        :items="GridTagXGItems"
        :mark="mark"
        :max="max"
        :selected="personality"
        v-if="isDataLoaded"
        @band-object="personality = $event"
      />
      <div class="m_introduce">
        我的称号(兴趣爱好选择6个)<GridTag2
          :items="GridTagXQItems"
          :mark="mark2"
          :max="max2"
          :selected="interest"
          v-if="isDataLoaded"
          @band-object="interest = $event"
        />
      </div>
      <div style="padding: 16px">
        <van-button
          round
          block
          type="primary"
          native-type="button"
          color="linear-gradient(to right,#ff3357 , #FFC0CB)"
          @click="saveInfo"
          >提交进入下步</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue"; 
import { reqSelUserInfoMy,reqSaveMemberInfoAll } from "@/api/api";
import { interestMap, personalityMap } from "@/common/constants/constants.js";
import { Form } from "vant";
import { Field } from "vant";
import GridTag from "@/components/Grid/GridTag.vue";
import GridTag2 from "@/components/Grid/GridTag.vue";

Vue.use(Form);
Vue.use(Field);
export default {
  //name填入本组件名字
  name: "EditIntroduce",
  // import引入的组件需要注入到对象中才能使用
  components: { GridTag, GridTag2 },
  data() {
    // 这里存放数据
    return {
      self_introduction: "",
      isDataLoaded: false, // 控制GridTag是否渲染的标志
      interestMap, //兴趣爱好中文码值
      personality: [],
      max: 6,
      mark: "personality", //用于唯一标识元素
      //性格类型大全
      GridTagXGItems: [
        { text: "开朗" },
        { text: "活泼" },
        { text: "热情" },
        { text: "外向" },
        { text: "善良" },
        { text: "乐观" },
        { text: "勇敢" },
        { text: "幽默" },
        { text: "聪慧" },
        { text: "达理" },
        { text: "稳重" },
        { text: "沉静" },
        { text: "谨慎" },
        { text: "温和" },
        { text: "实在" },
        { text: "真诚" },
        { text: "孤单" },
        { text: "冲动" },
        { text: "热血" },
        { text: "傲娇" },
        { text: "内向" },
        { text: "耿直" },
        { text: "机智" },
        { text: "务实" },
      ],
      personalityMap, //性格类型中文码值

      mark2: "interest",
      max2: 6,
      interest: [],
      GridTagXQItems: [
        { text: "躺平族" },
        { text: "内卷王" },
        { text: "历史迷" },
        { text: "颜值控" },
        { text: "夜猫子" },
        { text: "技术宅" },
        { text: "学霸光环" },
        { text: "学渣附体" },
        { text: "社恐份子" },
        { text: "吃货达人" },
        { text: "佛系青年" },
        { text: "斜杠青年" },
        { text: "千年书虫" },
        { text: "体育达人" },
        { text: "手作匠人" },
        { text: "科技极客" },
        { text: "公益先锋" },
        { text: "时尚达人" },
        { text: "游戏大神" },
        { text: "宠物达人" },
        { text: "舞林高手" },
        { text: "购物狂魔" },
        { text: "拍照达人" },
        { text: "王者战五渣" },
        { text: "文艺小清新" },
        { text: "旅行爱好者" },
        { text: "音乐发烧友" },
        { text: "环保主义者" },
        { text: "养生小能手" },
        { text: "美食探索者" },
        { text: "社交牛逼症" },
        { text: "涂鸦艺术家" },
        { text: "天命打工人" },
        { text: "剧本杀之手" },
        { text: "二次元穿越者" },
        { text: "狼人血染之王" },
        { text: "脱口秀段子王" },
        { text: "精致的猪猪孩" },
        { text: "重生之三世在看剧" },
      ],
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async getInfo() {
      const result = await reqSelUserInfoMy(); 
      console.log("查询客户信息:", result.code);
      if (result.code === 0) {
        console.log("成功:", result);
        //保存到全局对象
        this.$store.dispatch("recordUser", result.data);
        //将数据赋值给当前页面数据对象
        this.self_introduction = result.data.member_info.selfIntroduction;
        //按照,分割字符串插入到数组里
        if (result.data.member_info.personality) {
          this.personality = result.data.member_info.personality.split(",");
        }
        if (result.data.member_info.interest) {
          this.interest = result.data.member_info.interest.split(",");
        }
       
      } 
      this.isDataLoaded = true; // 数据加载完成后，设置标志为true
    },

    SubmitEvent() {
      //通知父容器
      this.$emit("parentFunction");
    },
    async saveInfo() {
      this.wait = true;
      const params = {
        selfIntroduction: this.self_introduction,
        personality: this.personality.join(","), //按照,分割数组格式的字符串
        interest: this.interest.join(","), //按照,分割数组格式的字符串
      }; //请求参数
      const result = await reqSaveMemberInfoAll(params); 
      console.log("保存客户信息:", result.code);
      if (result.code === 0) {
        console.log("成功:", result.data);
        //通知父容器
        this.$emit("parentFunction");
      }
      this.wait = false;
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getInfo();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {
    //页面创建完毕查询返显客户信息
  }, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.EditIntroduce {
  background-color: #fff;
  border-radius: 15px;
  width: 100%;
  height: 100%;
}
.m_introduce {
  padding-left: 15px;
  padding-top: 10px;
  font-display: bold;
  //字体加粗
  font-weight: 700;
  //字体大小
  font-size: 16px;
}
</style>