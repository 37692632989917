<!-- 登录 -->
<template>
  <div class="page-background">
    <div class="page-title">开启您的幸福通道</div>
    <van-cell-group>
      <van-field v-model="openid" label="openid" placeholder="请输入openid" />
    </van-cell-group>
    <div class="page-login-button">
      <van-button type="primary" size="large" class="my-button" @click="goToWeChatLogin">一键登录</van-button>
    </div>
    <div class="page-login-checkbox">
      <van-checkbox v-model="checked" shape="square" class="page-check">
        <p class="page-check">我同意</p>
      </van-checkbox>
      <span class="page-privacy" @click="showDialog">《用户协议和隐私政策》</span>
    </div>
  </div>
</template>

<script>
import { reqLoginWeixin } from "@/api/api";
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { Field } from 'vant';

Vue.use(Field);
import { Button, Checkbox, CheckboxGroup, Dialog, Toast } from "vant";
import Vue from "vue";
Vue.use(Button).use(Checkbox).use(Dialog).use(CheckboxGroup).use(Toast);
// 局部注册
export default {
  //name填入本组件名字
  name: "loginPage",
  // components: {《组件名称》},
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      checked: false,
      openid: "",
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    checkButton() {
      if (!this.$data.checked) {
        Toast.fail("请阅读并勾选同意相关协议");
        return false;
      }
      return true;
    },
    goToWeChatLogin() {
      if (!this.checkButton()) {
        return;
      }

      this.ajaxlogin();
    },
    async ajaxlogin() {
      const temp_params = {
        openid: this.openid,
      };
      const result = await reqLoginWeixin(temp_params);
      if (result.code == 0) {
        console.log("登录成功:", result);
        // 将user保存到vuex的state,先通过actios.js的recordUser方法保存到state中
        this.$store.dispatch("recordUser", result.data);

        // 将user保存到vuex的state,先通过actios.js的recordToken方法保存到state中
        this.$store.dispatch("recordToken", result.token);
        //把token存到全局请求头中
        // 将 token 存储到 localStorage 或 Vuex 中
        console.log("token:", result.token);
        //localStorage.setItem("token", result.token);

        // 将user保存到vuex的state,先通过actios.js的recordUser方法保存到state中
        this.$store.dispatch("recordPermission", result.permission);

        //把token存到cookie中
        document.cookie = "token=" + result.token + "; domain=.hongxian.cc; path=/;max-age=86400;";
        document.cookie = "token=" + result.token + "; domain=.*; path=/;max-age=86400;";

        //跳转 判断有无来源页面，若无来源页面则跳转到个人中心首页

        const redirect = this.$route.query.redirect;
        console.log("redirect:", redirect);
        if (redirect != undefined) {
          this.$router.push(redirect); // 跳转回原本要访问的页面
        } else {
          this.$router.push("/home/"); // 默认跳转到用户中心首页
        }
      }

    },

    showDialog() {
      const msg =
        "用户协议和隐私政策\n" +
        "在注册前，敬请您垂阅以下内容，在进行注册程序过程中点击“同意”按钮即表示用户完全接受本协议项下的全部条款。\n" +
        "第一条 会员资格\n" +
        "在您承诺完全同意本服务条款并在本站完成注册程序后，即可成为本网站会员，享受本网站为您提供的服务。如用户拒绝支付该项费用，则只能使用与查看非付费的内容。\n" +
        "第二条 会员权限\n" +
        "1.会员想观看/下载付费资源，须支付费用才能查看相关条目，可参阅内容条目收费标准及服务内容表；\n" +
        "2.任何会员均有义务遵守本规定及其他网络服务的协议、规定、程序及惯例。\n" +
        "第三条 会员资料\n" +
        "1.为了使我们能够更好地为会员提供服务，请您提供详尽准确的个人资料，如更改请及时更新，提供虚假资料所造成的后果由会员承担；\n" +
        "2.会员有责任保管好自己的注册密码并定期修改避免造成损失，由于会员疏忽所造成的损失由会员承担。用户应当对以其用户帐号进行的所有活动和事件负法律责任。\n" +
        "第四条 会员资格的取消\n" +
        "如发现任何会员有以下任何行为，本网站保留取消其使用服务的权利并追究其法律责任的权利：\n" +
        "\n" +
        "1.可能造成本网站全部或局部的服务受影响，或危害本网站运行；\n" +
        "2.以任何欺诈行为获得会员资格；\n" +
        "3.在本网站内从事非法商业行为，发布涉及敏感政治、宗教、色情或其它违反有关国家法律和政府法规的文字、图片等信息；\n" +
        "4.以任何非法目的而使用网络服务系统。\n" +
        "\n" +
        "第五条 素材产品版权归属与使用授权\n" +
        "1.本网站中的文字说明、摄影图片、插图、视频（不限于此）都为本站和其内容提供者所有，上述内容受本网站控制或内容所有者许可给本网站使用，且受著作权法、专利法、商标法以及各类其他知识产权法的保护。\n" +
        "2.会员在本网站所购买的一切内容资源仅限会员本人查阅，会员本人无权在任何已知的载体，以任何形式包括但不限于单独、被包含或与任何项目相结合的形式，以及作为其他任何项目上的商品或其他作者的作品展示、出售或分销本网站素材产品及复制品给予任何第三方。\n" +
        "3. 对于违反合作规定或非法使用网站内容的行为，本站将取消用户会员资格，要求行为人对所有权的丢失、使用及损坏做出赔偿并追究其法律责任。\n" +
        "第六条 服务商的权利\n" +
        "1.有权审核、接受或拒绝会员的注册申请，有权撤销或停止会员的全部或部分服务内容；\n" +
        "\n" +
        "2.有权修订会员的权利和义务，有权修改或调整本网站的服务内容；\n" +
        "\n" +
        "3.有权将修订的会员的权利和义务以电子邮件的形式通知会员，会员收到通知后仍继续使用本网站服务者即表示会员同意并遵守新修订内容。\n" +
        "\n" +
        "4.本网提供的服务仅供会员独立使用，未经本网授权，会员不得将会员号授予或转移给第三方。会员如果有违此例，本网有权向客户追索商业损失并保留追究法律责任的权利。\n" +
        "\n" +
        "第七条 服务商的义务\n" +
        "1.认真做好本网站所涉及的网络及通信系统的技术维护工作，保证本网站的畅通和高效；\n" +
        "2.除不可抗拒的因素导致本网站临时停止或短时间停止服务以外，乙方如需停止本网站的全部或部分服务时，须提前在本网站上发布通知通告会员。\n" +
        "3.如本网站因系统维护或升级等原因需暂停服务，将事先通过主页、电子邮件等方式公告会员；\n" +
        "4.因不可抗力而使本网站服务暂停，所导致会员任何实际或潜在的损失，本网不做任何补偿；\n" +
        "5.本网不承担会员因遗失密码而受到的一切损失。\n" +
        "6.本网仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如电脑、调制解调器及其他与接入互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由用户自行负担。\n" +
        "第八条 附则\n" +
        "1.以上规定的范围仅限于本网站；\n" +
        "2.本网会员因违反以上规定而触犯有关法律法规，一切后果自负，本网站不承担任何责任；\n" +
        "3.本规则未涉及之问题参见有关法律法规，当本规定与有关法律法规冲突时，以相应的法律法规为准。在本条款规定范围内，本网站拥有最终解释权。\n";
      Dialog.confirm({
        title: "《用户协议和隐私政策》",
        message: msg,
        messageAlign: "left",
      })
        .then(() => {
          // on confirm
          this.$data.checked = true;
        })
        .catch(() => {
          // on cancel
          this.$data.checked = false;
        });
    },
    clearCookie(name) {
      let cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name2 = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name2 + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        if (name2 == name) {
          break;
        }
      }
    },
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {


    const code = this.$route.query.code;
    console.log("code:" + code);

    const state = this.$route.query.state;
    console.log("state:" + state);

    //清除cookie中的token
    this.clearCookie("token");
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.page-background {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-image: url("./img/background1_2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  /* 确保背景在内容之下 */
}

.my-button {
  min-width: 15%;
  max-width: 30%;
  font-weight: 500;
}

.page-title {
  font-size: 3em;
  color: white;
  text-align: center;
  margin-top: 30%;
  font-weight: 700;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.page-login-button {
  margin-top: 33%;
  font-size: 2em;
  font-weight: 700;
}

.page-login-checkbox {
  text-align: center;
  margin-left: 35%;
  margin-top: 5px;
  font-size: 1.2em;
}

.page-privacy {
  color: #042476;
  padding: 0;
  margin-left: -30%;
}

.page-check {
  color: blue;
}

/* styles.css */

.van-checkbox__icon .van-iconn {
  border: 1px solid #0f42db;
}

.van-checkbox__icon .van-checkbox__icon--square .checkbox-disabled-background-color .van-checkbox__label {
  border: 1px solid #0f42db;
}
</style>