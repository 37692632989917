/*
包含n个接口请求函数的模块
函数的返回值: promise对象
 */
import axiosPromise from './http' 
const envConfig = await import(`@/common/config/config.${process.env.NODE_ENV}`);
//图片处理接口单独方法不写在api里面

const BASE_URL = envConfig.default.apiUrl; // 确保使用默认导出
//微信登录
export const reqLoginWeixin = (params) => axiosPromise(`${BASE_URL}/api/member/login/weixin`,params,'GET')

//获取自己基础信息接口
export const reqSelUserInfoMy = (params) => axiosPromise(`${BASE_URL}/api/member/sel/userInfo/my`,params,'GET')

//获取指定人基础信息接口
export const reqSelUserInfo = (params) => axiosPromise(`${BASE_URL}/api/member/sel/userInfo`,params,'GET')

//保存用户信息接口
export const reqSaveMemberInfoAll = (params) => axiosPromise(`${BASE_URL}/api/member/save/memberInfo/all`,params,'PSOT')


//查询用户择偶要求接口
export const reqSelRequire = (params) => axiosPromise(`${BASE_URL}/api/member/sel/require`,params,'GET')

//保存用户择偶要求接口
export const reqSaveRequire = (params) => axiosPromise(`${BASE_URL}/api/member/save/require`,params,'PSOT')


//查询我的钱包接口
export const reqSelCollectMy = (params) => axiosPromise(`${BASE_URL}/api/member/purse/sel/collect/my`,params,'PSOT')

//查询我的钱包动账接口
export const reqSelCollectLog = (params) => axiosPromise(`${BASE_URL}/api/member/purse/sel/collectLog`,params,'PSOT')


//查询用户浏览总数接口
export const reqSelBrowseTotal = (params) => axiosPromise(`${BASE_URL}/api/member/browseLog/sel/browse/total`,params,'PSOT')

//查询用户收藏总数接口
export const reqSelCollectTotal = (params) => axiosPromise(`${BASE_URL}/api/member/collectLog/sel/collect/total`,params,'PSOT')

//查询用户未读消息接口
export const reqSelUnReadInfoRedis = (params) => axiosPromise(`${BASE_URL}/api/member/sel/unReadInfo/redis`,params,'PSOT')

//查询用户浏览记录接口
export const reqSelBrowseISeen = (params) => axiosPromise(`${BASE_URL}/api/member/browseLog/sel/browse/iSeen`,params,'PSOT')

//查询谁看过我的接口
export const reqSelSeenMe = (params) => axiosPromise(`${BASE_URL}/api/member/browseLog/sel/browse/seenMe`,params,'PSOT')


//查询用户收藏记录接口
export const reqSelCollectMyCollection = (params) => axiosPromise(`${BASE_URL}/api/member/collectLog/sel/collect/myCollection`,params,'PSOT')

//查询用户收藏我的接口
export const reqSelCollectCollectionMe = (params) => axiosPromise(`${BASE_URL}/api/member/collectLog/sel/collect/collectionMe`,params,'PSOT')

//检查用户是否收藏接口
export const reqSelCollectCheck = (params) => axiosPromise(`${BASE_URL}/api/member/collectLog/sel/collect/check`,params,'POST')


//保存线上匹配用户接口
export const reqSaveCollect = (params) => axiosPromise(`${BASE_URL}/api/member/collectLog/save/collect`,params,'GET')

//取消线上匹配用户接口
export const reqDelCollect = (params) => axiosPromise(`${BASE_URL}/api/member/collectLog/del/collect`,params,'GET')



//查询用户最后一次访问收藏、浏览我的页面接口
export const reqSelLastTime = (params) => axiosPromise(`${BASE_URL}/api/member/lastTime/sel/lastTime`,params,'PSOT')

//更新最后一次访问收藏、浏览我的页面时间接口
export const reqUpdateLastTime = (params) => axiosPromise(`${BASE_URL}/api/member/lastTime/update/lastTime`,params,'GET')

//保存线上匹配用户接口
export const reqSaveMatch = (params) => axiosPromise(`${BASE_URL}/api/member/matchLog/save/match`,params,'GET')

//取消线上匹配用户接口
export const reqDelMatch = (params) => axiosPromise(`${BASE_URL}/api/member/matchLog/del/match`,params,'GET')

//检查用户是否匹配接口
export const reqSelMatchCheck = (params) => axiosPromise(`${BASE_URL}/api/member/matchLog/sel/match/check`,params,'POST')



//查询活动列表接口
export const reqSelActivity = (params) => axiosPromise(`${BASE_URL}/api/system/activity/sel/activity`,params,'POST')

//查询自己报名列表接口
export const reqSelActivityEnrollment = (params) => axiosPromise(`${BASE_URL}/api/system/activity/enrollment/sel/activityEnrollment`,params,'POST')

//查询活动报名人数接口
export const reqSelActivityEnrollmentPeopleRedis = (params) => axiosPromise(`${BASE_URL}/api/system/activity/enrollment//sel/activityEnrollment/people/redis`,params,'POST')


//提交报名活动接口
export const reqSaveActivityEnrollment = (params) => axiosPromise(`${BASE_URL}/api/system/activity/enrollment/save/activityEnrollment`,params,'POST')

//提交订单接口
export const reqSaveOrder = (params) => axiosPromise(`${BASE_URL}/api/system/order/save/order`,params,'POST')



//提交预支付订单
export const reqWeixinPaySendOrder = (params) => axiosPromise(`${BASE_URL}/api/weixin/pay/sendOrder`,params,'GET')








 //查询单个认证信息
export const reqSelAuthOrderLog = (params) => axiosPromise(`${BASE_URL}/api/system/realAuth/selAuthOrderLog/authType`,params,'GET')

 //删除认证图片接口
 export const reqDelAuthUserImg= (params) => axiosPromise(`${BASE_URL}/api/resources/authUser/delete/img`,params,'GET')

//上传图片接口
export const reqUploadAuthUserImg = (params) => axiosPromise(`${BASE_URL}/api/resources/authUser/upload/img`,params,'POST')


//搜索用户页接口
export const reqSelUsersSearch = (params) => axiosPromise(`${BASE_URL}/api/member/sel/users/search`,params,'POST')


//获取微信授权链接接口
export const reqSelWeiXinAuthorizeUrl = (params) => axiosPromise(`${BASE_URL}/api/weixin/authorizeUrl`,params,'GET')

 
//获取微信授权链接接口
export const reqSelWeiXinShareConfig = (params) => axiosPromise(`${BASE_URL}/api/weixin/share/config`,params,'GET')

 
//查询我的订单列表接口
export const reqSelOrder = (params) => axiosPromise(`${BASE_URL}/api/system/order/sel/order`,params,'POST')

