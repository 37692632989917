<!-- 积分明细 -->
<template>
  <div class="">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getdataList"
    >
      <van-cell
        v-for="item in dataList"
        :key="item.logid"
        :value="item.changeNumber"
      >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <div class="custom-title">
            <span>{{ timeStamp(item.createTime) }}</span> <span class="custom-title-left">{{ item.remark }}</span>
          </div>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import { reqSelCollectLog } from "@/api/api";
import { List } from "vant";

Vue.use(List);
export default {
  //name填入本组件名字
  name: "promotionUpPage",
  // components: {《组件名称》},
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      dataList: [],
      loading: false,
      finished: false,
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async getdataList() {
      const params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        purseType: "promotion_up",
      }; //请求参数
      const result = await reqSelCollectLog(params);
      if (result.code === 0) {
        console.log("成功:", result.data);
        //将数据赋值给当前页面数据对象
        this.dataList = result.data.records;
      }
      this.loading = false;
      this.finished = true;
    },
    //时间戳转 年月日时分秒
    timeStamp(time) {
      // 检查时间戳是否为秒级时间戳
      const isSeconds = time < 10000000000; // 10 位数的时间戳通常是秒级
      const date = new Date(isSeconds ? time * 1000 : time);

      const Y = date.getFullYear() + "-";
      const M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      const D = date.getDate() + " ";
      const h = date.getHours() + ":";
      const m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      const s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

      return Y + M + D + h + m + ":" + s;
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.custom-title-left{
    margin-left: 20px;
}
</style>