<!-- 编辑用户信息骨架主体 -->
<template>
  <div class="EditInfo">
    <div class="m_progress">
      <van-progress
        :percentage="25 + Number(active) * 25"
        :pivot-text="'第' + (Number(active) + Number(1)) + '步'"
        color="green"
      />
    </div>
    <van-tabs
      v-model="active"
      line-width="60px"
      background="#ffe6ea"
      title-active-color="#000"
      title-inactive-color="#969799"
      ref="vanTabs"
    >
      <van-tab >
        <template #title>
          <span class="m_title">①基础信息</span>
        </template>
        <div class="m_content"><EditBasic @parentFunction="changePage" /></div>
      </van-tab>

      <van-tab >
        <template #title>
          <span class="m_title">②用户相册</span>
        </template>
        <div class="m_content">
          <EditPhoto  @parentFunction="changePage"/>
        </div>
      </van-tab>

      <van-tab >
        <template #title>
          <span class="m_title">③兴趣爱好</span>
        </template>
        <div class="m_content">
          <EditIntroduce  @parentFunction="changePage"/>
        </div>
      </van-tab>

      <van-tab >
        <template #title>
          <span class="m_title">④择偶要求</span>
        </template>
        <div class="m_content"><edit-require/></div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import { Tab, Tabs } from "vant";
import EditBasic from "./EditBasic.vue";
import EditPhoto from "./EditPhoto.vue";
import EditIntroduce from "./EditIntroduce.vue";
import { Progress } from "vant";
import EditRequire from './EditRequire.vue';

Vue.use(Progress);
Vue.use(Tab);
Vue.use(Tabs);
export default {
  //name填入本组件名字
  name: "EditInfo",
  // components: {《组件名称》},
  // import引入的组件需要注入到对象中才能使用
  components: {
    EditBasic,
    EditPhoto,
    EditIntroduce,
    EditRequire,
  },
  data() {
    // 这里存放数据
    return {
      active:0,
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    //切换页面
    changePage() {
      const name = (Number(this.active)+Number(1));
      console.log(name);
      this.$refs.vanTabs.scrollTo(name);
      console.log(this.active);
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    

  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.m_progress {
  height: 20px;
  margin-top: 25px;
  //垂直居中
  vertical-align: middle;
}
.m_progress,
.EditInfo {
  background-color: #ffe6ea;
}
.m_img {
  width: 15px;
  //垂直居中
  vertical-align: middle;
}
.m_title {
  font-size: 15px;
}
.m_content {
  //圆角
  border-radius: 30px;
  margin: 0px 20px 0px 20px;
  //边框增加阴影
  box-shadow: 0px 2px 15px #ccc;
}
</style>