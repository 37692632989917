<!-- 登录 -->
<template>
  <div class="page-background">
    <div class="page-title">开启您的幸福通道</div>
    <div id="dl_box">
      <div id="dl_wx_box" class="page-login-button" v-show="dl_wx_show">
        <van-button type="primary" size="large" class="my-button" @click="goToWeChatLogin">一键登录</van-button>
      </div>
      <div class="page-ido-button">
        <van-checkbox v-model="checked" shape="square">
          <div class="page-privacy" @click="showDialog">
            我同意《用户协议和隐私政策》
          </div>
        </van-checkbox>


      </div>
      <div id="dl_pwd_box" hidden class="dl_pwd_box" v-show="dl_pwd_show">
        <van-cell-group>
          <van-field v-model="mobile" label="手机号" placeholder="请输入手机号" maxlength="11" type="tel" required :rules="[
            { required: true, message: '请填写您的手机号码！' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
          ]" />
          <van-field v-model="pwd" type="password" label="密码" placeholder="请输入密码" required />
        </van-cell-group>
        <van-button type="primary" size="large" block @click="goToPwdLogin">登录</van-button>
      </div>
      <div id="dl_sms_box" class="dl_sms_box" v-show="dl_sms_show">
        <van-cell-group>
          <van-field v-model="mobile" label="手机号" placeholder="请输入手机号" maxlength="11" type="tel" required :rules="[
            { required: true, message: '请填写您的手机号码！' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
          ]" />
          <van-field v-model="smsCode" center clearable label="短信验证码" placeholder="请输入短信验证码" use-button-slot required>
            <van-button slot="button" size="small" type="primary" @click="sendSmsCode">
              {{ sendButtonText }}
            </van-button>
          </van-field>
        </van-cell-group>
        <van-button type="primary" size="large" block @click="goToSmsLogin">登录</van-button>
      </div>
    </div>



    <div id="dl_qh_box" hidden class="dl_qh_box">
      <div id="dl_qh">
        切换登录方式
        <span id="dl_qh_pwd" @click="showPwdLogin" v-show="dl_pwd_show_wz">
          <img class="dl_icon" src="./img/shumashouji.png" />
        </span>
        <span id="dl_qh_sms" @click="showSmsLogin" v-show="dl_sms_show_wz">
          <img class="dl_icon" src="./img/outline-sms.png" />
        </span>

        <span id="dl_qh_wx" @click="showWxLogin" v-show="dl_wx_show_wz">
          <img class="dl_icon" src="./img/weixin.png" />
        </span>
      </div>
    </div>
    <van-overlay :show="wait" @click="wait = false">
      <div class="wrapper" @click.stop>
        <van-loading size="48px" color="#FFF" vertical>登录中...</van-loading>
      </div>
    </van-overlay>
  </div>


</template>

<script>
import http from "@/api/http";
import { reqSelWeiXinAuthorizeUrl } from "@/api/api";
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

import { Button, Checkbox, CheckboxGroup, Dialog, Toast, Field } from "vant";
import Vue from "vue";
import { Overlay, Loading } from 'vant';

Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Button)
  .use(Checkbox)
  .use(Dialog)
  .use(CheckboxGroup)
  .use(Toast)
  .use(Field);
// 局部注册
export default {
  //name填入本组件名字
  name: "loginPage",
  // components: {《组件名称》},
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      wait: false,
      sendButtonText: "发送验证码",
      checked: false,

      //控制输入框box
      dl_pwd_show: false,
      dl_sms_show: false,
      dl_wx_show: true,

      //控制选择文字
      dl_pwd_show_wz: true,
      dl_sms_show_wz: true,
      dl_wx_show_wz: false,

      //
      mobile: "",
      pwd: "",
      smsCode: "",

      //授权url地址
      wechatLoginUrl: "",
      msg: "用户协议和隐私政策\n" +
        "在注册前，敬请您垂阅以下内容，在进行注册程序过程中点击“同意”按钮即表示用户完全接受本协议项下的全部条款。\n" +
        "第一条 会员资格\n" +
        "在您承诺完全同意本服务条款并在本站完成注册程序后，即可成为本网站会员，享受本网站为您提供的服务。如用户拒绝支付该项费用，则只能使用与查看非付费的内容。\n" +
        "第二条 会员权限\n" +
        "1.会员想观看/下载付费资源，须支付费用才能查看相关条目，可参阅内容条目收费标准及服务内容表；\n" +
        "2.任何会员均有义务遵守本规定及其他网络服务的协议、规定、程序及惯例。\n" +
        "第三条 会员资料\n" +
        "1.为了使我们能够更好地为会员提供服务，请您提供详尽准确的个人资料，如更改请及时更新，提供虚假资料所造成的后果由会员承担；\n" +
        "2.会员有责任保管好自己的注册密码并定期修改避免造成损失，由于会员疏忽所造成的损失由会员承担。用户应当对以其用户帐号进行的所有活动和事件负法律责任。\n" +
        "第四条 会员资格的取消\n" +
        "如发现任何会员有以下任何行为，本网站保留取消其使用服务的权利并追究其法律责任的权利：\n" +
        "\n" +
        "1.可能造成本网站全部或局部的服务受影响，或危害本网站运行；\n" +
        "2.以任何欺诈行为获得会员资格；\n" +
        "3.在本网站内从事非法商业行为，发布涉及敏感政治、宗教、色情或其它违反有关国家法律和政府法规的文字、图片等信息；\n" +
        "4.以任何非法目的而使用网络服务系统。\n" +
        "\n" +
        "第五条 素材产品版权归属与使用授权\n" +
        "1.本网站中的文字说明、摄影图片、插图、视频（不限于此）都为本站和其内容提供者所有，上述内容受本网站控制或内容所有者许可给本网站使用，且受著作权法、专利法、商标法以及各类其他知识产权法的保护。\n" +
        "2.会员在本网站所购买的一切内容资源仅限会员本人查阅，会员本人无权在任何已知的载体，以任何形式包括但不限于单独、被包含或与任何项目相结合的形式，以及作为其他任何项目上的商品或其他作者的作品展示、出售或分销本网站素材产品及复制品给予任何第三方。\n" +
        "3. 对于违反合作规定或非法使用网站内容的行为，本站将取消用户会员资格，要求行为人对所有权的丢失、使用及损坏做出赔偿并追究其法律责任。\n" +
        "第六条 服务商的权利\n" +
        "1.有权审核、接受或拒绝会员的注册申请，有权撤销或停止会员的全部或部分服务内容；\n" +
        "\n" +
        "2.有权修订会员的权利和义务，有权修改或调整本网站的服务内容；\n" +
        "\n" +
        "3.有权将修订的会员的权利和义务以电子邮件的形式通知会员，会员收到通知后仍继续使用本网站服务者即表示会员同意并遵守新修订内容。\n" +
        "\n" +
        "4.本网提供的服务仅供会员独立使用，未经本网授权，会员不得将会员号授予或转移给第三方。会员如果有违此例，本网有权向客户追索商业损失并保留追究法律责任的权利。\n" +
        "\n" +
        "第七条 服务商的义务\n" +
        "1.认真做好本网站所涉及的网络及通信系统的技术维护工作，保证本网站的畅通和高效；\n" +
        "2.除不可抗拒的因素导致本网站临时停止或短时间停止服务以外，乙方如需停止本网站的全部或部分服务时，须提前在本网站上发布通知通告会员。\n" +
        "3.如本网站因系统维护或升级等原因需暂停服务，将事先通过主页、电子邮件等方式公告会员；\n" +
        "4.因不可抗力而使本网站服务暂停，所导致会员任何实际或潜在的损失，本网不做任何补偿；\n" +
        "5.本网不承担会员因遗失密码而受到的一切损失。\n" +
        "6.本网仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如电脑、调制解调器及其他与接入互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由用户自行负担。\n" +
        "第八条 附则\n" +
        "1.以上规定的范围仅限于本网站；\n" +
        "2.本网会员因违反以上规定而触犯有关法律法规，一切后果自负，本网站不承担任何责任；\n" +
        "3. 本站提供的会员信息服务，无法百分百鉴别人员所有的真实信息，交友需谨慎，若产生法律纠纷本站不承担任何责任；\n" +
        "4.本规则未涉及之问题参见有关法律法规，当本规定与有关法律法规冲突时，以相应的法律法规为准。在本条款规定范围内，本网站拥有最终解释权。\n",
      dialogShow: false,

    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    sendSmsCode() {
      //校验手机号有没有填写
      if (!this.mobile) {
        Toast("请输入手机号");
        return;
      }
      //正则校验手机号是不是纯数字是否是手机号且是不是11位
      if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        Toast("手机号码格式错误");
        return;
      }
      //发送短信验证码
      const login_uri =
        this.$config.apiUrl + "/api/system/realAuth/send/loginCode";
      http(login_uri, {
        mobile: this.mobile,
        companyId: this.$baseConfig.companyId,
      })
        .then((data) => {
          console.log(data);
          if (data.code == 200 || data.code == 0) {
            Toast("短信验证码已发送");
            console.log("短信验证码已发送");
            //按钮倒计时
            // 使用闭包处理倒计时逻辑
            let countdown = 300;
            let timer = setInterval(
              function () {
                if (countdown > 0) {
                  countdown--;
                  this.sendButtonText = `${countdown}秒后重发`;
                } else {
                  clearInterval(timer);
                  this.sendButtonText = "重新发送";
                }
              }.bind(this),
              1000
            );
          } else {
            Toast(data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * 短信登录
     */
    goToSmsLogin() {
      //同意按钮勾选
      if (!this.checkButton()) {
        return;
      }
      //提交短信验证码登录
      const login_uri = this.$config.apiUrl + "/api/member/login";
      http(
        login_uri,
        {
          mobile: this.mobile,
          code: this.smsCode,
          companyId: this.$baseConfig.companyId,
          loginType: 2,
        },
        "POST"
      ).then((data) => {
        console.log(data);
        if (data.code == 200 || data.code == 0) {
          //保存返回的用户信息
          // 将user保存到vuex的state,先通过actios.js的recordWeixinUser方法保存到state中
          
          console.log("登录成功:", data.data);
          console.log(data.data);

          // 将user保存到vuex的state,先通过actios.js的recordUser方法保存到state中
          this.$store.dispatch("recordUser", data.member);

          // 将user保存到vuex的state,先通过actios.js的recordUser方法保存到state中
          this.$store.dispatch("recordToken", data.token);
          console.log("token:",data.token);

          //跳转 判断有无来源页面，若无来源页面则跳转到个人中心首页

          const redirect = this.$route.query.redirect;
          console.log("redirect:", redirect);
          if (redirect != undefined) {
            this.$router.push(redirect); // 跳转回原本要访问的页面
          } else {
            this.$router.push("/home/"); // 默认跳转到用户中心首页
          }

          this.$router.push({
            path: "/index",
          });
        } else {
          Toast(data.msg);
        }
      });
    },
    showPwdLogin() {
      this.dl_pwd_show = true;
      this.dl_sms_show = false;
      this.dl_wx_show = false;
      this.dl_pwd_show_wz = false;
      this.dl_wx_show_wz = true;
      this.dl_sms_show_wz = true;
    },
    showSmsLogin() {
      this.dl_sms_show = true;
      this.dl_pwd_show = false;
      this.dl_wx_show = false;
      this.dl_sms_show_wz = false;
      this.dl_pwd_show_wz = true;
      this.dl_wx_show_wz = true;
    },
    showWxLogin() {
      this.dl_wx_show = true;
      this.dl_pwd_show = false;
      this.dl_sms_show = false;
      this.dl_wx_show_wz = false;
      this.dl_pwd_show_wz = true;
      this.dl_sms_show_wz = true;
    },

    /**
     * 微信登录
     */
    goToWeChatLogin() {
      //同意按钮勾选
      if (!this.checkButton()) {
        return;
      }


      const url = this.wechatLoginUrl;

      window.location.href = url;
    },
    ajaxWeiXin(m_code, m_state) {
      const login_uri = this.$config.apiUrl + "/api/weixin/userInfo";
      http(login_uri, {
        code: m_code,
        state: m_state,
      })
        .then((data) => {
          console.log(data);
          //获取openid后登录
          if (data != undefined) {
            // 将user保存到vuex的state,先通过actios.js的recordWeixinUser方法保存到state中
            console.log(data.member);
            //this.$store.dispatch("recordWeixinUser", data.weixin);

            // 将user保存到vuex的state,先通过actios.js的recordUser方法保存到state中
            this.$store.dispatch("recordUser", data.data);

            // 将user保存到vuex的state,先通过actios.js的recordToken方法保存到state中
            this.$store.dispatch("recordToken", data.token);

            // 将user保存到vuex的state,先通过actios.js的recordUser方法保存到state中
            this.$store.dispatch("recordPermission", data.permission);

            //保存permission

            //把token存到cookie中
            //document.cookie = "token=Bearer " + data.token+"; domain=.hongxian.cc; path=/;   max-age=604800;   SameSite=None; Secure";
            document.cookie = "token=" + data.token + "; domain=.hongxian.cc; path=/;max-age=86400;";
            // Login.vue
            //document.cookie = `token=Bearer ${data.token}; domain=.hongxian.cc; path=/; max-age=604800; SameSite=None;  Secure`; // 即使 HTTP 协议也需添加 Secure
            //还要在# Nginx 配置
            //add_header Set-Cookie "token=xxxx; Path=/; Domain=.hongxian.cc; Max-Age=604800; SameSite=None";
            console.log("登录成功:", data);
            //跳转 判断有无来源页面，若无来源页面则跳转到个人中心首页

            const redirect = this.$route.query.redirect;
            console.log("redirect:", redirect);
            if (redirect != undefined) {
              this.$router.push(redirect); // 跳转回原本要访问的页面
            } else {
              this.$router.push("/home/"); // 默认跳转到用户中心首页
            }
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    },

    checkButton() {
      if (!this.$data.checked) {
        Toast.fail("请阅读并勾选同意相关协议");
        return false;
      }
      return true;
    },

    showDialog() {
      Dialog.confirm({
        title: "《用户协议和隐私政策》",
        message: this.msg,
        messageAlign: "left",
        theme: "round",
        getContainer: "body"
      })
        .then(() => {
          // on confirm
          this.$data.checked = true;
        })
        .catch(() => {
          // on cancel
          this.$data.checked = false;
        });
    },
    clearCookie(name) {
      let cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name2 = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name2 + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        if (name2 == name) {
          break;
        }
      }
    },
    async getWechatLoginUrl() {
      //const appId = this.$config.appId;
      // const redirectUri = encodeURIComponent(this.$config.baseUrl + "/login"); // 登录后返回页面
      // const scope = "snsapi_userinfo"; // 根据需要选择
      const state = "YOUR_STATE"; // 可以是随机数，用于验证 
      const params = {
        state: state
      }; //请求参数
      const result = await reqSelWeiXinAuthorizeUrl(params);
      if (result.code === 0) {
        this.wechatLoginUrl = result.redirectURL;
        console.log("wechatLoginUrl:" + this.wechatLoginUrl);
      }
    },
    goToPwdLogin() {

    },
    clearAllCookie() {
      // 获取所有 cookie 并分割成数组
      const cookies = document.cookie.split(';');

      // 遍历并清除所有 cookie
      cookies.forEach(cookie => {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();

        // 强制设置过期时间为过去时间
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.hongxian.cc`;
      });

      // 可选：清除当前域名的 cookie（双重保障）
      document.cookie.split(';').forEach(cookie => {
        document.cookie = cookie.replace(/^ +/, '')
          .replace(/=.*/, `=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`);
      });

      console.log('All cookies cleared for domain .hongxian.cc');
    }
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    const code = this.$route.query.code;
    console.log("code:" + code);

    const state = this.$route.query.state;
    console.log("state:" + state);

    //从微信授权页面返回
    if (code != undefined && state != undefined) {
      this.wait = true;
      this.ajaxWeiXin(code, state);
    } else {
      
      this.clearAllCookie(); //清除所有cookie 
      this.getWechatLoginUrl();
    } 
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    //使class为 van-field__label的文字颜色变为黑色、加粗
    const label = document.querySelectorAll(
      ".van-field__label, .van-field__control, .dl_sms_box"
    );
    label.forEach((item) => {
      item.style.color = "black";
      item.style.fontWeight = "bold";
    });

    //设置勾选相关协议样式
    const icon = document.querySelectorAll(".van-checkbox .van-checkbox__icon .van-icon");
    icon.forEach((item) => {
      item.style.fontWeight = "bold";
      item.style.border = "3px solid #1989fa";
    });
  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.page-background {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-image: url("./img/background2_2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -100000;
  /* 确保背景在内容之下 */
}

.my-button {
  min-width: 15%;
  max-width: 30%;
  font-weight: 500;
}

.page-title {
  font-size: 2.5em;
  color: white;
  text-align: center;
  margin-top: 30%;
  font-weight: 700;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.page-login-button {
  margin-top: 33%;
  font-size: 2em;
  font-weight: 700;
}




.dl_box {
  text-align: center;
  //设置背景颜色白色透明度0.7
  background-color: rgba(255, 255, 255, 0.7);
}

.dl_pwd_box,
.dl_sms_box {
  background: rgba(255, 255, 255, 0.7);
}

.dl_txt,
.dl_qh_box {
  color: #fff;
  line-height: 40px;
  //绝对定位浮动在最下方
  position: relative;
  top: 20%;
  transform: translateY(-50%);
}

.dl_icon {
  width: 40px;
  height: 40px;
  margin-left: 10px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* styles.css 

.van-checkbox__icon .van-iconn {
  border: 1px solid #0f42db;
}

.van-checkbox__icon
  .van-checkbox__icon--square
  .checkbox-disabled-background-color
  .van-checkbox__label {
  border: 1px solid #0f42db;
}
*/
.van-cell,
.van-cell-group {
  //清除背景颜色
  background-color: transparent;
}

.van-cell__title .van-field__label {
  color: #fff;
  background: #fff;
}

//清除文字颜色

.van-cell__title.van-field__label span {
  color: white !important;
  /* 使用 !important 来确保优先级，但应谨慎使用 */
}

.van-field__label span {
  color: white !important;
  /* 使用 !important 来确保优先级，但应谨慎使用 */
}

.page-ido-button {
  text-align: center;
  margin-top: 20px;
  // text-align: center;居中无效
  display: flex;
  justify-content: center;
  /* 水平居中 */
}
</style>