<!--  -->
<template>
  <div class="">
    <div>
      <DropdownMenu
        :queryGender="queryGender"
        @band-object="queryCondition = $event"
      />
    </div>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="card">
        <Card :userList="userList" />
      </div>
    </van-list>
  </div>
</template>
    
    <script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

import Vue from "vue";
import Card from "@/components/Card/Card.vue";
import {
  interestMap,
  personalityMap,
  educationMap,
  work_unit_typeMap,
  jobMap,
  annual_incomeMap,
} from "@/common/constants/constants.js";
import util from "@/common/util/js/util";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu.vue";
import { Dialog } from "vant";
import { List } from "vant";
import { reqSelUsersSearch } from "@/api/api";
Vue.use(List);
export default {
  // import引入的组件需要注入到对象中才能使用
  name: "searchComponent",
  components: {
    Card,
    DropdownMenu,
  },
  data() {
    // 这里存放数据
    return {
      loading: false,
      finished: true,
      queryCondition: [], //双向绑定到DropdownMenu控件
      queryGender: -1,
      queryCount: 5,
      currentPage: 0,
      total: 0,
      token: this.$store.state.token,
      userList: [],
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    // queryCondition 是要观察的数据属性，当 queryCondition 发生变化时，此函数会被调用
    async queryCondition(newVal, oldVal) {
      //若搜索条件变化则清空列表
      this.currentPage = 0; //重置页码
      this.finished = false; //重置完成标志位为false，可以继续加载数据
      this.userList = []; //清空列表数据
      this.loading = true; //开始转动等待图标
      console.log("清除列表");

      // newVal 是新的值，oldVal 是旧的值
      console.log("queryCondition changed from", oldVal, "to", newVal); 
      this.currentPage++; //页码加一
      var temp_params = this.queryCondition; //使用临时变量加入页码，避免重复触发监听
      temp_params.currentPage = this.currentPage; //更新页码
      temp_params.pageSize = this.queryCount;
      await this.getUserSearch(temp_params); 
    },
  },
  // 方法集合
  methods: {
    onLoad() {
      //当组件滚动到底部时，会触发 load 事件并将继续查询
      console.log("onLoad");
      console.log(this.total);
      console.log(this.currentPage);
      console.log(this.queryCount);

      if (this.queryCondition.length == 0) {
        return; // 若查询条件为空，则不执行查询操作
      }

      this.currentPage++; //页码加一
      var temp_params = this.queryCondition; //使用临时变量加入页码，避免重复触发监听
      temp_params.currentPage = this.currentPage; //更新页码
      temp_params.pageSize = this.queryCount;
      if (this.currentPage * this.queryCount < this.total) {
        this.finished = false;
      } else {
        this.finished = true;
        return; // 若当前页码乘以每页数量大于总记录数，则不再加载数据
      }
      this.getUserSearch(temp_params);
    },
    async getUserSearch(temp_params) {
      // 创建一个包含分页条件的对象
    
      console.log(temp_params);

      const result = await reqSelUsersSearch(temp_params);
      console.log(result);
      if (result.data.records.length > 0) {
        result.data.records.forEach((element) => {
          element.key = element.uid;

          element.interest = element.interest.split(",");
          for (let i = 0; i < element.interest.length; i++) {
            let selectedInterest = element.interest[i];
            //console.log(selectedInterest);

            element.interest[i] = interestMap[selectedInterest];
            //console.log(element.interest[i]);
          }
          element.personality = element.personality.split(",");
          for (let i = 0; i < element.personality.length; i++) {
            let selectedPersonality = element.personality[i];
            //console.log(selectedPersonality);
            element.personality[i] = personalityMap[selectedPersonality];
            //console.log(element.personality[i]);
          }
          if (element.birth != null && element.birth != undefined) {
            element.age =
              new Date().getFullYear() - element.birth.substring(0, 4) + "岁"; //今天日期 - 生日 = 年龄
          }
          if (element.education != null && element.education != undefined) {
            element.education = educationMap[element.education];
          }
          if (
            element.workUnitType != null &&
            element.workUnitType != undefined
          ) {
            element.workUnitType = work_unit_typeMap[element.workUnitType];
            //console.log(element.workUnitType);
          }
          if (element.job != null && element.job != undefined) {
            element.job = jobMap[element.job];
          }

          if (
            element.lookingFor.annualIncomeRange != null &&
            element.lookingFor.annualIncomeRange != undefined
          ) {
            element.lookingFor.annualIncomeRange =
              annual_incomeMap[element.lookingFor.annualIncomeRange];
            if (element.lookingFor.annualIncomeRange != "不限") {
              element.lookingFor.annualIncomeRange =
                "年入" + element.lookingFor.annualIncomeRange;
            }
          }
          if (element.header != null && element.header != undefined) {
            element.headimg =this.$config.imgUrl + util.imgPath(element.header, "_small");
          } else {
            element.headimg = "./img/logo/logo.jpg"; //默认头像
          }

          //将数据赋值给当前页面数据对象
          this.total = result.data.total; //总条数
          console.log(this.total);
          //总条数/页面条数 向上取整 = 当前页码则
          console.log(Math.ceil(result.data.total / this.queryCount));
          console.log(this.currentPage);
          console.log(
            Math.ceil(result.data.total / this.queryCount) == this.currentPage
          );
          if (
            this.currentPage >= Math.ceil(result.data.total / this.queryCount)
          ) {
            this.loading = false;
            this.finished = true; // 没有更多数据了
            console.log("没有更多数据了");
          }

          this.userList.push(element);
        });
      
      }

      // 加载状态结束
      this.loading = false;//这个状态控制是否触发@load="onLoad"方法 
    },
    showDialog() {

      Dialog.alert({
        message: "您未登录，请先登录！",
      }).then(() => {
        // on confirm
        this.$store.dispatch("recordUserInfo", null);
        this.$router.push("/login");
      });
    },

    /**
     * 设置三角图标为黑色
     */
    updateDynamicStyles() {
      // 创建一个内联样式标签
      let styleTag = document.createElement("style");
      styleTag.innerHTML = `
        .van-dropdown-menu__title::after {
          border-color: transparent transparent #000000 #000000 !important;
        }
      `;

      // 将样式标签插入到 head 中
      document.head.appendChild(styleTag);
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (!this.$store.state.token) {
      this.showDialog();
      return;
    }
    //获取用户性别信息 若等于1则搜索0，若0则搜索1

    console.log(this.$store.state.userInfo);
    console.log(this.$store.state.userInfo.member_info.sex);
    if (this.$store.state.userInfo.member_info.sex == 1) {
      this.queryGender = 2;
    } else if (this.$store.state.userInfo.sex == 2) {
      this.queryGender = 1;
    } else {
      this.queryGender = -1;
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.updateDynamicStyles();
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
    <style lang='scss' scoped>
// @import url(); 引入公共css类
</style>