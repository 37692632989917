
import { Image as VanImage } from 'vant'; 
import request from "@/api/http.js"; // 引入自定义的 axios 实例
const VantRequestPlugin = {
  install(Vue) {
    // 重写 VanImage 组件的请求逻辑
    Vue.component('van-image', {
      extends: VanImage,
      methods: {
        async loadImage() {
          try {
            const response = await request.get(this.src, {
              responseType: 'blob'
            });
            this.currentSrc = URL.createObjectURL(response);
          } catch (error) {
            console.error('Error loading image:', error);
            this.error = true;
          }
        }
      }
    });
  }
};

export default VantRequestPlugin;