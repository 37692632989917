<!-- 编辑头像 -->
<template>
  <div class="EditPhoto">
    
    <div>请上传头像</div>
    <van-uploader :headers="getHeaders"
      v-model="fileList_header"
      multiple
      :max-size="5000 * 1024"
      :max-count="1"
      image-fit="contain"
      :after-read="afterRead_header"
      :before-delete="beforeDelete_header"
      @oversize="onOversize"
    >  
    </van-uploader>

    <div>请上传展示照片可多选最多6张</div>
    <van-uploader :headers="getHeaders"
      v-model="fileList"
      multiple
      :max-size="5000 * 1024"
      :max-count="6"
      image-fit="contain"
      :after-read="afterRead"
      :before-delete="beforeDelete"
      @oversize="onOversize"
    > 
    
    </van-uploader>
 

    <van-cell center > 
      <template #right-icon> 
        <div class="container" is-link  >
          <div class="m_fk_sub_text_left">VIP点击选择展示条件</div>
          <div  class="m_fk_sub_text_right"> <van-button :disabled="isButtonDisabled"  @click="showPopup('permission_purchase')" type="info">{{ permission_purchase_text }}</van-button>
          </div>
                
        </div>
        
      </template>
    </van-cell>
    <van-popup
      v-model="isPopupVisible"
      :close-on-click-overlay="false"
      round
      style="width: 80%"
    >
    <div id="permission_purchase" v-show="permission_purchaseObjectShow">
        <van-checkbox-group v-model="permission_purchase" style="padding: 10px">
          <van-row type="flex" justify="space-between">
            <van-col span="6"> <span @click="closePopup">取消</span></van-col>
            <van-col span="6"> <span>展示权限</span></van-col>
            <van-col span="6" style="text-align: right; color: green">
              <span @click="popup_typeConfirm('permission_purchase')"
                >确定</span
              ></van-col
            >
          </van-row>
          <van-cell-group>
            <van-cell
              v-for="(item, index) in permission_purchaseMap"
              clickable
              :key="index"
              :title="item"
              icon=""
            >
              <template #right-icon>
                <van-checkbox
                  :name="index.toString()"
                  shape="square"
                  ref="permission_purchase_checkboxes"
                />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>
    </van-popup>



    <div style="padding: 16px">
      <van-button
        round
        block
        type="primary"
        native-type="button"
        color="linear-gradient(to right,#ff3357 , #FFC0CB)"
        @click="SubmitEvent"
        >提交进入下步</van-button
      >
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import { Uploader } from "vant";
import { Popup } from "vant";
// 在另一个语句中导入命名导出的 sendImage 函数
import { sendImage } from "@/api/http";
import ajax from "@/api/http"; 
import compressFile from "@/common/util/js/imageUtils.js";
import { Button } from 'vant';

Vue.use(Button);
Vue.use(Uploader);
Vue.use(Popup);
export default {
  //name填入本组件名字
  name: "EditPhoto",
  // components: {《组件名称》},
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      fileList: [], 
      fileList_header: [],  
      markList:[],
      pathList:[], 

      isPopupVisible: false, 
      isButtonDisabled: true, // 新增禁用状态变量
      //图片查看权限 (login登录可见 ,vip vip等级可见，svip线下vip可见,realName实名认证可见,city同城可见，car有车可见，hosuse有房可见，job_guoqi国企可见,job_school教师可见，)
      permission_purchaseMap: {
        login: "登录可见",
        vip: "VIP等级可见",
        svip: "线下VIP可见",
        realName: "实名认证可见",
        //city: "同城可见",
        car: "有车可见",
        house: "有房可见",
        job_guoqi: "国企可见",
        job_siqi: "私企可见",
        job_jiaoshi: "教师可见",  
        job_shiye: "事业单位可见",  
        job_gongwuyuan: "公务员可见",
        job_laoban: "个体老板可见",   
        job_zhengfu: "政府机关可见",   
        job_xuexiao: "学校工作可见",   
        job_yiyuan: "医院工作可见",   
        job_lvshi: "律师工作可见", 
        job_jundui: "军队工作可见", 
      },
      permission_purchase: ['login'],
      permission_purchase_text: "登录用户可见",
      permission_purchaseObjectShow: false, 

      // 图片数量
      number: 0, 
    };
  },
  // 监听属性 类似于data概念
  computed: {
    
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
 
    beforeDelete_header() {  
      const formData = new FormData();
      formData.append('mark', 'header');
      //提交删除图片请求
      ajax(
        this.$config.apiUrl + "/api/resources/photo/delete", // 请求地址
        formData, //请求参数
        "POST"
      )
        .then((data) => {
          this.wait = false;
          console.log("成功:", data); 
           
        })
        .catch((error) => {
          this.wait = false;
          console.error("Error:", error);
          // 处理错误
        }); 

 
      return true;
    },


    async afterRead_header(files) {  
      if (!Array.isArray(files)) {
        files = [files];
      }
  
      for (const file of files) {
   
        file.status = "uploading";
        file.message = "上传中...";

        // 压缩图片
        console.log("file压缩开始");
        const file_compress = await compressFile(file.file);
        console.log("file压缩完成");

        // 上传图片至服务器
        const formData = new FormData();
        formData.append("image", file_compress, file.name);
        formData.append("mark", "header");
        //分割数组用逗号分隔字符串 
        const permission_purchase_temp = this.permission_purchase.join(","); 
        formData.append("permission", permission_purchase_temp); 

        let timeoutId = setTimeout(() => {
          file.status = "failed";
          file.message = "上传超时";
        }, 20000);

        sendImage(
          this.$config.apiUrl + "/api/resources/photo/upload/photo", // 请求地址
          formData
        )
          .then((res) => {
            console.log("res", res);
            if (res.code == 0) {
              clearTimeout(timeoutId); // 清除定时器
              file.status = "done";
              file.message = "上传成功";
            }
          })
          .catch((error) => {
            console.error("上传失败", error);
            clearTimeout(timeoutId); // 清除定时器
            file.status = "failed";
            file.message = "上传失败";
          });
      }
    },


    beforeDelete(file) { 
      console.log("file.number", file.number);
      console.log("this.number", this.number);
      const formData = new FormData();
      formData.append('mark', 'photo_' + file.number);
      //提交删除图片请求
      ajax(
        this.$config.apiUrl + "/api/resources/photo/delete", // 请求地址
        formData, //请求参数
        "POST"
      )
        .then((data) => {
          this.wait = false;
          console.log("成功:", data); 
        })
        .catch((error) => {
          this.wait = false;
          console.error("Error:", error);
          // 处理错误
        }); 


      this.number = this.number - 1;
      return true;
    },


    async afterRead(files) { 
      if (!Array.isArray(files)) {
        files = [files];
      }

      console.log("this.number", this.number);
      let mark = ""; 
      for (const file of files) {

        this.number = this.number + 1;
       
        if (!file.number || file.number == "" || file.number == "undefined" ){
          file.number = this.number;
        }
        console.log("file.number", file.number);

        mark = "photo_" + file.number;
        file.status = "uploading";
        file.message = "上传中...";

        // 压缩图片
        console.log("file压缩开始");
        const file_compress = await compressFile(file.file);
        console.log("file压缩完成");

        // 上传图片至服务器
        const formData = new FormData();
        formData.append("image", file_compress, file.name);
        formData.append("mark", mark);
        formData.append("permission", this.permission_purchase);
        this.number = this.number + 1;

        let timeoutId = setTimeout(() => {
          file.status = "failed";
          file.message = "上传超时";
        }, 20000);

        sendImage(
          this.$config.apiUrl + "/api/resources/photo/upload/photo", // 请求地址
          formData
        )
          .then((res) => {
            console.log("res", res);
            if (res.code == 0) {
              clearTimeout(timeoutId); // 清除定时器
              file.status = "done";
              file.message = "上传成功";
            }
          })
          .catch((error) => {
            console.error("上传失败", error);
            clearTimeout(timeoutId); // 清除定时器
            file.status = "failed";
            file.message = "上传失败";
          });
      }
    },
    SubmitEvent() {
      this.updateImgPermission();
      //通知父容器
      this.$emit("parentFunction");
    },


    popup_typeConfirm(name) {
      const typeArray = this.$data[name];
      const typeArrayMap = this.$data[name + "Map"];
      let text = "";

      if (typeArray.includes("99")) {
        text = "不限";
      } else {
        text = typeArray.map((type) => typeArrayMap[type]).join(",");
      }

      this.$data[`${name}_text`] = text;
      console.log("text", text);

      // 执行其他操作，如关闭弹窗
      this.closePopup();
    },
    closePopup() {
      this.isPopupVisible = false; // 关闭弹窗
    },
    showPopup(id) {
      // 重置所有以'Show'结尾的属性为false
      for (let key in this.$data) {
        if (key.endsWith("ObjectShow")) {
          this[key] = false;
        }
      }

      // 根据传入的id设置对应的显示标志为true
      const showPropName = id + "ObjectShow";
      console.log("showPropName:", showPropName);
      if (Object.prototype.hasOwnProperty.call(this, showPropName)) {
        this[showPropName] = true;
        console.log("showPropName:", showPropName);
      }

      // 设置弹出窗口的可见性
      this.isPopupVisible = true;
    },


    
  getHeaders() {
    return {
      token: this.$store.state.token
    }
  },


  updateImgPermission() {  
      const formData = new FormData();   
      this.pathList.forEach(path => formData.append("paths", path));
      this.markList.forEach(mark => formData.append("marks", mark));
      formData.append("permission", this.permission_purchase); 
      //提交删除图片请求
      ajax(
        this.$config.apiUrl + "/api/resources/photo/update/photo/permission", // 请求地址
        formData, //请求参数
        "POST"
      )
        .then((data) => {
          this.wait = false;
          console.log("成功:", data); 
           
        })
        .catch((error) => {
          this.wait = false;
          console.error("Error:", error);
          // 处理错误
        }); 

 
      return true;
    },
    onOversize() {
    this.$toast.fail('图片大小不能超过 5MB!');
    }



  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    //反显用户照片
    if (this.$store.state.userInfo.member_img.header && this.$store.state.userInfo.member_img.header != "") { 
      this.pathList.push(this.$store.state.userInfo.member_img.header); 
      this.markList.push('header'); 
      this.fileList_header.push({ url: this.$config.imgUrl + this.$store.state.userInfo.member_img.header,number:'1' });
    }
    if (this.$store.state.userInfo.member_img.photo1 && this.$store.state.userInfo.member_img.photo1 != "") {
      this.pathList.push(this.$store.state.userInfo.member_img.photo1); 
      this.markList.push('photo_1');
      this.number = this.number + 1;
      this.fileList.push({ url: this.$config.imgUrl + this.$store.state.userInfo.member_img.photo1,number:'1' });
    }
    console.log("this.$store.state.userInfo.member_img.photo2", this.$store.state.userInfo.member_img.photo2);
    if (this.$store.state.userInfo.member_img.photo2 && this.$store.state.userInfo.member_img.photo2 != "") {
      this.pathList.push(this.$store.state.userInfo.member_img.photo2); 
      this.markList.push('photo_2');
      this.number = this.number + 1;
      this.fileList.push({ url: this.$config.imgUrl + this.$store.state.userInfo.member_img.photo2,number:'2' });
    }
    if (this.$store.state.userInfo.member_img.photo3 && this.$store.state.userInfo.member_img.photo3 != "") {
      this.pathList.push(this.$store.state.userInfo.member_img.photo3); 
      this.markList.push('photo_3');
      this.number = this.number + 1;
      this.fileList.push({ url: this.$config.imgUrl + this.$store.state.userInfo.member_img.photo3,number:'3' });
    }
    if (this.$store.state.userInfo.member_img.photo4 && this.$store.state.userInfo.member_img.photo4 != "") {
      this.pathList.push(this.$store.state.userInfo.member_img.photo4); 
      this.markList.push('photo_4');
      this.number = this.number + 1;
      this.fileList.push({ url: this.$config.imgUrl + this.$store.state.userInfo.member_img.photo4,number:'4' });
    }
    if (this.$store.state.userInfo.member_img.photo5 && this.$store.state.userInfo.member_img.photo5 != "") {
      this.pathList.push(this.$store.state.userInfo.member_img.photo5); 
      this.markList.push('photo_5');
      this.number = this.number + 1;
      this.fileList.push({ url: this.$config.imgUrl + this.$store.state.userInfo.member_img.photo5,number:'5' });
    }

    //根据图片名字取出括号内的权限值，放入数组内
    if (this.$store.state.userInfo.member_img.header && this.$store.state.userInfo.member_img.header != "") {
      this.permission_header = this.$store.state.userInfo.member_img.header.match(/\(([^)]+)\)/)[1];
      console.log("this.permission_header", this.permission_header);
      //逗号分割权限值放入数组内
      this.permission_purchase = this.permission_header.split(",");
      console.log("this.permission_purchase", this.permission_purchase);
      this.popup_typeConfirm('permission_purchase'); //反显文本
    }



  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

     
    //挂载完成后判断是否是vip开放按钮点击权限
    console.log("this.$store.state.memberPurse.vip", this.$store.state.memberPurse.vip);
    if(this.$store.state.memberPurse.vip > 0){
      this.isButtonDisabled = false;
    }

    const label = document.querySelectorAll(".van-uploader__preview-image");
    label.forEach((item) => {
      item.style.height = "120xp !important";
      console.log("item");
    });
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.EditPhoto {
  text-align: center;
  padding: 8px;
  background-color: #fff;
  border-radius: 15px;
}
.container {
            display: flex;
        }
.m_fk_sub_text_left{
  width: 40%;
  box-sizing: border-box;
}
.m_fk_sub_text_right{  
  margin-left: 8px;
  box-sizing: border-box;
}
.m_fk_sub_text {
  text-align: center;
  //水平居中
  display: flex;
  justify-content: center; // 水平居中  
}

</style>