<!-- 认证用户身份信息页面 -->
<template>
<div class=''>
    <van-field
        v-model="real_name"
        type="text"
        name="realName" 
        label="真实姓名"
        placeholder="真实姓名不会公开展示"
        :rules="[{ required: true, message: '请填写真实姓名' }]"
      />
    <van-field
        v-model="certificate"
        type="text"
        name="certificate" 
        label="身份证"
        placeholder="身份证号码保密不会公开展示，认证时使用" 
      />

      <div style="padding: 16px">
        <van-button
          round
          block
          type="primary"
          native-type="button"
          color="linear-gradient(to right,#ff3357 , #FFC0CB)"
          @click="auth"
          >提交进入下步</van-button
        >
      </div>
</div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
//import { mapState } from "vuex";
import {
  Form,
  Field,
  Picker,
  Popup,
  Toast,
  Cell,
  CellGroup, 
  Loading,
  Overlay,
} from "vant";
import ajax from "@/api/http";
Vue.use(Form)
  .use(Field)
  .use(Picker)
  .use(Popup)
  .use(Toast)
  .use(Cell)
  .use(CellGroup) 
  .use(Loading)
  .use(Overlay);
export default {
//name填入本组件名字
name:'UserInfoPage',
// components: {《组件名称》},
// import引入的组件需要注入到对象中才能使用
  components: {},
  data  () {
// 这里存放数据
    return {
        real_name:"",
        certificate:"",

    }
  },
// 监听属性 类似于data概念
  computed: {},
// 监控data中的数据变化
  watch: {},
// 方法集合
  methods: {


    // 提交参数拿到认证连接
    auth(){
      ajax(
        this.$config.apiUrl + "/api/system/realAuth/getAuthURL", // 请求地址
        {"Name":this.real_name,"IdCard":this.certificate,"RedirectUrl":this.$config.baseUrl+"/user/realAuth/userInfo"}, //请求参数
        "POST"
      )
        .then((data) => {
          this.wait = false;
          console.log("成功:", data);
          //通知父容器
          this.$emit("parentFunction");
          //判断是否获取成功
          if (data.code == 0) {
            //跳转链接
            window.location.href = data.auth.Response.BizToken.Url;
          } else {
            Toast.fail("获取验证失败，请稍后再试！");
          }
 



        })
        .catch((error) => {
          this.wait = false;
          console.error("Error:", error);
          // 处理错误
        });

     }
  },
// 生命周期 - 创建完成（可以访问当前this实例）
  created  () {

  },
// 生命周期 - 挂载完成（可以访问DOM元素）
  mounted  () { 
  },
  beforeCreate  () {}, // 生命周期 - 创建之前
  beforeMount  () {}, // 生命周期 - 挂载之前
  beforeUpdate  () {}, // 生命周期 - 更新之前
  updated  () {}, // 生命周期 - 更新之后
  beforeDestroy  () {}, // 生命周期 - 销毁之前
  destroyed  () {}, // 生命周期 - 销毁完成
  activated  () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类

</style>