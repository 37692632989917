/*
直接更新state的多个方法的对象
 */
import Vue from 'vue'
import {
  RECEIVE_ADDRESS,
  RECEIVE_CATEGORYS,
  RECEIVE_SHOPS,
  RECEIVE_USER_INFO,
  RESET_USER_INFO,
  RECEIVE_WEIXIN_USER_INFO,
  RESET_WEIXIN_USER_INFO,
  RECEIVE_INFO,
  RECEIVE_RATINGS,
  RECEIVE_GOODS,
  INCREMENT_FOOD_COUNT,
  DECREMENT_FOOD_COUNT,
  CLEAR_CART,
  RECEIVE_SEARCH_SHOPS,
  RECEIVE_TOKEN,
  RESET_TOKEN,
  RECEIVE_MEMBERPURSE,
  RESET_MEMBERPURSE,
  RECEIVE_PERMISSION,
  RESET_PERMISSION 
} from './mutation-types'

export default {
  [RECEIVE_ADDRESS] (state, {address}) {
    state.address = address
  },

  [RECEIVE_CATEGORYS] (state, {categorys}) {
    state.categorys = categorys
  },

  [RECEIVE_SHOPS] (state, {shops}) {
    state.shops = shops
  },

  //接收用户信息
  [RECEIVE_USER_INFO] (state, {userInfo}) {
    state.userInfo = userInfo
  },
  //重置用户信息对象
  [RESET_USER_INFO] (state) {
    state.userInfo = {}
  },
  
  //接收微信用户信息
  [RECEIVE_WEIXIN_USER_INFO] (state, {userInfo}) {
    state.userWeixinInfo = userInfo
  },
  //重置微信用户信息对象
  [RESET_WEIXIN_USER_INFO] (state) {
    state.userWeixinInfo = {}
  },
  
   //接收token信息
   [RECEIVE_TOKEN] (state, {token}) {
    state.token = token
  },
  //重置token对象
  [RESET_TOKEN] (state) {
    state.token = {}
  },
 
  [RECEIVE_PERMISSION] (state, {permission}) {
    state.permission = permission
  },
  //重置token对象
  [RESET_PERMISSION] (state) {
    state.permission = {}
  },

 //接收MemberPurse信息
 [RECEIVE_MEMBERPURSE] (state, {memberPurse}) {
  state.memberPurse = memberPurse
},
//重置MemberPurse对象
[RESET_MEMBERPURSE] (state) {
  state.memberPurse = {}
},


  
  


  [RECEIVE_INFO](state, {info}) {
    state.info = info
  },

  [RECEIVE_RATINGS](state, {ratings}) {
    state.ratings = ratings
  },

  [RECEIVE_GOODS](state, {goods}) {
    state.goods = goods
  },

  [INCREMENT_FOOD_COUNT](state, {food}) {
    if(!food.count) { // 第一次增加
      // food.count = 1  // 新增属性(没有数据绑定)
      /*
      对象
      属性名
      属性值
       */
      Vue.set(food, 'count', 1) // 让新增的属性也有数据绑定
      // 将food添加到cartFoods中
      state.cartFoods.push(food)
    } else {
      food.count++
    }
  },
  [DECREMENT_FOOD_COUNT](state, {food}) {
    if(food.count) {// 只有有值才去减
      food.count--
      if(food.count===0) {
        // 将food从cartFoods中移除
        state.cartFoods.splice(state.cartFoods.indexOf(food), 1)
      }
    }
  },

  [CLEAR_CART](state) {

    // 清除food中的count
    state.cartFoods.forEach(food => food.count = 0)
    // 移除购物车中所有购物项
    state.cartFoods = []
  },

  [RECEIVE_SEARCH_SHOPS](state, {searchShops}) {
    state.searchShops = searchShops
  },
}