export const interestMap = {
  0: "躺平族",
  1: "内卷王",
  2: "历史迷",
  3: "颜值控",
  4: "夜猫子",
  5: "技术宅",
  6: "学霸光环",
  7: "学渣附体",
  8: "社恐份子",
  9: "吃货达人",
  10: "佛系青年",
  11: "斜杠青年",
  12: "千年书虫",
  13: "体育达人",
  14: "手作匠人",
  15: "科技极客",
  16: "公益先锋",
  17: "时尚达人",
  18: "游戏大神",
  19: "宠物达人",
  20: "舞林高手",
  21: "购物狂魔",
  22: "拍照达人",
  23: "王者战五渣",
  24: "文艺小清新",
  25: "旅行爱好者",
  26: "音乐发烧友",
  27: "环保主义者",
  28: "养生小能手",
  29: "美食探索者",
  30: "社交牛逼症",
  31: "涂鸦艺术家",
  32: "天命打工人",
  33: "剧本杀之手",
  34: "二次元穿越者",
  35: "狼人血染之王",
  36: "脱口秀段子王",
  37: "精致的猪猪孩",
  38: "重生之三世在看剧",
};


export const personalityMap = {
  0: "开朗",
  1: "活泼",
  2: "热情",
  3: "外向",
  4: "善良",
  5: "乐观",
  6: "勇敢",
  7: "幽默",
  8: "聪慧",
  9: "达理",
  10: "稳重",
  11: "沉静",
  12: "谨慎",
  13: "温和",
  14: "实在",
  15: "真诚",
  16: "孤单",
  17: "冲动",
  18: "热血",
  19: "傲娇",
  20: "内向",
  21: "耿直",
  22: "机智",
  23: "务实",
};

export const work_unit_typeMap = {
  0: "央企/国企",
  1: "事业单位",
  2: "私企",
  3: "公务员",
  4: "个体老板",
  5: "政府机关",
  6: "教师",
  7: "在校学生",
  8: "上市公司",
  9: "医院",
  10: "律师机构",
  11: "退休",
  12: "部队/武警",
  13: "待业",
  14: "其他",
};

export const jobMap = {
  0: "公务员",
  1: "公司职员",
  2: "教师",
  3: "医生",
  4: "护士",
  5: "律师",
  6: "经营个体户",
  7: "媒体人",
  8: "金融人",
  9: "自由人",
  10: "农业从业者",
  11: "企业高管",
  12: "军人",
  13: "科研人员",
  14: "工程师技术人员",
  15: "艺术类",
  98: "其他",
};


export const educationMap = {
  0: "初中及以下",
  1: "高中/职高",
  2: "专科",
  3: "本科",
  4: "硕士",
  5: "博士",
};

export const annual_incomeMap = {
  0: "3万元",
  1: "5万元",
  2: "8万元",
  3: "10万元",
  4: "12万元",
  5: "15万元",
  6: "20万元及以上",
};

export const house_purchaseMap = {
  0: "无",
  1: "目前无，有能力购买",
  2: "有",
  3: "多套",
};

export const car_purchaseMap = {
  0: "无",
  1: "目前无，有能力购买",
  2: "有",
  3: "多辆",
};

export const marriage_historyMap = {
  0: "未婚",
  1: "离异未育",
  2: "离异已育/未婚已育",
  3: "丧偶",
};

export const is_child_statusMap = {
  0: "无",
  1: "小孩为男孩跟自己",
  2: "小孩为女孩跟自己",
  3: "小孩跟对方",
  4: "其他情况",
};

export const is_parentMap = {
  0: "父母健在",
  1: "父母离异",
  2: "只有父亲健在",
  3: "只有母亲健在",
  4: "自己一人",
};


export const brothers_numberMap = {
  0: "独生子女",
  1: "1个",
  2: "2个",
  3: "3个",
  4: "4个",
  5: "5个",
  6: "6个",
  7: "7个",
  8: "8个",
  9: "9个",
};

export const living_with_parentsMap = {
  0: "愿意",
  1: "不愿意",
  2: "视情况而定",
  3: "尊重伴侣意见",
};

export const is_smokerMap = {
  0: "不吸",
  1: "烟民",
  2: "应酬才吸",
  3: "偶尔吸",
};

export const is_alcoholicMap = {
  0: "滴酒不沾",
  1: "偶尔小酌",
  2: "应酬才喝",
  3: "好酒量经常喝",
};

export const marriage_typeMap = {
  0: "嫁娶",
  1: "两顾",
  2: "上门",
  3: "都可以接受",
};

export const is_petMap = {
  0: "不喜欢养",
  1: "目前不养，可以接受",
  2: "养有宠物",
};

export const zodiac_signMap = {
  0: "水瓶座",
  1: "双鱼座",
  2: "白羊座",
  3: "金牛座",
  4: "双子座",
  5: "巨蟹座",
  6: "狮子座",
  7: "处女座",
  8: "天秤座",
  9: "天蝎座",
  10: "射手座",
  11: "摩羯座",
};

export const blood_typeMap = {
  0: "不清楚",
  1: "A型血",
  2: "B型血",
  3: "O型血",
  4: "AB型血",
  5: "RH型血",
  6: "ABO型血",
  7: "其他",
};

export const permission_purchaseMap = {
  login: "登录",
  vip: "VIP等级",
  svip: "线下VIP",
  realName: "实名认证",
  //city: "同城",
  car: "有车",
  house: "有房",
  job_guoqi: "国企",
  job_siqi: "私企",
  job_jiaoshi: "教师",  
  job_shiye: "事业单位",  
  job_gongwuyuan: "公务员",
  job_laoban: "个体老板",   
  job_zhengfu: "政府机关",   
  job_xuexiao: "学校工作",   
  job_yiyuan: "医院工作",   
  job_lvshi: "律师工作", 
  job_jundui: "军队工作", 
}

export const authMap = {
  0: "点击提交审核", 
  1: "已提交", 
  2: "已完成审核", 
  3: "审核失败"
}