//暴露给用户使用，借此触发mutations中的方法，保存数据（可执行异步操作）
import {
  RECEIVE_USER_INFO,
  //RESET_USER_INFO,
  RECEIVE_WEIXIN_USER_INFO,
  RECEIVE_TOKEN,
  RECEIVE_MEMBERPURSE,
  RECEIVE_PERMISSION, 

} from './mutation-types'
import {
  reqSelUserInfoMy
} from '../api/api'
export default {
  //同步记录用户信息
  recordUser({ commit }, userInfo) {
    commit(RECEIVE_USER_INFO, { userInfo })
  },

  // 异步获取用户信息
  async getUserInfo({ commit }) {
    const result = await reqSelUserInfoMy() //这是api方法
    if (result.code === 0) {
      const userInfo = result.data
      commit(RECEIVE_USER_INFO, { userInfo })
    }
  },

  //同步记录微信用户信息
  recordWeixinUser({ commit }, userInfo) {
    commit(RECEIVE_WEIXIN_USER_INFO, { userInfo })
  },

  //同步记录token
  recordToken({ commit }, token) {
    commit(RECEIVE_TOKEN, { token })
  },
  recordPermission({ commit }, permission) {
    commit(RECEIVE_PERMISSION, { permission })
  },
  
 
  //同步记录用户钱包等级信息
  recordMemberPurse({ commit }, memberPurse) {
    commit(RECEIVE_MEMBERPURSE, { memberPurse })
  },

}