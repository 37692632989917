<!-- 信息展示页1 -->
<template>
	<div class="info1">
		<van-swipe :autoplay="3000">
			<van-swipe-item v-for="(m_image, index) in images" :key="index">
				<van-image class="swipe-img" style="object-fit: contain" lazy-load fit="cover" :src="m_image"
					@click="photoClick(m_image)" />
			</van-swipe-item>
		</van-swipe>

		<div class="m_fk">
			<van-row gutter="1" clases="m_fk_row">
				<van-col span="6">
					<div class="m_fk_title_fk">
						<van-image class="m_fk_title_img"
							:src="require('@/pages/user/infoPage/img/chenhao.png')"></van-image>
					</div>
				</van-col>
				<van-col span="6" v-for="(m_interest, index) in interest" :key="index"
					:style="interestTagStyle"><van-tag size="medium" type="warning">
						{{ interestMap[m_interest] }}
					</van-tag></van-col>
			</van-row>
		</div>

		<div class="m_fk">
			<van-row gutter="1" clases="m_fk_row">
				<van-col span="6">
					<div class="m_fk_title_fk">
						<van-image class="m_fk_title_img"
							:src="require('@/pages/user/infoPage/img/gexing.png')"></van-image>
					</div>
				</van-col>
				<van-col span="6" v-for="(m_personality, index) in personality" :key="index"
					:style="personalityTagStyle"><van-tag size="medium" type="success">
						{{ personalityMap[m_personality] }}
					</van-tag></van-col>
			</van-row>
		</div>

		<div class="m_fk">
			<van-row gutter="1" clases="m_fk_row">
				<van-col span="6">
					<div class="m_fk_title_fk">
						<van-image class="m_fk_title_img"
							:src="require('@/pages/user/infoPage/img/renzheng.png')"></van-image>
					</div>
				</van-col>

				<van-col span="4">
					<div class="m_fk_sub">
						<van-icon color="#009900" :name="require('@/pages/user/infoPage/img/renzheng-shenfen-ok.png')
							" size="30" />
					</div>
					<div class="m_fk_sub_text m_fk_sub">身份认证</div>
				</van-col>

				<van-col span="4">
					<div class="m_fk_sub">
						<van-icon color="#009900" :name="require('@/pages/user/infoPage/img/renzheng-geshui-ok.png')
							" size="30" />
					</div>
					<div class="m_fk_sub_text m_fk_sub">个税认证</div>
				</van-col>

				<van-col span="4">
					<div class="m_fk_sub">
						<van-icon color="#009900" :name="require('@/pages/user/infoPage/img/renzheng-fangzi-ok.png')
							" size="30" />
					</div>
					<div class="m_fk_sub_text m_fk_sub">房子认证</div>
				</van-col>

				<van-col span="4">
					<div class="m_fk_sub">
						<van-icon color="#009900" :name="require('@/pages/user/infoPage/img/renzheng-chezi-ok.png')"
							size="30" />
					</div>
					<div class="m_fk_sub_text m_fk_sub">车子认证</div>
				</van-col>
			</van-row>
		</div>

		<div class="m_fk">
			<van-row gutter="1">
				<van-col span="6">
					<div class="m_fk_title_fk">
						<van-image class="m_fk_title_img"
							:src="require('@/pages/user/infoPage/img/shuxing.png')"></van-image>
					</div>
				</van-col>
				<van-col span="18">
					<div class="m_fk_info_uid">嘉宾编号:{{ this.uid }} <van-icon class="m_fk_info_icon"
							v-show="this.isMatchCheck" :color="likeColor" @click="likeMethod" name="like-o" /><van-icon
							class="m_fk_info_icon" name="star-o" :color="collectColor"
							@click="collectMethod" /><van-icon @click="onclickButtonShare" class="m_fk_info_icon" name="share-o" /></div>
				</van-col>
			</van-row>

			<van-row class="m_fk_info_row">
				<van-col
					v-if="this.real_name && this.$store.state.userInfo.uid != undefined && this.$store.state.userInfo.member_info.vip > 0"
					span="6">名字{{ this.real_name }}</van-col>
				<van-col span="6">昵称 {{ this.nick_name }}</van-col>
				<van-col span="6">{{ this.sexMap[this.sex] }}</van-col>
				<div v-if="this.marriage_history == 0">
					<van-col span="6">{{
						this.marriage_historyMap[this.marriage_history]
					}}</van-col>
				</div>
			</van-row>

			<van-row class="m_fk_info_row">
				<van-col span="6">身高{{ this.height }}cm</van-col>
				<van-col span="6">体重{{ this.weight }}kg</van-col>
				<van-col span="6">学历{{ this.educationMap[this.education] }}</van-col>
				<van-col span="6">{{ this.birth }}岁</van-col>
			</van-row>
			<hr />
			<van-row class="m_fk_info_row" v-if="this.marriage_history > 0">
				<div v-if="this.marriage_history > 0">
					<van-col span="10">{{
						this.marriage_historyMap[this.marriage_history]
					}}</van-col>
				</div>

				<van-col span="12" v-if="is_child_status > 0">{{
					this.is_child_statusMap[this.is_child_status]
				}}</van-col>
			</van-row>

			<van-row class="m_fk_info_row">
				<van-col span="3">{{ this.car_purchaseMap[this.car_purchase] }}车</van-col>
				<van-col span="3">{{ this.house_purchaseMap[this.house_purchase] }}房</van-col>
				<van-col span="6">年入{{ this.annual_incomeMap[this.annual_income] }}</van-col>
				<van-col span="12">职业{{ this.jobMap[this.job] }}</van-col>
			</van-row>
			<van-row class="m_fk_info_row">
				<van-col span="18">{{ this.company_name }}</van-col>
				<van-col span="6">类型{{ this.work_unit_typeMap[this.work_unit_type] }}</van-col>
			</van-row>
			<hr />
			<van-row class="m_fk_info_row">
				<van-col span="12">户口{{ this.getProvinceAbbreviation(this.hr_address) }}</van-col>
				<van-col span="12">居住{{
					this.getProvinceAbbreviation(this.habitation_address)
				}}</van-col>
			</van-row>

			<van-row class="m_fk_info_row">
				<van-col span="7">兄妹数量:{{ this.brothers_numberMap[this.brothers_number] }}</van-col>
				<van-col span="5">{{ this.is_parentMap[this.is_parent] }}</van-col>
				<van-col span="11">{{
					this.living_with_parentsMap[this.living_with_parents]
				}}婚后与父母同住</van-col>
			</van-row>

			<hr />

			<van-row gutter="0.5" class="m_fk_info_row">
				<van-col span="8">{{ this.is_smokerMap[this.is_smoker] }}烟</van-col>
				<van-col span="8">{{ this.is_alcoholicMap[this.is_alcoholic] }}酒</van-col>
				<van-col span="8">嫁娶形式:{{ this.marriage_typeMap[this.marriage_type] }}</van-col>
			</van-row>

			<van-row gutter="0.5" class="m_fk_info_row">
				<van-col span="8">宠物:{{ this.is_petMap[this.is_pet] }}</van-col>
				<van-col span="8">{{ this.zodiac_signMap[this.zodiac_sign] }}</van-col>
				<van-col span="8">血型:{{ this.blood_typeMap[this.blood_type] }}</van-col>
			</van-row>
		</div>

		<div class="m_fk">
			<van-row gutter="1" clases="m_fk_row">
				<van-col span="6">
					<div class="m_fk_title_fk">
						<van-image class="m_fk_title_img"
							:src="require('@/pages/user/infoPage/img/xuanyan.png')"></van-image>
					</div>
				</van-col>
				<van-col span="24">
					<div class="custom-font">
						{{ this.self_introduction }}
					</div>
				</van-col>
			</van-row>
		</div>

		<div class="m_fk">
			<van-row gutter="1" clases="m_fk_row">
				<van-col span="6">
					<div class="m_fk_title_fk">
						<van-image class="m_fk_title_img"
							:src="require('@/pages/user/infoPage/img/xunmi.png')"></van-image>
					</div>
				</van-col>
			</van-row>

			<van-row gutter="1" class="m_fk_info_row">
				<van-col span="7">{{ this.rquire_yearText }}</van-col>
				<van-col span="12">学历:{{ this.rquire_education_text }}</van-col>
				<van-col>{{
					this.rquire_marriage_history_text
				}}{{ this.sexMap[this.rquire_sex] }}士</van-col>
			</van-row>
			<hr />
			<van-row class="m_fk_info_row">
				<van-col span="12">身高{{ this.rquire_heightText }}</van-col>
				<van-col span="12">体重{{ this.rquire_weightText }}</van-col>
				<van-col span="16">职业{{ this.rquire_job_text }}</van-col>
				<van-col span="6">年入{{ this.rquire_annual_income_text }}</van-col>
			</van-row>
			<hr />

			<van-row class="m_fk_info_row" v-if="this.rquire_marriage_history_text != '未婚'">
				<div v-if="this.rquire_marriage_history_text > 0">
					<van-col span="10">{{
						rquire_marriage_history_text
					}}</van-col>
				</div>
			</van-row>

			<van-row class="m_fk_info_row">
				<van-col span="12">{{ this.rquire_car_purchase_text }}车</van-col>
				<van-col span="12">{{ this.rquire_house_purchase_text }}房</van-col>

				<van-col span="12">户口{{ this.rquire_hr_address_text }}</van-col>
				<van-col span="12">居住{{
					this.rquire_habitation_address_text
				}}</van-col>
			</van-row>

			<hr />
			<van-row gutter="1" class="m_fk_info_row">
				<van-col>{{ this.rquire_is_smoker_text }}烟&nbsp;</van-col>
				<van-col span="6">{{ this.rquire_is_alcoholic_text }}酒</van-col>
				<van-col span="12">嫁娶形式:{{ this.rquire_marriage_type_text }}</van-col>
			</van-row>

			<van-row gutter="0.5" class="m_fk_info_row">
				<van-col span="18">宠物:{{ this.rquire_is_pet_text }}</van-col>
			</van-row>
			<van-row gutter="0.5" class="m_fk_info_row">
				<van-col>星座:{{ this.rquire_zodiac_sign_text }}&nbsp;</van-col>
				<van-col>血型:{{ this.rquire_blood_type_text }}</van-col>
			</van-row>
			<br />

		</div>
		<van-share-sheet v-model="showShare" title="立即分享给好友" :options="ShareOptions" @select="onShareSelect" />
	</div>
</template>
<!--这里引入分离的业务js代码-->
<script>
import indexjs from './js/InfoOne.js'
export default {
	...indexjs,
}
</script>
<!--这里引入分离的界面样式代码-->
<style lang="scss" scoped>
@import './css/InfoOne.scss';
</style>