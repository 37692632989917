/*
包含多个基于state的getter计算属性的对象
 */
export default {

  /**
   * 获取全局用户信息
   * @param {*} state 
   * @returns 
   */
  getUserInfo (state) {
    return state.userInfo;
  },

  /**
   * 获取登录时微信信息
   * @param {} state 
   * @returns 
   */
  getUserWeixinInfo (state) {
    return state.userWeixinInfo;
  },
  
  getPermission (state) {
    return state.permission;
  },

  

  // totalCount (state) {
  //   return state.cartFoods.reduce((preTotal, food) => preTotal + food.count , 0)
  // },

  // totalPrice (state) {
  //   return state.cartFoods.reduce((preTotal, food) => preTotal + food.count*food.price , 0)
  // },

  // positiveSize (state) {
  //   return state.ratings.reduce((preTotal, rating) => preTotal + (rating.rateType===0?1:0) , 0)
  // }
}