<!--我收藏谁  -->
<template>
  <div class="">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getUserLookWho">
      <div class="card">
        <Card :userList="userList" />
      </div>
    </van-list>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { reqSelCollectMyCollection } from "@/api/api";
import util from "@/common/util/js/util";
import Card from "@/components/Card/Card.vue";
import {
  interestMap,
  personalityMap,
  educationMap,
  work_unit_typeMap,
  jobMap,
  annual_incomeMap,
} from "@/common/constants/constants.js";
import Vue from "vue";
import { List } from "vant";

Vue.use(List);
export default {
  //name填入本组件名字
  name: "myCollectionComponent",
  // components: {《组件名称》},
  // import引入的组件需要注入到对象中才能使用
  components: { Card },
  data() {
    // 这里存放数据
    return {
      userList: [],
      loading: false,
      finished: false,
      currentPage: 0,
      pageSize: 10,
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async getUserLookWho() {
      this.currentPage++; 
      const params = {
        currentPage: this.currentPage, pageSize: this.pageSize
      }; //请求参数
      const result = await reqSelCollectMyCollection(params);
      console.log("查询客户信息:", result.code);
      if (result.code === 0) {
        console.log("成功:", result.data);
        //把查到数据添加到当前页面数据对象中,转码
        if (result.data.records.length > 0) {
          result.data.records.forEach((element) => {
            element.key = element.logid;

            element.interest = element.interest.split(",");
            for (let i = 0; i < element.interest.length; i++) {
              let selectedInterest = element.interest[i];
              //console.log(selectedInterest);

              element.interest[i] = interestMap[selectedInterest];
              //console.log(element.interest[i]);
            }
            element.personality = element.personality.split(",");
            for (let i = 0; i < element.personality.length; i++) {
              let selectedPersonality = element.personality[i];
              //console.log(selectedPersonality);
              element.personality[i] = personalityMap[selectedPersonality];
              //console.log(element.personality[i]);
            }
            if (element.birth != null && element.birth != undefined) {
              element.age =
                new Date().getFullYear() -
                element.birth.substring(0, 4) +
                "岁"; //今天日期 - 生日 = 年龄
            }
            if (element.education != null && element.education != undefined) {
              element.education = educationMap[element.education];
            }
            if (
              element.workUnitType != null &&
              element.workUnitType != undefined
            ) {
              element.workUnitType = work_unit_typeMap[element.workUnitType];
              console.log(element.workUnitType);
            }
            if (element.job != null && element.job != undefined) {
              element.job = jobMap[element.job];
            }

            if (
              element.lookingFor.annualIncomeRange != null &&
              element.lookingFor.annualIncomeRange != undefined
            ) {
              element.lookingFor.annualIncomeRange =
                annual_incomeMap[element.lookingFor.annualIncomeRange];
              if (element.lookingFor.annualIncomeRange != "不限") {
                element.lookingFor.annualIncomeRange =
                  "年入" + element.lookingFor.annualIncomeRange;
              }
            }
            if (element.header != null && element.header != undefined) {
              element.headimg = this.$config.imgUrl + util.imgPath(element.header, "_small"); // 替换图片路径
            } else {
              element.headimg = "./img/logo/logo.jpg"; //默认头像
            }

            this.userList.push(element);
            //总条数/页面条数 向上取整 = 当前页码则
            console.log(Math.ceil(result.data.total / this.pageSize));
            if (Math.ceil(result.data.total / this.pageSize) == this.currentPage) {
              this.loading = false;
              this.finished = true; // 没有更多数据了
              return;
            }
          });
        }else{
          this.finished = true; // 没有更多数据了
        }

        // 加载状态结束
        this.loading = false;
      }


    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类</style>