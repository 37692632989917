import axios from 'axios';
import store from '@/store';
import router from '@/router'; // 导入Vue Router
// 使用 js-cookie 库

// 创建axios实例
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true, // 关键配置，允许携带cookie

});

// 添加请求拦截器
instance.interceptors.request.use(
  config => {
    const token = store.state.token;
    console.log("token:", token);
    if (token != null && token !== '' && token !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


// 添加响应拦截器
instance.interceptors.response.use(
  response => {
    // 如果响应头中有新的 token，则更新 Vuex store 中的 token
    const newToken = response.headers['authorization'];
    if (newToken != null && newToken !== '' && newToken !== undefined) {
      store.commit('recordToken', newToken.replace('Bearer ', ''));
    }
    console.log("newToken", newToken);

    return response;
  },
  error => {
    // 错误处理
    if (error.response) {
      // 检查响应状态码
      if (error.response.status === 214 && error.response.data === "Token无效") {
        // 清除token
        store.commit('updateToken', null);
        // 重定向到登录页面
        router.push('/login');
      }
    }
    return Promise.reject(error);
  }
);

/**
 * AJAX请求封装
 * @param {string} url - 请求URL
 * @param {Object} [data={}] - 请求数据
 * @param {string} [method='GET'] - 请求方法
 * @returns {Promise<any>} - 返回Promise，解析为response.data
 */
async function send(url, data = {}, method = 'GET') {
  // 从Vuex store中获取token
  const currentToken = store.state.token;
  console.log("currentToken:", currentToken);

  // 根据请求方法决定是否需要将数据作为body发送
  const config = {
    method,
    url,
    ...(method.toUpperCase() === 'GET' && Object.keys(data).length > 0
      ? { url: `${url}?${new URLSearchParams(data).toString()}` }
      : { data }),
    headers: {
      ...(currentToken ? { Authorization: `Bearer ${currentToken}` } : {}),
    },
  };

  // 如果 data 是 FormData 对象，则设置 Content-Type 为 multipart/form-data
  if (data instanceof FormData) {
    config.headers['Content-Type'] = undefined; // 让浏览器自动设置 Content-Type
  } else {
    config.headers['Content-Type'] = 'application/json';
  }

  try {
    const response = await instance(config); // 使用axios实例发送请求
    const responseData = response.data;

    // 检查响应数据中是否包含新的token
    if (responseData.token) {
      const newToken = responseData.token;
      console.log("newToken:", newToken);

      // 比较新旧token
      if (newToken !== currentToken) {
        // 更新Vuex store中的token
        store.commit('updateToken', newToken);
        console.log("Token updated to:", newToken);
      }
    }

    return responseData;
  } catch (error) {
    console.error('Error making request:', error);
    throw error;
  }
}



function axiosPromise(url, data = {}, method = 'GET') {
  // 从Vuex store中获取token
  const currentToken = store.state.token;
  console.log("currentToken:", currentToken);

  // 根据请求方法决定是否需要将数据作为body发送
  const config = {
    method,
    url,
    ...(method.toUpperCase() === 'GET' && Object.keys(data).length > 0
      ? { url: `${url}?${new URLSearchParams(data).toString()}` }
      : { data }),
    headers: {
      ...(currentToken ? { Authorization: `Bearer ${currentToken}` } : {}),
    },
    withCredentials: true, // 关键配置，允许携带cookie 



  };

  // 修改后的 http.js 拦截器逻辑
  if (data instanceof FormData) {
    config.headers['Content-Type'] = undefined;
  } else if (config.responseType === 'blob' && /\.(png|jpe?g|gif)$/i.test(config.url)) {
    // 图片下载请求处理
    config.headers['Content-Type'] = 'application/octet-stream';
    config.responseType = 'blob';
  } else if (typeof data === 'string' && data.startsWith('data:image')) {
    // Base64 图片上传处理
    config.headers['Content-Type'] = 'image/png'; // 根据实际格式调整
  } else {
    config.headers['Content-Type'] = 'application/json';
  }

  // 强制所有图片请求携带凭证
  if (config.url?.includes('/resources/')) {
    config.withCredentials = true;
  }


  return new Promise(function (resolve, reject) {
    // 执行异步ajax请求
    try {
      const config = {
        method: method,
        url: url,
        ...(method.toUpperCase() === 'GET' && Object.keys(data).length > 0
          ? { url: `${url}?${new URLSearchParams(data).toString()}` }
          : { data }),
        headers: {
          ...(currentToken ? { Authorization: `Bearer ${currentToken}` } : {}),
        },
      };

      // 如果 data 是 FormData 对象，则设置 Content-Type 为 multipart/form-data
      if (data instanceof FormData) {
        config.headers['Content-Type'] = undefined; // 让浏览器自动设置 Content-Type
      } else {
        config.headers['Content-Type'] = 'application/json';
      }

      const response = instance(config); // 使用axios实例发送请求
      const responseData = response.data;

      // 检查响应数据中是否包含新的token
      if (responseData.token) {
        const newToken = responseData.token;
        console.log("newToken:", newToken);

        // 比较新旧token
        if (newToken !== currentToken) {
          // 更新Vuex store中的token
          store.commit('updateToken', newToken);
          console.log("Token updated to:", newToken);
        }
      }

      resolve(responseData);
    } catch (error) {
      console.error('Error making request:', error);
      reject(error);
    }
  });
}


/**
 * 发送图片请求封装
 * @param {string} url - 请求URL
 * @param {FormData} imageData - 图片数据，应使用FormData封装
 * @returns {Promise<any>} - 返回Promise，解析为response.data
 */
async function sendImage(url, imageData) {
  // 从Vuex store中获取token
  const currentToken = store.state.token;

  // 配置请求，注意此时不使用Content-Type: application/json
  const config_img = {
    method: 'POST', // 通常发送图片使用POST方法
    url,
    data: imageData, // 使用FormData封装的图片数据
    headers: {
      // 手动设置Content-Type
      //'Content-Type': 'multipart/form-data',
      // 浏览器会自动设置正确的Content-Type和boundary
      ...(currentToken ? { Authorization: `Bearer ${currentToken}` } : {}),
    },
    withCredentials: true, // 允许发送凭证
  };
  console.log("Request config for image:", config_img); // 打印请求配置

  try {
    const response = await instance(config_img); // 使用axios实例发送请求
    return response.data; // 返回响应数据
  } catch (error) {
    console.error('Error sending image:', error);
    throw error; // 重新抛出错误以便上层处理
  }
}


async function loadImage(url) {
  try {
   const response = await instance.get(url, { 
      responseType: 'blob'
    })
    return URL.createObjectURL(response.data)
  } catch (error) {
    console.error('图片加载失败', error)
    return null
  }
}


// 导出 send 函数作为默认导出
export default send;

// 导出 axiosPromise 函数作为命名导出
export { axiosPromise };


// 导出 sendImage 函数作为命名导出
export { sendImage };

export { loadImage };