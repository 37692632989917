/*
路由器对象模块
 */
import Vue from 'vue'
import VueRouter from 'vue-router'



const index = () => import('../pages/index/index.vue')

import Login from '../pages/user/Login.vue'
import Login2 from '../pages/user/Login2.vue'
import Register from '../pages/user/Register.vue'
import activity from '../pages/menu/activity/index.vue'
import activityInfo from '../pages/menu/activity/activityInfo.vue'
import editActity from '../pages/menu/activity/edit.vue'
import myActivity from '../pages/menu/activity/myActivity.vue'

import order from '../pages/menu/order/index.vue'
import pay from '../pages/menu/order/pay.vue'


import Home from '../pages/home/index.vue'
import iSeen from '../pages/home/iSeen/index.vue'
import seenMe from '../pages/home/seenMe/index.vue'
import collectionMe from '../pages/home/collectionMe/index.vue'
import myCollection from '../pages/home/myCollection/index.vue'
import userPoints from '../pages/home/userPoints/index.vue' 
import vip from '../pages/home/vip/index.vue' 
import matchUp from '../pages/home/matchUp/index.vue' 
import matchDown from '../pages/home/matchDown/index.vue'  
import promotionUp from '../pages/home/promotionUp/index.vue'   
import EditInfo from '../pages/user/editPage/EditInfo.vue'
import EditBasic from '../pages/user/editPage/EditBasic.vue'
import EditPhoto from '@/pages/user/editPage/EditPhoto.vue'
import EditIntroduce from '../pages/user/editPage/EditIntroduce.vue'
import EditRequire from '../pages/user/editPage/EditRequire.vue'
import search from '../pages/index/search.vue'
import InfoOne from '../pages/user/infoPage/InfoOne.vue'
import UserInfoPage from '@/pages/user/authPage/UserInfoPage.vue'
import authImgPage from '@/pages/user/authPage/authImg.vue'
import UserAuthIndexPage from '@/pages/user/authPage/Index.vue'
//import BusinessCard from '../pages/home/businessCard/BusinessCard.vue'

// 声明使用插件
Vue.use(VueRouter)

export default new VueRouter({
	mode: 'history', // 添加这一行，将路由模式改为history
	// 所有路由
	routes: [{
		path: '/index',
		component: index, // 返回路由组件的函数, 只有执行此函数才会加载路由组件, 这个函数在请求对应的路由路径时才会执行
		meta: {
			showFooter: true,
			title: '红线知音'
		}, 
	},
	{
		path: '/',
		redirect: '/index',
		meta: {
			showFooter: true,
			title: '红线知音'
		}
	},
	{
		path: '/search',
		component:search,
		meta: {
			showFooter: true,
			title: '红线知音'
		}
	},
	{
		path: '/home',
		component: Home,
		title: '红线知音'
	},
	{
		path: '/menu/order',
		component: order,
		title: '订单列表'
	},
	{
		path: '/menu/order/pay',
		component: pay,
		title: '支付订单'
	},
	{
		path: '/menu/activity',
		component: activity,
		title: '活动列表'
	},
	{
		path: '/menu/activity/edit',
		component: editActity,
		title: '红线知音'
	}, 
	{
		path: '/menu/activity/activityInfo',
		component: activityInfo,
		title: '红线知音'
	}, 
	{
		path: '/menu/activity/myActivity',
		component: myActivity,
		title: '红线知音'
	},
	{
		path: '/home/iSeen',
		component: iSeen,
		title: '红线知音'
	},
	{
		path: '/home/seenMe',
		component: seenMe,
		title: '红线知音'
	},
	{
		path: '/home/myCollection',
		component: myCollection,
		title: '红线知音'
	},
	{
		path: '/home/collectionMe',
		component: collectionMe,
		title: '红线知音'
	},
	{
		path: '/home/userPoints',
		component: userPoints,
		title: '红线知音'
	}, 
	{
		path: '/home/vip',
		component: vip,
		title: '红线知音'
	}, 
	{
		path: '/home/matchUp',
		component: matchUp,
		title: '红线知音'
	}, 
	{
		path: '/home/matchDown',
		component: matchDown,
		title: '红线知音'
	}, 
	{
		path: '/home/promotionUp',
		component: promotionUp,
		title: '红线知音'
	}, 
	
	{
		path: '/login',
		component: Login,
		meta: {
			showFooter: false,
			title: '红线知音'
		}
	},
	{
		path: '/login2',
		component: Login2,
		meta: {
			showFooter: false,
			title: '红线知音'
		}
	},
	{
		path: '/register',
		component: Register,
		meta: {
			showFooter: false,
			title: '红线知音'
		}
	},
	{
		path: '/user/editBasic',
		component: EditBasic,
		meta: {
			showFooter: false,
			title: '红线知音'
		}
	},
	{
		path: '/user/editInfo',
		component: EditInfo,
		meta: {
			showFooter: false,
			title: '红线知音'
		}
	},
	{
		path: '/user/editPhoto',
		component: EditPhoto,
		meta: {
			showFooter: false,
			title: '红线知音'
		}
	},
	{
		path: '/user/editIntroduce',
		component: EditIntroduce,
		meta: {
			showFooter: false,
			title: '红线知音'
		}
	},
	{
		path: '/user/editRequire',
		component: EditRequire,
		meta: {
			showFooter: false,
			title: '红线知音'
		}
	},
	{
		path: '/user/realAuth/index',
		component: UserAuthIndexPage,
		meta: {
			showFooter: false,
			title: '红线知音用户信息认证'
		}
	},
	{
		path: '/user/realAuth/userInfo',
		component: UserInfoPage,
		meta: {
			showFooter: false,
			title: '红线知音用户信息认证'
		}
	},
	{
		path: '/user/auth/authImg',
		component: authImgPage,
		meta: {
			showFooter: false,
			title: '红线知音用户信息认证'
		}
	},
	{
		path: '/user/infoPage/InfoOne',
		component: InfoOne,
		meta: {
			showFooter: false,
			title: '红线知音'
		}
	},
	
	
	
	

	


		// {
		//   path: '/login',
		//   component: Login,
		//   children: [
		//      {
		//        path: '/login/Login',
		//        component: BusinessCard
		//      },
		//     {
		//       path: '/home/',
		//       component: index
		//     }
		//   ]
		// },
	]
})