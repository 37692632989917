<!-- 活动编辑页面 -->
<template>
    <div class="editActivity">
        <van-form @submit="onSubmit">
            <van-field v-model="username" name="活动标题" label="活动标题" placeholder="活动标题"
                :rules="[{ required: true, message: '请填写活动标题' }]" />
            <van-field v-model="username" name="活动开始报名时间" label="活动开始报名时间" placeholder="活动报名时间"
                :rules="[{ required: true, message: '请填写活动开始报名时间' }]" />
            <van-field v-model="username" name="活动结束报名时间" label="活动结束报名时间" placeholder="活动结束报名时间"
                :rules="[{ required: true, message: '请填写活动结束报名时间' }]" />
            <van-field v-model="username" name="活动开场开始时间" label="活动开场时间" placeholder="活动开场时间"
                :rules="[{ required: true, message: '请填写活动开场时间' }]" />
            <van-field v-model="username" name="限制人数" label="限制人数" placeholder="限制人数" type="digit"
                :rules="[{ required: true, message: '请填写限制人数' }]" />
            <van-field v-model="username" name="报名费用" label="报名费用" placeholder="报名费用"
                :rules="[{ required: true, message: '请填写报名费用' }]" />
            <van-field v-model="username" name="报名保证金" label="报名保证金" placeholder="报名保证金"
                :rules="[{ required: true, message: '请填写报名保证金费用' }]" />
            <van-field readonly clickable name="picker" :value="activityType" label="活动类型" placeholder="活动类型"
                @click="showActivityTypePicker = true" />
            <van-popup v-model="showActivityTypePicker" position="bottom">
                <van-picker show-toolbar :columns="activityTypeColumns" @confirm="activityTypeOnConfirm"
                    @cancel="showActivityTypePicker = false" />
            </van-popup>

            <van-field readonly clickable name="picker" :value="activityPermission" label="报名活动权限" placeholder="报名活动权限"
                @click="showActivityPermissionPicker = true" />
            <van-popup v-model="showActivityPermissionPicker" position="bottom">
                <van-picker show-toolbar :columns="activityPermissionColumns" @confirm="activityPermissionOnConfirm"
                    @cancel="showActivityPermissionPicker = false" />
            </van-popup>

            <van-field name="uploader" label="活动宣传图片上传">
                <template #input>
                    <van-uploader v-model="uploader" />
                </template>
            </van-field>

            <van-field v-model="message" rows="1" autosize label="活动介绍" type="textarea" placeholder="请输入活动介绍" />

            <div style="margin: 16px">
                <van-button round block type="info" native-type="submit">提交</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from 'vue';
import { Form } from 'vant';
import { Field } from 'vant';
import { Dialog } from 'vant';

// 全局注册
Vue.use(Dialog);
Vue.use(Form);
Vue.use(Field);
export default {
    //name填入本组件名字
    name: 'editActivity',
    // components: {《组件名称》},
    // import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return {

            activityType: '',
            activityTypeColumns: ['线上', '线下', '公会', '商业公司', '个人'],
            showActivityTypePicker: false,

            activityPermission: '',
            activityPermissionColumns: ['线上', '线下', '公会', '商业公司', '个人'],
            showActivityPermissionPicker: false,

            uploader: [],

        }
   },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        activityTypeOnConfirm(value) {
            this.activityType = value;
            this.showActivityTypePicker = false;
        },

        onSubmit(values) {
            console.log('submit', values);
            Dialog.alert({
                message: '此功能暂未开放给会员使用',
            }).then(() => {
                // on close
            });
        },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        Dialog.alert({
                message: '此功能暂未开放给会员使用',
            }).then(() => {
                // on close
            });
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类</style>