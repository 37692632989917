var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-background"},[_c('div',{staticClass:"page-title"},[_vm._v("开启您的幸福通道")]),_c('div',{attrs:{"id":"dl_box"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dl_wx_show),expression:"dl_wx_show"}],staticClass:"page-login-button",attrs:{"id":"dl_wx_box"}},[_c('van-button',{staticClass:"my-button",attrs:{"type":"primary","size":"large"},on:{"click":_vm.goToWeChatLogin}},[_vm._v("一键登录")])],1),_c('div',{staticClass:"page-ido-button"},[_c('van-checkbox',{attrs:{"shape":"square"},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_c('div',{staticClass:"page-privacy",on:{"click":_vm.showDialog}},[_vm._v(" 我同意《用户协议和隐私政策》 ")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dl_pwd_show),expression:"dl_pwd_show"}],staticClass:"dl_pwd_box",attrs:{"id":"dl_pwd_box","hidden":""}},[_c('van-cell-group',[_c('van-field',{attrs:{"label":"手机号","placeholder":"请输入手机号","maxlength":"11","type":"tel","required":"","rules":[
          { required: true, message: '请填写您的手机号码！' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
        ]},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}}),_c('van-field',{attrs:{"type":"password","label":"密码","placeholder":"请输入密码","required":""},model:{value:(_vm.pwd),callback:function ($$v) {_vm.pwd=$$v},expression:"pwd"}})],1),_c('van-button',{attrs:{"type":"primary","size":"large","block":""},on:{"click":_vm.goToPwdLogin}},[_vm._v("登录")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dl_sms_show),expression:"dl_sms_show"}],staticClass:"dl_sms_box",attrs:{"id":"dl_sms_box"}},[_c('van-cell-group',[_c('van-field',{attrs:{"label":"手机号","placeholder":"请输入手机号","maxlength":"11","type":"tel","required":"","rules":[
          { required: true, message: '请填写您的手机号码！' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
        ]},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}}),_c('van-field',{attrs:{"center":"","clearable":"","label":"短信验证码","placeholder":"请输入短信验证码","use-button-slot":"","required":""},model:{value:(_vm.smsCode),callback:function ($$v) {_vm.smsCode=$$v},expression:"smsCode"}},[_c('van-button',{attrs:{"slot":"button","size":"small","type":"primary"},on:{"click":_vm.sendSmsCode},slot:"button"},[_vm._v(" "+_vm._s(_vm.sendButtonText)+" ")])],1)],1),_c('van-button',{attrs:{"type":"primary","size":"large","block":""},on:{"click":_vm.goToSmsLogin}},[_vm._v("登录")])],1)]),_c('div',{staticClass:"dl_qh_box",attrs:{"id":"dl_qh_box","hidden":""}},[_c('div',{attrs:{"id":"dl_qh"}},[_vm._v(" 切换登录方式 "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.dl_pwd_show_wz),expression:"dl_pwd_show_wz"}],attrs:{"id":"dl_qh_pwd"},on:{"click":_vm.showPwdLogin}},[_c('img',{staticClass:"dl_icon",attrs:{"src":require("./img/shumashouji.png")}})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.dl_sms_show_wz),expression:"dl_sms_show_wz"}],attrs:{"id":"dl_qh_sms"},on:{"click":_vm.showSmsLogin}},[_c('img',{staticClass:"dl_icon",attrs:{"src":require("./img/outline-sms.png")}})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.dl_wx_show_wz),expression:"dl_wx_show_wz"}],attrs:{"id":"dl_qh_wx"},on:{"click":_vm.showWxLogin}},[_c('img',{staticClass:"dl_icon",attrs:{"src":require("./img/weixin.png")}})])])]),_c('van-overlay',{attrs:{"show":_vm.wait},on:{"click":function($event){_vm.wait = false}}},[_c('div',{staticClass:"wrapper",on:{"click":function($event){$event.stopPropagation();}}},[_c('van-loading',{attrs:{"size":"48px","color":"#FFF","vertical":""}},[_vm._v("登录中...")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }