//已废弃
var baseConfig = {
    
	// 图片域名
	imgDomain: 'http://',
	// H5端域名
	h5Domain: 'http://',
	// 腾讯地图key
	mpKey: 'HOCBZ-GEXKD-QJW4A-PRYEJ-4LMSJ-GEFH5',
	// api安全
	apiSecurity: false,
	// 公钥
	publicKey: ``,
	//客服地址
	webSocket: '',

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,
	//微信公众号appId 
	appId: "wx4685a6c41190453f",

	// 公司id
	companyId: 1
};

export default baseConfig;