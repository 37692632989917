<!--订单页面  -->
<template>
    <div class="">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getList">
            <div class="card">
                <van-card v-for="data in dataList" :key="data.orderId" num="1" :price="data.totalAmount" :desc="data.remark" :title="data.title" :thumb="data.img">
                    <template #tags>
                        <div>订单编号：{{ data.orderSn }}</div>
                        <div>下单时间：{{ formatTime(data.createdAt) }}</div>
                    </template>
                    <template #footer>
                         
                        <van-button v-if="data.totalAmount > 0 && data.orderStatus == 0" class="pay_button" plain type="primary" size="mini">支付</van-button>
                        <van-button v-if="data.totalAmount > 0 && data.orderStatus == 2" plain type="warning" size="mini">退款</van-button>
                    </template>
                </van-card>
            </div>
        </van-list>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { reqSelOrder } from "@/api/api";
import util from "@/common/util/js/util";
import { Card } from 'vant';

import Vue from "vue";
import { List } from "vant";
import { Button } from 'vant';

Vue.use(Button);
Vue.use(Card);
Vue.use(List);
export default {
    //name填入本组件名字
    name: "orderComponent",
    // components: {《组件名称》},
    // import引入的组件需要注入到对象中才能使用
    components: {  },
    data() {
        // 这里存放数据
        return {
            dataList: [],
            loading: false,
            finished: false,
            currentPage: 0,
            pageSize: 10,
        };
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        async getList() {
            this.currentPage++; 
            const params = {
                currentPage: this.currentPage, pageSize: this.pageSize
            }; //请求参数
            const result = await reqSelOrder(params);
            console.log("查询客户信息:", result.code);
            if (result.code === 0) {
                console.log("成功:", result.data);
                //把查到数据添加到当前页面数据对象中,转码
                if (result.data.records.length > 0) {
                    result.data.records.forEach((element) => {
                        element.key = element.orderId;
                        //处理图片地址 
                        //处理是充值vip订单还是活动订单
                        if (element.payType == 0) {
                            element.title = "充值VIP"; 
                            element.img=require("./img/vip.svg");
                        }else{
                            element.title = "活动报名"; 
                            element.img=require("./img/huodong.svg");
                        } 
                        this.dataList.push(element);
                        //总条数/页面条数 向上取整 = 当前页码则
                        console.log(Math.ceil(result.data.total / this.pageSize));
                        if (Math.ceil(result.data.total / this.pageSize) == this.currentPage) {
                            this.loading = false;
                            this.finished = true; // 没有更多数据了
                            return;
                        }
                    });
                } else {
                    this.finished = true; // 没有更多数据了
                }

                // 加载状态结束
                this.loading = false;
            }


        },
        formatTime(time) {
            return util.timeStamp(time); // 在方法中引用 util
        },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() { },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.pay_button{
    margin-right: 10px;
}

</style>