<!--  -->
<template>
  <div class="" id="home_index">
    <div class="home_top">
      <div class="home_top_head">
        <van-image class="img_head"  round :src="headerImgUrl" />
      </div>

      <div class="home_top_info">
        <div class="font_username">
          {{ this.$store.getters.getUserInfo.member_info?.nickName || "匿名" }}
        </div>
      </div>
    </div>

    <div class="home_centre_data">
      <van-grid square :column-num="4">
        <van-grid-item>
          <template #default>
            <router-link to="/home/vip">
              <div class="text_center">{{ vip }}</div>
              <div>vip等级</div>
            </router-link>
          </template>
        </van-grid-item>

        <van-grid-item>
          <template #default>
            <router-link to="/home/matchUp">
              <div class="text_center">{{ matchUp }}</div>
              <div class="min_font">线上匹配机会</div>
            </router-link>
          </template>
        </van-grid-item>

        <van-grid-item>
          <template #default>
            <router-link to="/home/matchDown">
              <div class="text_center">{{ matchDown }}</div>
              <div class="min_font">线下匹配机会</div>
            </router-link>
          </template>
        </van-grid-item>

        <van-grid-item>
          <template #default>
            <router-link to="/home/promotionUp">
              <div class="text_center">{{ promotionUp }}</div>
              <div class="min_font">线上推广机会</div>
            </router-link>
          </template>
        </van-grid-item>

        <van-grid-item>
          <template #default>
            <router-link to="/home/iseen">
              <div class="text_center">{{ iSeen }}</div>
              <div>我看过谁</div>
            </router-link>
          </template>
        </van-grid-item>

        <van-grid-item>
          <template #default>
            <router-link to="/home/seenMe">
              <van-badge class="van-badge-text" v-if="unBrowseNumber > 0" :content="unBrowseNumber">
                <div class="text_center">{{ seenMe }}</div>
              </van-badge>
              <div class="text_center" v-else>{{ seenMe }}</div>
              <div>谁看过我</div>
            </router-link>
          </template>
        </van-grid-item>

        <van-grid-item>
          <template #default>
            <router-link to="/home/myCollection">
              <div class="text_center">{{ myCollection }}</div>
              <div>我的收藏</div>
            </router-link>
          </template>
        </van-grid-item>

        <van-grid-item>
          <template #default>
            <router-link to="/home/collectionMe">
              <van-badge class="van-badge-text" v-if="unCollectNumber > 0" :content="unCollectNumber">
                <div class="text_center">{{ collectionMe }}</div>
              </van-badge>
              <div class="text_center" v-else>{{ collectionMe }}</div>
              <div>收藏我的</div>
            </router-link>
          </template>
        </van-grid-item>

        <van-grid-item>
          <template #default>
            <router-link to="/home/userPoints">
              <div class="text_center">{{ userPoints }}</div>
              <div>可用积分</div>
            </router-link>
          </template>
        </van-grid-item>
      </van-grid>
    </div>



    <div class="home_vip">
      <van-grid :column-num="4">
        <van-grid-item>
          <template #default>
            <router-link to="/menu/order">
              <div class="icon_div">
                <van-image :src="require('./img/dingdan.svg')" />
              </div>
              <div class="min_font">我的订单</div>
            </router-link>
          </template>
        </van-grid-item>

        <van-grid-item>
          <template #default>
            <router-link to="/menu/actity/edit">
              <div class="icon_div">
                <van-image :src="require('./img/huodong.svg')" />
              </div>
              <div class="min_font">发布活动</div>
            </router-link>
          </template>
        </van-grid-item>
      </van-grid>
    </div>

    <div class="home_end">
      <van-grid :column-num="4">
        <van-grid-item>
          <template #default>
            <router-link to="/user/editInfo">
              <div class="icon_div">
                <van-icon class="icon_my" name="records-o" />
              </div>
              <div class="min_font">我的资料</div>
            </router-link>
          </template>
        </van-grid-item>


        <van-grid-item>
          <template #default>
            <router-link to="/user/realAuth/index">
              <div class="icon_div">
                <van-icon class="icon_my" name="user-circle-o" />
              </div>
              <div class="min_font">信息认证</div>
            </router-link>
          </template>
        </van-grid-item>

        <van-grid-item icon="share-o" text="分享自己" />
        <van-grid-item icon="setting-o" text="会员设置" />
        <!--  <span class="shop_header_title">{{userinfo._id}}</span>
         <span class="shop_header_title">{{userinfo._nickname}}</span>
          -->
      </van-grid>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import {
  reqSelBrowseTotal,
  reqSelCollectTotal,
  reqSelCollectMy,
  reqSelUnReadInfoRedis,
} from "@/api/api";
import { mapState } from "vuex";
import { Grid, GridItem } from "vant";
import logo from "@/pages/home/img/logo.jpg";
import { Icon } from "vant";
import { Dialog } from "vant";
import { Badge } from "vant";
import { Image as VanImage } from "vant";
import { reqSelUserInfoMy } from "@/api/api";

Vue.use(VanImage);
Vue.use(Badge);
Vue.use(Icon);

Vue.use(Grid);
Vue.use(GridItem);
export default {
  //name填入本组件名字
  name: "homePage",
  // components: {《组件名称》},
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      popupShow: false,
      userPoints: 0, //用户积分
      iSeen: 0, //我看过谁
      seenMe: 0, //谁看过我
      matchUp: 0, //线上匹配机会
      matchDown: 0, //线下匹配机会
      promotionUp: 0, //线上推广机会
      vip: 0,
      myCollection: 0, //我的收藏
      collectionMe: 0, //收藏我的
      unBrowseNumber: 0, //未看浏览条数
      unCollectNumber: 0, //未看收藏条数

      homeData: [
        {
          value: "999",
          label: "可用积分",
        },
        {
          value: "999",
          label: "我看过谁",
        },
        {
          value: "999",
          label: "谁看过我",
        },
        {
          value: "999",
          label: "我的收藏",
        },
        {
          value: "999",
          label: "线上匹配机会",
        },
        {
          value: "999",
          label: "线下匹配机会",
        },
        {
          value: "999",
          label: "线上推广机会",
        },
        {
          value: "999",
          label: "vip等级",
        },
      ],
    };
  },
  // 监听属性 类似于data概念
  computed: {
    ...mapState(["userInfo", "userWeixinInfo", "token"]),
    headerImgUrl() {
      return this.$config.imgUrl + this.$store.getters.getUserInfo.member_img?.header || logo;
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async getUserPurse() {
      const result = await reqSelCollectMy();
      if (result.code === 0 && result.data) {
        //将数据赋值给当前页面数据对象
        console.log("成功:", result.data);
        this.userPoints = result.data.userPoints;
        this.matchUp = result.data.matchUp;
        this.matchDown = result.data.matchDown;
        this.promotionUp = result.data.promotionUp;
        this.vip = result.data.vip;
        // 将user保存到vuex的state,先通过actios.js的recordMemberPurse方法保存到state中
        this.$store.dispatch("recordMemberPurse", result.data);

        this.getUserCollectionTotal();
        this.getUnReadInfoByRedis();
      }
    },
    async getUserBrowseLOGTotal() {
      const result = await reqSelBrowseTotal();
      if (result.code === 0) {
        console.log("成功:", result.data);
        //将数据赋值给当前页面数据对象
        this.iSeen = result.iSeen;
        this.seenMe = result.seenMe;
      }
    },

    async getUserCollectionTotal() {
      const result = await reqSelCollectTotal();
      if (result.code === 0) {
        console.log("成功:", result.data);
        //将数据赋值给当前页面数据对象
        this.myCollection = result.myCollection;
        this.collectionMe = result.collectionMe;
      }
    },
    async getUnReadInfoByRedis() {
      const result = await reqSelUnReadInfoRedis({});
      console.log("保存客户信息:", result.code);
      if (result.code === 0) {
        console.log("成功:", result.data);
        //将数据赋值给当前页面数据对象
        this.unBrowseNumber = result.unBrowseNumber;
        console.log("未看浏览条数:", this.unBrowseNumber);
        this.unCollectNumber = result.unCollectNumber;
        console.log("未看收藏条数:", this.unCollectNumber);
      }
    },

    showDialog() {
      Dialog.alert({
        message: "您未登录，请先登录！",
      }).then(() => {
        // on confirm
        this.$store.dispatch("recordUserInfo", null);
        this.$router.push("/login");
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log("this.$store.state.token: " + this.$store.state.token);
    if (!this.$store.state.token) {
      this.showDialog();
      return;
    }
    this.getUserPurse();
    this.getUserBrowseLOGTotal();
    const result = reqSelUserInfoMy();
    console.log("查询客户信息:", result);
    console.log("查询客户信息:", result.code);
    if(result.code===0){ 
      console.log("成功:", result);
        //保存到全局对象
        this.$store.dispatch("recordUser", result.data);
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.home_top_head {
  text-align: center;
}

.img_head {
  width: 100px;
  /* 设置图片宽度 */
  height: 100px;
  /* 设置图片高度 */
  border-radius: 50%;
  /* 将图片转换为圆形 */
  object-fit: cover;
  /* 确保图片内容填充整个容器，超出的部分会被剪裁 */
}

.font_username {
  font-weight: 700;
  font-size: 1.2em;
  text-align: center;
}

.font_data {
  font-size: 0.5em;
}

.font_data_txt {
  font-size: 0.3em;
}

.min_font {
  font-size: 0.8em;
}

.icon_div {
  text-align: center;
  padding: 5px;
}

.icon_my {
  //水平居中
  text-align: center;
  font-size: 2em;
}

.text_center {
  text-align: center;
}

.van-badge-text {
  padding: 5px;
  padding-left: 30%;
}
</style>