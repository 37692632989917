<!-- 编辑用户信息 -->
<template>
  <div class="page_backgroup">
    <div class="m_title">填写基本信息</div>
    <van-form ref="formRef" submit="saveMemberAllInfo">
      <van-field
        v-model="file_number"
        name="fileNumber"
        label="档案编号"
        placeholder="请填写红娘回复的档案编号若无请留空"
      />

      <van-field
        readonly
        clickable
        name="sex"
        label="性别"
        :disabled="sex_is_disabled"
        placeholder="点击选择性别"
        :value="this.sexMap[this.sex]"
        @click="sexShow = true"
      />

      <van-popup v-model="sexShow" position="bottom" name="sexShow">
        <van-picker
          title="性别"
          show-toolbar
          :columns="this.mapTextValues(this.sexMap)"
          :value="this.sexMap[this.sex]"
          @cancel="sexShow = false"
          @confirm="handleConfirm('sex', $event)"
        />
      </van-popup>

      <van-field
        v-model="nick_name"
        type="text"
        name="nickName"
        label="昵称"
        placeholder="昵称"
        :rules="[{ required: true, message: '请填写昵称' }]"
      />

      <van-field
        v-model="real_name"
        type="text"
        name="realName"
        :disabled="realName_is_disabled"
        label="真实姓名"
        placeholder="真实姓名不会公开展示"
        :rules="[{ required: true, message: '请填写真实姓名' }]"
      />

     

     

      

      <van-field
        v-model="birth"
        readonly
        clickable
        label="生日"
        name="birth"
        placeholder="点击选择生日"
        :value="birth"
        @click="birthShow = true"
      />

      <van-popup v-model="birthShow" show="flase" position="bottom">
        <van-datetime-picker
          type="date"
          title="选择年月日"
          :value="new Date(1994, 2, 3)"
          :min-date="new Date(1960, 0, 1)"
          :max-date="new Date(2006, 0, 1)"
          @cancel="birthShow = false"
          @confirm="handleConfirm('birth', $event)"
        />
      </van-popup>
      <van-field
        v-model="height"
        type="text"
        name="height"
        label="身高"
        placeholder="身高厘米cm"
        :rules="[{ required: true, message: '请填写身高厘米cm' }]"
      />

      <van-field
        v-model="weight"
        type="text"
        name="weight"
        label="体重"
        placeholder="体重千克kg"
        :rules="[{ required: true, message: '请填写体重千克kg' }]"
      />

      <van-field
        readonly
        clickable
        name="education"
        label="学历"
        placeholder="学历"
        :value="this.educationMap[this.education]"
        @click="educationShow = true"
      />
      <van-popup v-model="educationShow" position="bottom" name="educationShow">
        <van-picker
          title="学历"
          show-toolbar
          default-index="3"
          :columns="this.mapTextValues(this.educationMap)"
          :value="this.educationMap[this.education]"
          @cancel="educationShow = false"
          @confirm="handleConfirm('education', $event)"
        />
      </van-popup>

      <van-field
        v-model="company_name"
        type="text"
        name="company_name"
        label="公司名称"
        placeholder="不会公开展示"
        :rules="[{ required: true, message: '请填写公司名称' }]"
      />

      <van-field
        readonly
        clickable
        name="workUnitType"
        label="工作单位类型"
        placeholder="点击选择工作单位类型"
        :value="this.work_unit_typeMap[this.work_unit_type]"
        @click="work_unit_typeShow = true"
      />
      <van-popup v-model="work_unit_typeShow" position="bottom">
        <van-picker
          title="工作单位类型"
          show-toolbar
          default-index="2"
          :columns="this.mapTextValues(this.work_unit_typeMap)"
          :value="this.work_unit_typeMap[this.work_unit_type]"
          @cancel="work_unit_typeShow = false"
          @confirm="handleConfirm('work_unit_type', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="job"
        label="职业标签"
        placeholder="职业标签"
        :value="this.jobMap[this.job]"
        @click="jobShow = true"
      />
      <van-popup v-model="jobShow" position="bottom" name="jobShow">
        <van-picker
          title="职业标签"
          show-toolbar
          default-index="1"
          :columns="this.mapTextValues(this.jobMap)"
          :value="this.jobMap[this.job]"
          @cancel="jobShow = false"
          @confirm="handleConfirm('job', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="annualIncome"
        label="年收入万元"
        placeholder="年收入万元"
        :value="this.annual_incomeMap[this.annual_income]"
        @click="annual_incomeShow = true"
      />
      <van-popup v-model="annual_incomeShow" position="bottom">
        <van-picker
          default-index="2"
          title="年收入万元"
          show-toolbar
          :columns="this.mapTextValues(this.annual_incomeMap)"
          :value="this.annual_incomeMap[this.annual_income]"
          @cancel="annual_incomeShow = false"
          @confirm="handleConfirm('annual_income', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="carPurchase"
        label="购车情况"
        placeholder="购车情况"
        :value="this.car_purchaseMap[this.car_purchase]"
        @click="car_purchaseShow = true"
      />
      <van-popup
        v-model="car_purchaseShow"
        position="bottom"
        name="carPurchaseShow"
      >
        <van-picker
          default-index="1"
          title="购车情况"
          show-toolbar
          :columns="this.mapTextValues(this.car_purchaseMap)"
          :value="this.car_purchaseMap[this.car_purchase]"
          @cancel="car_purchaseShow = false"
          @confirm="handleConfirm('car_purchase', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="housePurchase"
        label="购房情况"
        placeholder="购房情况"
        :value="this.house_purchaseMap[this.house_purchase]"
        @click="house_purchaseShow = true"
      />
      <van-popup v-model="house_purchaseShow" position="bottom">
        <van-picker
          default-index="1"
          title="购房情况"
          show-toolbar
          :columns="this.mapTextValues(this.house_purchaseMap)"
          :value="this.house_purchaseMap[this.house_purchase]"
          @cancel="house_purchaseShow = false"
          @confirm="handleConfirm('house_purchase', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="hrAddress"
        label="户口所在地"
        placeholder="户口所在地"
        @click="hr_addressShow = true"
        :value="this.hr_address"
      />
      <van-popup v-model="hr_addressShow" position="bottom">
        <van-area
          title="户口所在地"
          :area-list="areaList"
          value="450202"
          @confirm="handleConfirm('hr_address', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="habitationAddress"
        label="居住地"
        placeholder="居住地"
        @click="habitation_addressShow = true"
        :value="this.habitation_address"
      />
      <van-popup
        v-model="habitation_addressShow"
        position="bottom"
        name="habitationAddressShow"
      >
        <van-area
          title="居住地"
          :area-list="areaList"
          value="450202"
          @confirm="handleConfirm('habitation_address', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="marriageHistory"
        label="婚史"
        placeholder="婚史"
        :value="this.marriage_historyMap[this.marriage_history]"
        @click="marriage_historyShow = true"
      />
      <van-popup v-model="marriage_historyShow" position="bottom">
        <van-picker
          default-index="0"
          title="婚史"
          show-toolbar
          :columns="this.mapTextValues(this.marriage_historyMap)"
          :value="this.marriage_historyMap[this.marriage_history]"
          @cancel="marriage_historyShow = false"
          @confirm="handleConfirm('marriage_history', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="isChildStatus"
        v-show="marriage_history != 0"
        label="是否有小孩"
        placeholder="是否有小孩"
        :value="this.is_child_statusMap[this.is_child_status]"
        @click="is_child_statusShow = true"
      />
      <van-popup v-model="is_child_statusShow" position="bottom">
        <van-picker
          default-index="0"
          title="是否有小孩"
          show-toolbar
          :columns="this.mapTextValues(this.is_child_statusMap)"
          :value="this.is_child_statusMap[this.is_child_status]"
          @cancel="is_child_statusShow = false"
          @confirm="handleConfirm('is_child_status', $event)"
        />
      </van-popup>

      <van-field
        v-model="child_year"
        type="text"
        name="childYear"
        label="小孩年纪"
        placeholder="小孩年纪"
        v-show="is_child_status != 0"
      />

      <van-field
        readonly
        clickable
        name="isParent"
        label="父母状况"
        placeholder="父母状况"
        :value="this.is_parentMap[this.is_parent]"
        @click="is_parentShow = true"
      />
      <van-popup v-model="is_parentShow" position="bottom" name="is_parentShow">
        <van-picker
          default-index="0"
          title="父母状况"
          show-toolbar
          :columns="this.mapTextValues(this.is_parentMap)"
          :value="this.is_parentMap[this.is_parent]"
          @cancel="is_parentShow = false"
          @confirm="handleConfirm('is_parent', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="brothersNumber"
        label="兄妹数量"
        placeholder="兄妹数量"
        :value="this.brothers_numberMap[this.brothers_number]"
        @click="brothers_numberShow = true"
      />
      <van-popup
        v-model="brothers_numberShow"
        position="bottom"
        name="brothersNumberShow"
      >
        <van-picker
          default-index="0"
          title="兄妹数量"
          show-toolbar
          :columns="this.mapTextValues(this.brothers_numberMap)"
          :value="this.brothers_numberMap[this.brothers_number]"
          @cancel="brothers_numberShow = false"
          @confirm="handleConfirm('brothers_number', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="livingWithParents"
        label="婚后与父母同住"
        placeholder="婚后与父母同住"
        :value="this.living_with_parentsMap[this.living_with_parents]"
        @click="living_with_parentsShow = true"
      />
      <van-popup
        v-model="living_with_parentsShow"
        position="bottom"
        name="livingWithParentsShow"
      >
        <van-picker
          default-index="2"
          title="婚后与父母同住"
          show-toolbar
          :columns="this.mapTextValues(this.living_with_parentsMap)"
          :value="this.living_with_parentsMap[this.living_with_parents]"
          @cancel="living_with_parentsShow = false"
          @confirm="handleConfirm('living_with_parents', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="isSmoker"
        label="是否吸烟"
        placeholder="是否吸烟"
        :value="this.is_smokerMap[this.is_smoker]"
        @click="is_smokerShow = true"
      />
      <van-popup v-model="is_smokerShow" position="bottom" name="isSmokerShow">
        <van-picker
          default-index="0"
          title="是否吸烟"
          show-toolbar
          :columns="this.mapTextValues(this.is_smokerMap)"
          :value="this.is_smokerMap[this.is_smoker]"
          @cancel="is_smokerShow = false"
          @confirm="handleConfirm('is_smoker', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="isAlcoholic"
        label="是否饮酒"
        placeholder="是否饮酒"
        :value="this.is_alcoholicMap[this.is_alcoholic]"
        @click="is_alcoholicShow = true"
      />
      <van-popup v-model="is_alcoholicShow" position="bottom">
        <van-picker
          default-index="0"
          title="是否饮酒"
          show-toolbar
          :columns="this.mapTextValues(this.is_alcoholicMap)"
          :value="this.is_alcoholicMap[this.is_alcoholic]"
          @cancel="is_alcoholicShow = false"
          @confirm="handleConfirm('is_alcoholic', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="marriageType"
        label="嫁娶形式"
        placeholder="嫁娶形式"
        :value="this.marriage_typeMap[this.marriage_type]"
        @click="marriage_typeShow = true"
      />
      <van-popup v-model="marriage_typeShow" position="bottom">
        <van-picker
          default-index="1"
          title="嫁娶形式"
          show-toolbar
          :columns="this.mapTextValues(this.marriage_typeMap)"
          :value="this.marriage_typeMap[this.marriage_type]"
          @cancel="marriage_typeShow = false"
          @confirm="handleConfirm('marriage_type', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="isPet"
        label="是否养宠物"
        placeholder="是否养宠物"
        :value="this.is_petMap[this.is_pet]"
        @click="is_petShow = true"
      />
      <van-popup v-model="is_petShow" position="bottom">
        <van-picker
          default-index="1"
          title="是否养宠物"
          show-toolbar
          :columns="this.mapTextValues(this.is_petMap)"
          :value="this.is_petMap[this.is_pet]"
          @cancel="is_petShow = false"
          @confirm="handleConfirm('is_pet', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="zodiacSign"
        label="星座"
        placeholder="星座"
        :value="this.zodiac_signMap[this.zodiac_sign]"
        @click="zodiac_signShow = true"
      />
      <van-popup v-model="zodiac_signShow" position="bottom">
        <van-picker
          default-index="1"
          title="星座"
          show-toolbar
          :columns="this.mapTextValues(this.zodiac_signMap)"
          :value="this.zodiac_signMap[this.zodiac_sign]"
          @cancel="zodiac_signShow = false"
          @confirm="handleConfirm('zodiac_sign', $event)"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="bloodType"
        label="血型"
        placeholder="血型"
        :value="this.blood_typeMap[this.blood_type]"
        @click="blood_typeShow = true"
      />
      <van-popup v-model="blood_typeShow" position="bottom">
        <van-picker
          default-index="1"
          title="血型"
          show-toolbar
          :columns="this.mapTextValues(this.blood_typeMap)"
          :value="this.blood_typeMap[this.blood_type]"
          @cancel="blood_typeShow = false"
          @confirm="handleConfirm('blood_type', $event)"
        />
      </van-popup>

      <div style="padding: 16px">
        <van-button
          round
          block
          type="primary"
          native-type="button"
          color="linear-gradient(to right,#ff3357 , #FFC0CB)"
          @click="saveMemberAllInfo"
          >提交进入下步</van-button
        >
      </div>
    </van-form>

    <van-overlay :show="wait" @click="wait = false">
      <div class="wrapper" @click.stop>
        <van-loading size="48px" color="#FFF" vertical>保存中...</van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
//import { mapState } from "vuex";
import { work_unit_typeMap,jobMap,educationMap,annual_incomeMap,house_purchaseMap,car_purchaseMap,marriage_historyMap,is_child_statusMap,is_parentMap,brothers_numberMap,living_with_parentsMap,is_smokerMap,is_alcoholicMap,marriage_typeMap,is_petMap,zodiac_signMap,blood_typeMap } from '@/common/constants/constants.js';
import {
  Form,
  Field,
  Picker,
  Popup,
  Toast,
  Cell,
  CellGroup,
  DatetimePicker,
  Area,
  Loading,
  Overlay,
} from "vant";
import { areaList } from "@vant/area-data";
import { reqSelUserInfoMy,reqSaveMemberInfoAll } from "@/api/api";
Vue.use(Form)
  .use(Field)
  .use(Picker)
  .use(Popup)
  .use(Toast)
  .use(Cell)
  .use(CellGroup)
  .use(DatetimePicker)
  .use(Area)
  .use(areaList)
  .use(Loading)
  .use(Overlay);
export default {
  //name填入本组件名字
  name: "EditUser",
  // components: {《组件名称》},
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      realName_is_disabled: false,
      sex_is_disabled: false,
      file_number: this.$store.state.userInfo?.member_info?.file_number,
      nick_name: this.$store.state.userInfo?.member_info?.nick_name,
      real_name: this.$store.state.userInfo?.member_info?.real_name, 
      sex: "",
      sexMap: {
        0: "女",
        1: "男",
      },
      sexShow: false, // 关闭弹出框

      birth: "",
      birthShow: false,

      weight: "",
      height: "",
      company_name: "",
      work_unit_type: "", //工作单位类型
      work_unit_typeShow: false,
      work_unit_typeMap,//工作单位类型映射表
      job: "", //职业标签
      jobShow: false,
      jobMap,//职业标签映射表
      education: "",
      educationShow: false,
      educationMap,
      annual_income: "",
      annual_incomeShow: false,
      annual_incomeMap,//年收入映射表


      house_purchase: "",
      house_purchaseShow: false,
      house_purchaseMap,//房购映射表

      car_purchase: "",
      car_purchaseShow: false,
      car_purchaseMap,//车购映射表

      marriage_history: "",
      marriage_historyShow: false,
      marriage_historyMap,//婚姻历史映射表

      is_child_status: "",
      is_child_statusShow: false,
      is_child_statusMap,//是否有小孩映射表
      child_year: "", //小孩年龄
      hr_address: "", //户籍所在地
      hr_addressShow: false,
      habitation_address: "", //居住地
      habitation_addressShow: false,
      is_parent: "",
      is_parentShow: false,
      is_parentMap,//是否有父母映射表
      brothers_number: "",
      brothers_numberShow: false,
      brothers_numberMap,//兄弟数量映射表
      living_with_parents: "",
      living_with_parentsShow: false,
      living_with_parentsMap,
      is_smoker: "",
      is_smokerShow: false,
      is_smokerMap,
      is_alcoholic: "",
      is_alcoholicShow: false,
      is_alcoholicMap,//是否吸烟、喝酒映射表
      marriage_type: "",
      marriage_typeShow: false,
      marriage_typeMap,//婚姻类型映射表
      is_pet: "",
      is_petShow: false,
      is_petMap,//宠物映射表

      zodiac_sign: "",
      zodiac_signShow: false,
      zodiac_signMap,//星座映射表
      blood_type: "",
      blood_typeShow: false,
      blood_typeMap,

      wait: false, // 保存中提示信息状态是否展示
      areaList, //省市区数据
    };
  },
  // 监听属性 类似于data概念
  computed: {
    // ...mapState(["userInfo", "userWeixinInfo", "token"]),
    // userInfoInitialized() {
    //   return this.userInfo || {};
    // },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 公共方法处理选择器选择结果
    handleConfirm(dataKey, value) {
      // dataKey: 数据模型的键名
      // value: 用户选择的值

      //普通情况设置值

      //判断value是否是数组
      if (Array.isArray(value)) {
        // 更新数据模型
        let province = value[0].name;
        let city = value[1].name;
        let district = value[2].name;
        this[dataKey] = province + "-" + city + "-" + district;
      } else {
        this[dataKey] = value;
      }

      //判断value是否是时间
      if (value instanceof Date) {
        // 更新数据模型
        this[dataKey] = this.formatDate(value);
      }

      // 获取数据字典中的对应键名
      let dataKeyMap = this[dataKey + "Map"]; // 数据字典
      if (dataKeyMap !== undefined && dataKeyMap !== null) {
        // 更新数据模型
        this[dataKey] = this.getKeyByValue(dataKeyMap, value);
      }

      // 关闭弹出框
      this[dataKey + "Show"] = false;
    },
    // 根据value获取key
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    },
    // 根据数据字典获取value
    mapTextValues(m_map) {
      return Object.values(m_map);
    },

    // 格式化日期
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    // 提交表单
    async saveMemberAllInfo() {
      this.wait = true;
      const  params ={
          fileNumber: this.file_number,
          nickName: this.nick_name,
          realName: this.real_name, 
          sex: this.sex,
          birth: this.birth,
          height: this.height,
          weight: this.weight,
          education: this.education,
          companyName: this.company_name,
          workUnitType: this.work_unit_type,
          job: this.job,
          annualIncome: this.annual_income,
          carPurchase: this.car_purchase,
          housePurchase: this.house_purchase,
          hrAddress: this.hr_address,
          habitationAddress: this.habitation_address,
          marriageHistory: this.marriage_history,
          isChildStatus: this.is_child_status,
          childYear: this.child_year,
          isParent: this.is_parent,
          brothersNumber: this.brothers_number,
          livingWithParents: this.living_with_parents,
          isSmoker: this.is_smoker,
          isAlcoholic: this.is_alcoholic,
          marriageType: this.marriage_type,
          isPet: this.is_pet,
          zodiacSign: this.zodiac_sign,
          bloodType: this.blood_type,
        };
      
      const result = await reqSaveMemberInfoAll(params);
      console.log("保存客户信息:", result);
      console.log("保存客户信息:", result.code);
      if(result.code===0){ 
          console.log("成功:", result.data);
          //通知父容器
          this.$emit("parentFunction");
      }else{
        console.log("保存失败");
      }
      this.wait = false;
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  async created() {
    //页面创建完毕查询返显客户信息
    console.log(this.$store.state.userInfo );
    console.log(this.$store.state.userInfo.member );
    console.log(this.$store.state.userInfo.member.uid );

    const result =await reqSelUserInfoMy();
    console.log("查询客户信息:", result);
    console.log("查询客户信息:", result.code);
    if(result.code===0){ 
      console.log("成功:", result);
        //保存到全局对象
        this.$store.dispatch("recordUser", result.data);
        //将数据赋值给当前页面数据对象
        this.file_number = result.data.member_info.fileNumber;
        this.nick_name = result.data.member_info.nickName;
        this.real_name = result.data.member_info.realName; 
        this.sex = result.data.member_info.sex;
        this.birth = this.formatDate(new Date(result.data.member_info.birth));
        this.weight = result.data.member_info.weight;
        this.height = result.data.member_info.height;
        this.company_name = result.data.member_info.companyName;
        this.work_unit_type = result.data.member_info.workUnitType;
        this.job = result.data.member_info.job;
        this.education = result.data.member_info.education;
        this.annual_income = result.data.member_info.annualIncome;
        this.house_purchase = result.data.member_info.housePurchase;
        this.car_purchase = result.data.member_info.carPurchase;
        this.marriage_history = result.data.member_info.marriageHistory;
        this.is_child_status = result.data.member_info.isChildStatus;
        this.child_year = result.data.member_info.childYear;
        this.hr_address = result.data.member_info.hrAddress;
        this.habitation_address = result.data.member_info.habitationAddress;
        this.is_parent = result.data.member_info.isParent;
        this.brothers_number = result.data.member_info.brothersNumber;
        this.living_with_parents = result.data.member_info.livingWithParents;
        this.is_smoker = result.data.member_info.isSmoker;
        this.is_alcoholic = result.data.member_info.isAlcoholic;
        this.marriage_type = result.data.member_info.marriageType;
        this.is_pet = result.data.member_info.isPet;
        this.zodiac_sign = result.data.member_info.zodiacSign;
        this.blood_type = result.data.member_info.bloodType;

        if (result.data.member_info && result.data.member_info?.realName?.length > 0) {
          this.realName_is_disabled = true;
        }
        // 由于sex是整数，我们不需要检查length。相反，我们可以检查它是否是一个有效的数字。
        if (typeof result.data.member_info && typeof result.data.member_info.sex === "number" && !isNaN(result.data.member_info.sex)) {
          this.sex_is_disabled = true;
        }
    }

     
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
body {
  background-color: #fff;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
.page_backgroup {
  background-color: white;
  border-radius: 15px;
}
.m_title {
  text-align: center;
  //加粗
  font-weight: bold;
  margin-top: 10px;
}
</style>