<!-- 年龄组件 -->
<template>
  <div class="picker-container">
    <van-picker
      class="my-picker"
      :columns="columns"
      @change="handleChange"
      show-toolbar
      :title="'请选择' + useComponentName + '范围'"
      visible-item-count="5"
      confirm-button-text="提交"
      @confirm="onConfirm"
      @cancel="onCancel"
    />
    <div class="picker-separator">至</div>
  </div>
</template>

<script>
import { Picker } from "vant";

export default {
  components: {
    [Picker.name]: Picker,
  },
  props: {
    useComponentName: {
      type: String,
      required: true, // 如果这个 prop 是必传的，则设置 required 为 true
    },
    ShowUnit: {
      type: String,
      default: "", // 你可以为 prop 设置默认值
    },
    minValue: {
      type: Number,
      default: 1, // 设置默认最小值
    },
    maxDefaultIndex: {
      type: Number,
      default: 1, // 设置默认最大值
    },
    maxValue: {
      type: Number,
      default: 60, // 设置默认最大值
    },
    minDefaultIndex: {
      type: Number,
      default: 60, // 设置默认最大值
    },
 
  },
  data() {
    return {
      columns: [
        {
          values: this.generateAgeValues(this.minValue, this.maxValue),
          defaultIndex: this.minDefaultIndex, // 默认选中第一个值
        },
        {
          values: this.generateAgeValues(this.minValue, this.maxValue), 
          defaultIndex: this.maxDefaultIndex, // 默认选中第一个值
        },
      ],

    };
  },
  methods: {
  
    formattedText(values) {
      if (!values) {
        return "";
      }
      const [start, end] = values;
      return `${start}至${end}`;
    },
    onCancel() {
      console.log("取消选择");
      this.$emit("parentFunction"); // 使用自定义事件来更新父组件的数据
    },
    onConfirm(value, index) {
      console.log("Current Values:", value, index);
 

      this.$emit("band-objectText", this.formattedText(value));
      this.$emit("band-object", value); // 直接发送选择的值回父组件
      this.$emit("parentFunction"); // 使用自定义事件来更新父组件的数据
    },
    generateAgeValues(start, end) {
      // 生成指定范围内的年龄值数组
      const values = [];
      for (let i = start; i <= end; i++) {
        values.push(i.toString() + this.ShowUnit);
      }
      return values;
    },
    extractNumber(value) {
      const match = value.match(/\d+(\.\d+)?/);
      return match ? parseFloat(match[0]) : null;
    },
    handleChange(picker, values) {
      console.log("Current Values:", values);

      var numValues = values.map(this.extractNumber); // 将带单位的字符串转换为数值数组

      // 判断第一个数值是否大于第二个数值，并相应地更新第二个值
      if (numValues[0] > numValues[1]) {
        const [number, unit] = values[0].split(/(\d+)/).filter(Boolean); // 假设单位和数字是连在一起的
        picker.setColumnValue(1, number + (unit || "")); // 只设置数字部分，如果需要单位则加上单位
        //这里需要重新获取values值因为上面已经更新值了
        values = picker.getValues();
        numValues = values.map(this.extractNumber); 
      } 
      if (numValues[1] < numValues[0]) {
        const [number, unit] = values[1].split(/(\d+)/).filter(Boolean); // 同上
        picker.setColumnValue(0, number + (unit || "")); // 只设置数字部分，如果需要单位则加上单位
      }
 
      this.$emit("band-objectText", this.formattedText(values));
      this.$emit("band-object", values); // 直接发送选择的值回父组件

     },
    updateDynamicStyles() {
      // 创建一个内联样式标签
      let styleTag = document.createElement("style");
      styleTag.innerHTML = `
        .van-picker-column__item {
          font-size: 25px; !important;
        }
      `;

      // 将样式标签插入到 head 中
      document.head.appendChild(styleTag);
    },
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.updateDynamicStyles();
  },
 
};
</script>

<style scoped>
.picker-container {
  position: relative; /* 确保容器是相对定位的 */
  /* 其他样式，如宽度、高度等，根据需要添加 */
}

.picker-separator {
  position: absolute; /* 绝对定位 */
  z-index: 1000; /* 确保分隔符在其他元素之上 */
  top: 56%; /* 垂直位置，根据需要调整 */
  left: calc(50% - 15px); /* 水平居中，减去分隔符宽度的一半，根据需要调整 */
  transform: translateY(-50%); /* 微调垂直位置，如果需要的话 */
  color: #000; /* 字体颜色 */
  font-size: 20px; /* 字体大小 */
  /* 其他样式，如边框、背景等，根据需要添加 */
}
/* 通过增加特异性或使用 !important 来覆盖默认样式 */
.van-picker-column__item {
  font-size: 18px; /* 调整为你想要的字体大小 */
}
::v-deep .my-picker .van-picker__confirm {
  /* 在这里添加你的自定义样式 */
  color: green; /* 示例：将文字颜色改为蓝色 */
  font-weight: 100;
}
</style>