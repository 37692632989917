<!-- 择偶要求 -->
<template>
  <div class="EditRequire">
    <div class="m_title">择偶要求</div>
    <van-cell center title="性别">
      <template #right-icon>
        <div id="sex">
          <van-radio-group v-model="sex" direction="horizontal" :disabled="sex_is_disabled">
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </div>
      </template>
    </van-cell>

    <van-cell center title="年龄范围">
      <template #right-icon>
        <div is-link @click="showPopup('year')">
          <div v-if="year?.length === 0">点击选择年龄</div>
          <div v-else>{{ yearText }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="身高范围">
      <template #right-icon>
        <div is-link @click="showPopup('height')">
          <div v-if="height?.length === 0">点击选择身高</div>
          <div v-else>{{ heightText }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="体重范围">
      <template #right-icon>
        <div is-link @click="showPopup('weight')">
          <div v-if="weight?.length === 0 || weightText?.length === 0">
            点击选择体重
          </div>
          <div v-else>{{ weightText }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="居住地">
      <template #right-icon>
        <div is-link @click="showPopup('habitation_address')">
          <div v-if="habitation_address?.length === 0">点击选择居住地</div>
          <div v-else>{{ habitation_address_text }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="家乡">
      <template #right-icon>
        <div is-link @click="showPopup('hr_address')">
          <div v-if="hr_address_text?.length === 0">点击选择家乡</div>
          <div v-else>{{ hr_address_text }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="婚姻状况">
      <template #right-icon>
        <div is-link @click="showPopup('marriage_history')">
          <div v-if="marriage_history === ''">点击选择婚状况</div>
          <div v-else>{{ marriage_history_text }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="工作单位类型">
      <template #right-icon>
        <div is-link @click="showPopup('work_unit_type')">
          <div v-if="work_unit_type === '' || work_unit_type_text == ''">
            点击选择工作单位类型
          </div>
          <div v-else>{{ work_unit_type_text }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="学历">
      <template #right-icon>
        <div is-link @click="showPopup('education')">
          <div v-if="education === '' || education_text == ''">
            点击选择学历
          </div>
          <div v-else>{{ education_text }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="职业标签">
      <template #right-icon>
        <div is-link @click="showPopup('job')">
          <div v-if="work_unit_type === '' || job_text == ''">
            点击选择职业类型
          </div>
          <div v-else>{{ job_text }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="年收入">
      <template #right-icon>
        <div is-link @click="showPopup('annual_income')">
          <div v-if="annual_income === '' || annual_income_text == ''">
            点击选择年收入范围
          </div>
          <div v-else>{{ annual_income_text }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="购房情况">
      <template #right-icon>
        <div is-link @click="showPopup('house_purchase')">
          <div v-if="house_purchase === '' || house_purchase_text == ''">
            点击选择购房情况
          </div>
          <div v-else>{{ house_purchase_text }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="购汽车情况">
      <template #right-icon>
        <div is-link @click="showPopup('car_purchase')">
          <div v-if="car_purchase === '' || car_purchase_text == ''">
            点击选择购汽车情况
          </div>
          <div v-else>{{ car_purchase_text }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="是否吸烟">
      <template #right-icon>
        <div is-link @click="showPopup('is_smoker')">
          <div v-if="is_smoker === '' || is_smoker_text == ''">
            点击选择是否吸烟情况
          </div>
          <div v-else>{{ is_smoker_text }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="是否饮酒">
      <template #right-icon>
        <div is-link @click="showPopup('is_alcoholic')">
          <div v-if="is_alcoholic === '' || is_alcoholic_text == ''">
            点击选择是否饮酒情况
          </div>
          <div v-else>{{ is_alcoholic_text }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="是否养宠物">
      <template #right-icon>
        <div is-link @click="showPopup('is_pet')">
          <div v-if="is_pet === '' || is_pet_text == ''">
            点击选择是否饮酒情况
          </div>
          <div v-else>{{ is_pet_text }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="择婚姻形式">
      <template #right-icon>
        <div is-link @click="showPopup('marriage_type')">
          <div v-if="marriage_type === '' || marriage_type_text == ''">
            点击选择婚姻形式
          </div>
          <div v-else>{{ marriage_type_text }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="婚后与父母同住">
      <template #right-icon>
        <div is-link @click="showPopup('living_with_parents')">
          <div v-if="living_with_parents === '' || living_with_parents_text == ''">
            点击选择婚后与父母同住
          </div>
          <div v-else>{{ living_with_parents_text }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="星座">
      <template #right-icon>
        <div is-link @click="showPopup('zodiac_sign')">
          <div v-if="zodiac_sign === '' || zodiac_sign_text == ''">
            点击选择星座
          </div>
          <div v-else>{{ zodiac_sign_text }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell center title="血型">
      <template #right-icon>
        <div is-link @click="showPopup('blood_type')">
          <div v-if="blood_type === '' || blood_type_text == ''">
            点击选择血型
          </div>
          <div v-else>{{ blood_type_text }}</div>
        </div>
      </template>
    </van-cell>

    <div style="padding: 5px 16px">
      <router-link active-class="" to="/home">
        <van-button type="danger" block round @click="onConfirm" color="linear-gradient(to right,#ff3357 , #FFC0CB)">
          完成
        </van-button>
      </router-link>
    </div>

    <van-popup v-model="isPopupVisible" :close-on-click-overlay="false" round style="width: 80%">
      <div id="YearPicker" v-show="yearObjectShow">
        <PickerScope @band-object="year = $event" @band-objectText="yearText = $event" @parentFunction="closePopup"
          useComponentName="年龄" ShowUnit="岁" :minValue="18" :minDefaultIndex="2" :maxValue="60" :maxDefaultIndex="15" />
      </div>

      <div id="heightPicker" v-show="heightObjectShow">
        <PickerScope @band-object="height = $event" @band-objectText="heightText = $event" @parentFunction="closePopup"
          useComponentName="身高" ShowUnit="cm" :minValue="140" :minDefaultIndex="20" :maxValue="220"
          :maxDefaultIndex="40" />
      </div>
      <div id="weightPicker" v-show="weightObjectShow">
        <PickerScope @band-object="weight = $event" @band-objectText="weightText = $event" @parentFunction="closePopup"
          useComponentName="体重" ShowUnit="kg" :minValue="30" :minDefaultIndex="10" :maxValue="300"
          :maxDefaultIndex="30" />
      </div>

      <div id="habitation_address" v-show="habitation_addressObjectShow">
        <div>
          <van-cell is-link>
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <div type="default" @click="closePopup">取消</div>
            </template>
            <template #right-icon>
              <div style="color: green" @click="habitation_addressConfirm">
                确定
              </div>
            </template>
          </van-cell>
        </div>
        <van-tree-select :items="habitation_address_items" :active-id.sync="habitation_address_activeIndex"
          :main-active-index.sync="habitation_address_sub_activeIndex" />
      </div>

      <div id="hr_address" v-show="hr_addressObjectShow">
        <van-area :area-list="areaList" value="4502" :columns-num="2" @confirm="hr_address_confirm" @cancel="closePopup"
          @change="hr_address_onAreaChange">
          <template #title>
            <div @click="hr_addressOnClick">不限</div>
          </template>
        </van-area>
      </div>

      <div id="marriage_history" v-show="marriage_historyObjectShow">
        <van-checkbox-group v-model="marriage_history" style="padding: 10px">
          <van-row type="flex" justify="space-between">
            <van-col span="6"> <span @click="closePopup">取消</span></van-col>
            <van-col span="6"> <span>婚姻情况</span></van-col>
            <van-col span="6" style="text-align: right; color: green">
              <span @click="popup_typeConfirm('marriage_history')">确定</span></van-col>
          </van-row>
          <van-cell-group>
            <van-cell v-for="(item, index) in marriage_historyMap" clickable :key="index" :title="item">
              <template #right-icon>
                <van-checkbox :name="index.toString()" shape="square" ref="marriage_history_checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>

      <div id="education" v-show="educationObjectShow">
        <van-checkbox-group v-model="education" style="padding: 10px">
          <van-row type="flex" justify="space-between">
            <van-col span="6"> <span @click="closePopup">取消</span></van-col>
            <van-col span="6"> <span>多选学历类型</span></van-col>
            <van-col span="6" style="text-align: right; color: green">
              <span @click="popup_typeConfirm('education')">确定</span></van-col>
          </van-row>
          <van-cell-group>
            <van-cell v-for="(item, index) in educationMap" clickable :key="index" :title="item">
              <template #right-icon>
                <van-checkbox :name="index.toString()" shape="square" ref="education_checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>

      <div id="work_unit_type" v-show="work_unit_typeObjectShow">
        <van-checkbox-group v-model="work_unit_type" style="padding: 10px">
          <van-row type="flex" justify="space-between">
            <van-col span="6"> <span @click="closePopup">取消</span></van-col>
            <van-col span="6"> <span>多选工作单位类型</span></van-col>
            <van-col span="6" style="text-align: right; color: green">
              <span @click="popup_typeConfirm('work_unit_type')">确定</span></van-col>
          </van-row>
          <van-cell-group>
            <van-cell v-for="(item, index) in work_unit_typeMap" clickable :key="index" :title="item">
              <template #right-icon>
                <van-checkbox :name="index.toString()" shape="square" ref="work_unit_type_checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>

      <div id="job" v-show="jobObjectShow">
        <van-checkbox-group v-model="job" style="padding: 10px">
          <van-row type="flex" justify="space-between">
            <van-col span="6"> <span @click="closePopup">取消</span></van-col>
            <van-col span="6"> <span>多选职业类型</span></van-col>
            <van-col span="6" style="text-align: right; color: green">
              <span @click="popup_typeConfirm('job')">确定</span></van-col>
          </van-row>
          <van-cell-group>
            <van-cell v-for="(item, index) in jobMap" clickable :key="index" :title="item">
              <template #right-icon>
                <van-checkbox :name="index.toString()" shape="square" ref="job_checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>

      <div id="annual_income" v-show="annual_incomeObjectShow">
        <van-checkbox-group v-model="annual_income" style="padding: 10px">
          <van-row type="flex" justify="space-between">
            <van-col span="6"> <span @click="closePopup">取消</span></van-col>
            <van-col span="6"> <span>多选年收入类型</span></van-col>
            <van-col span="6" style="text-align: right; color: green">
              <span @click="popup_typeConfirm('annual_income')">确定</span></van-col>
          </van-row>
          <van-cell-group>
            <van-cell v-for="(item, index) in annual_incomeMap" clickable :key="index" :title="item">
              <template #right-icon>
                <van-checkbox :name="index.toString()" shape="square" ref="annual_income_checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>

      <div id="house_purchase" v-show="house_purchaseObjectShow">
        <van-checkbox-group v-model="house_purchase" style="padding: 10px">
          <van-row type="flex" justify="space-between">
            <van-col span="6"> <span @click="closePopup">取消</span></van-col>
            <van-col span="6"> <span>购房情况</span></van-col>
            <van-col span="6" style="text-align: right; color: green">
              <span @click="popup_typeConfirm('house_purchase')">确定</span></van-col>
          </van-row>
          <van-cell-group>
            <van-cell v-for="(item, index) in house_purchaseMap" clickable :key="index" :title="item" icon="">
              <template #right-icon>
                <van-checkbox :name="index.toString()" shape="square" ref="house_purchase_checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>

      <div id="car_purchase" v-show="car_purchaseObjectShow">
        <van-checkbox-group v-model="car_purchase" style="padding: 10px">
          <van-row type="flex" justify="space-between">
            <van-col span="6"> <span @click="closePopup">取消</span></van-col>
            <van-col span="6"> <span>购汽车情况</span></van-col>
            <van-col span="6" style="text-align: right; color: green">
              <span @click="popup_typeConfirm('car_purchase')">确定</span></van-col>
          </van-row>
          <van-cell-group>
            <van-cell v-for="(item, index) in car_purchaseMap" clickable :key="index" :title="item">
              <template #right-icon>
                <van-checkbox :name="index.toString()" shape="square" ref="car_purchase_checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>

      <div id="is_smoker" v-show="is_smokerObjectShow">
        <van-checkbox-group v-model="is_smoker" style="padding: 10px">
          <van-row type="flex" justify="space-between">
            <van-col span="6"> <span @click="closePopup">取消</span></van-col>
            <van-col span="6"> <span>是否吸烟</span></van-col>
            <van-col span="6" style="text-align: right; color: green">
              <span @click="popup_typeConfirm('is_smoker')">确定</span></van-col>
          </van-row>
          <van-cell-group>
            <van-cell v-for="(item, index) in is_smokerMap" clickable :key="index" :title="item">
              <template #right-icon>
                <van-checkbox :name="index.toString()" shape="square" ref="is_smoker_checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>

      <div id="is_alcoholic" v-show="is_alcoholicObjectShow">
        <van-checkbox-group v-model="is_alcoholic" style="padding: 10px">
          <van-row type="flex" justify="space-between">
            <van-col span="6"> <span @click="closePopup">取消</span></van-col>
            <van-col span="6"> <span>是否饮酒</span></van-col>
            <van-col span="6" style="text-align: right; color: green">
              <span @click="popup_typeConfirm('is_alcoholic')">确定</span></van-col>
          </van-row>
          <van-cell-group>
            <van-cell v-for="(item, index) in is_alcoholicMap" clickable :key="index" :title="item">
              <template #right-icon>
                <van-checkbox :name="index.toString()" shape="square" ref="is_alcoholic_checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>

      <div id="is_pet" v-show="is_petObjectShow">
        <van-checkbox-group v-model="is_pet" style="padding: 10px">
          <van-row type="flex" justify="space-between">
            <van-col span="6"> <span @click="closePopup">取消</span></van-col>
            <van-col span="6"> <span>是否养宠物</span></van-col>
            <van-col span="6" style="text-align: right; color: green">
              <span @click="popup_typeConfirm('is_pet')">确定</span></van-col>
          </van-row>
          <van-cell-group>
            <van-cell v-for="(item, index) in is_petMap" clickable :key="index" :title="item" icon="">
              <template #right-icon>
                <van-checkbox :name="index.toString()" shape="square" ref="is_pet_checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>

      <div id="living_with_parents" v-show="living_with_parentsObjectShow">
        <van-checkbox-group v-model="living_with_parents" style="padding: 10px">
          <van-row type="flex" justify="space-between">
            <van-col span="6"> <span @click="closePopup">取消</span></van-col>
            <van-col span="6"> <span>婚后与父母同住</span></van-col>
            <van-col span="6" style="text-align: right; color: green">
              <span @click="popup_typeConfirm('living_with_parents')">确定</span></van-col>
          </van-row>
          <van-cell-group>
            <van-cell v-for="(item, index) in living_with_parentsMap" clickable :key="index" :title="item">
              <template #right-icon>
                <van-checkbox :name="index.toString()" shape="square" ref="living_with_parents_checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>

      <div id="marriage_type" v-show="marriage_typeObjectShow">
        <van-checkbox-group v-model="marriage_type" style="padding: 10px">
          <van-row type="flex" justify="space-between">
            <van-col span="6"> <span @click="closePopup">取消</span></van-col>
            <van-col span="6"> <span>婚姻形式</span></van-col>
            <van-col span="6" style="text-align: right; color: green">
              <span @click="popup_typeConfirm('marriage_type')">确定</span></van-col>
          </van-row>
          <van-cell-group>
            <van-cell v-for="(item, index) in marriage_typeMap" clickable :key="index" :title="item">
              <template #right-icon>
                <van-checkbox :name="index.toString()" shape="square" ref="marriage_type_checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>

      <div id="zodiac_sign" v-show="zodiac_signObjectShow">
        <van-checkbox-group v-model="zodiac_sign" style="padding: 10px">
          <van-row type="flex" justify="space-between">
            <van-col span="6"> <span @click="closePopup">取消</span></van-col>
            <van-col span="6"> <span>星座</span></van-col>
            <van-col span="6" style="text-align: right; color: green">
              <span @click="popup_typeConfirm('zodiac_sign')">确定</span></van-col>
          </van-row>
          <van-cell-group>
            <van-cell v-for="(item, index) in zodiac_signMap" clickable :key="index" :title="item">
              <template #right-icon>
                <van-checkbox :name="index.toString()" shape="square"
                  ref="                                                                                                                                                                         " />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>

      <div id="blood_type" v-show="blood_typeObjectShow">
        <van-checkbox-group v-model="blood_type" style="padding: 10px">
          <van-row type="flex" justify="space-between">
            <van-col span="6"> <span @click="closePopup">取消</span></van-col>
            <van-col span="6"> <span>血型</span></van-col>
            <van-col span="6" style="text-align: right; color: green">
              <span @click="popup_typeConfirm('blood_type')">确定</span></van-col>
          </van-row>
          <van-cell-group>
            <van-cell v-for="(item, index) in blood_typeMap" clickable :key="index" :title="item">
              <template #right-icon>
                <van-checkbox :name="index.toString()" shape="square" ref="blood_type_checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>
    </van-popup>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import { Icon } from "vant";
import PickerScope from "@/components/Picker/PickerScope.vue";
import { Popup } from "vant";
import { TreeSelect } from "vant";
import { Area } from "vant";
import { areaList } from "@vant/area-data";
import { Cell, CellGroup } from "vant";
import { Button } from "vant";
import { RadioGroup, Radio } from "vant";
import { Col, Row } from "vant";
import { Checkbox, CheckboxGroup } from "vant";
import { reqSelRequire, reqSaveRequire } from "@/api/api";
import { Toast } from 'vant';

Vue.use(Toast);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Col);
Vue.use(Row);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Area);
Vue.use(TreeSelect);
Vue.use(Popup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Icon);
export default {
  //name填入本组件名字
  name: "EditRequire",
  // components: {《组件名称》},
  // import引入的组件需要注入到对象中才能使用
  components: {
    PickerScope,
  },
  data() {
    // 这里存放数据
    return {
      value: 0,
      option: [
        {
          text: "默认排序",
          value: 0,
        },
        {
          text: "最新加入",
          value: 1,
        },
        {
          text: "最近登录",
          value: 2,
        },
      ],

      isPopupVisible: false, //全屏弹窗控制
      sex: "",
      sex_is_disabled: false,
      year: [],
      yearText: "",
      yearObjectShow: false,

      height: [],
      heightText: "",
      heightObjectShow: false,

      weight: [],
      weightText: "",
      weightObjectShow: false,

      habitation_address: [],
      habitation_address_text: "",
      habitation_addressObjectShow: false,
      habitation_address_activeIndex: [4502],
      habitation_address_sub_activeIndex: 0,
      habitation_address_items: [
        {
          text: "柳州",
          children: [
            {
              text: "全柳州",
              id: 4502,
            },
            {
              text: "只限市区",
              id: 450201,
            },
            {
              text: "城中区",
              id: 450202,
            },
            {
              text: "鱼峰区",
              id: 450203,
            },
            {
              text: "柳南区",
              id: 450204,
            },
            {
              text: "柳北区",
              id: 450205,
            },
            {
              text: "柳江区",
              id: 450206,
            },
            {
              text: "柳城县",
              id: 450222,
            },
            {
              text: "鹿寨县",
              id: 450223,
            },
            {
              text: "融安县",
              id: 450224,
            },
            {
              text: "融水苗族自治县",
              id: 450225,
            },
            {
              text: "三江侗族自治县",
              id: 450226,
            },
          ],
        },
        {
          text: "桂林",
          children: [
            {
              text: "全桂林",
              id: 4503,
            },
            {
              text: "只限市区",
              id: 450301,
            },
            {
              text: "秀峰区",
              id: 450302,
            },
            {
              text: "叠彩区",
              id: 450303,
            },
            {
              text: "象山区",
              id: 450304,
            },
            {
              text: "七星区",
              id: 450305,
            },
            {
              text: "雁山区",
              id: 450311,
            },
            {
              text: "临桂区",
              id: 450312,
            },
            {
              text: "阳朔县",
              id: 450321,
            },
            {
              text: "灵川县",
              id: 450323,
            },
            {
              text: "全州县",
              id: 450324,
            },
            {
              text: "兴安县",
              id: 450325,
            },
            {
              text: "永福县",
              id: 450326,
            },
            {
              text: "灌阳县",
              id: 450327,
            },
            {
              text: "龙胜各族自治县",
              id: 450328,
            },
            {
              text: "资源县",
              id: 450329,
            },
            {
              text: "平乐县",
              id: 450330,
            },
            {
              text: "恭城瑶族自治县",
              id: 450332,
            },
            {
              text: "荔浦市",
              id: 450381,
            },
          ],
        },
        {
          text: "南宁",
          children: [
            {
              text: "全南宁",
              id: 4501,
            },
            {
              text: "只限市区",
              id: 450101,
            },
            {
              text: "兴宁区",
              id: 450102,
            },
            {
              text: "青秀区",
              id: 450103,
            },
            {
              text: "江南区",
              id: 450105,
            },
            {
              text: "西乡塘区",
              id: 450107,
            },
            {
              text: "良庆区",
              id: 450108,
            },
            {
              text: "邕宁区",
              id: 450109,
            },
            {
              text: "武鸣区",
              id: 450110,
            },
            {
              text: "隆安县",
              id: 450123,
            },
            {
              text: "马山县",
              id: 450124,
            },
            {
              text: "上林县",
              id: 450125,
            },
            {
              text: "宾阳县",
              id: 450126,
            },
            {
              text: "横县",
              id: 450127,
            },
          ],
        },
        {
          text: "玉林",
          children: [
            {
              text: "全玉林",
              id: 4509,
            },
            {
              text: "只限市区",
              id: 450901,
            },
            {
              text: "玉州区",
              id: 450902,
            },
            {
              text: "福绵区",
              id: 450903,
            },
            {
              text: "容县",
              id: 450921,
            },
            {
              text: "陆川县",
              id: 450922,
            },
            {
              text: "博白县",
              id: 450923,
            },
            {
              text: "兴业县",
              id: 450924,
            },
            {
              text: "北流市",
              id: 450981,
            },
          ],
        },
      ],

      hr_address: [],
      hr_address_text: "不限",
      hr_addressObjectShow: false,
      areaList,

      marriage_history: [],
      marriage_history_text: "不限",
      marriage_historyObjectShow: false,
      marriage_historyMap: {
        0: "未婚",
        1: "离异未育",
        2: "离异已育/未婚已育",
        3: "丧偶",
        4: "不限",
      },

      work_unit_type: [],
      work_unit_type_text: "不限",
      work_unit_typeObjectShow: false,
      //用于初始化列表
      work_unit_typeMap: {
        99: "不限",
        0: "央企/国企",
        1: "事业单位",
        2: "私企",
        3: "公务员",
        4: "个体老板",
        5: "政府机关",
        6: "学校",
        7: "在校学生",
        8: "上市公司",
        9: "医院",
        10: "律师机构",
        11: "退休",
        12: "部队/武警",
        13: "待业",
        14: "其他",
      },
      education: [],
      education_text: "不限",
      educationObjectShow: false,
      educationMap: {
        0: "初中及以下",
        1: "高中/职高",
        2: "专科",
        3: "本科",
        4: "硕士",
        5: "博士",
      },

      job: [],
      job_text: "不限",
      jobArray: [],
      jobMap: {
        0: "公务员",
        1: "公司职员",
        2: "教师",
        3: "医生",
        4: "护士",
        5: "律师",
        6: "记者",
        7: "媒体人",
        8: "金融人",
        9: "自由人",
        10: "农业从业者",
        11: "企业高管",
        12: "军人",
        13: "科程师",
        15: "艺研人员",
        14: "工术类",
        16: "创业者",
        98: "其他",
        99: "不限",
      },
      jobObjectShow: false,

      annual_income: [],
      annual_income_text: "不限",
      annual_incomeObjectShow: false,
      annual_incomeMap: {
        0: "3万元",
        1: "5万元",
        2: "8万元",
        3: "10万元",
        4: "12万元",
        5: "15万元",
        6: "20万元及以上",
        99: "不限",
      },

      house_purchase: [],
      house_purchase_text: "不限",
      house_purchaseObjectShow: false,
      house_purchaseMap: {
        0: "无",
        1: "目前无,有能力购买",
        2: "有",
        3: "多套",
        99: "不限",
      },

      car_purchase: [],
      car_purchase_text: "不限",
      car_purchaseObjectShow: false,
      car_purchaseMap: {
        0: "无",
        1: "目前无,有能力购买",
        2: "有",
        3: "多辆",
        99: "不限",
      },

      is_smoker: [],
      is_smoker_text: "不限",
      is_smokerObjectShow: false,
      is_smokerMap: {
        0: "不吸",
        1: "烟民",
        2: "应酬才吸",
        3: "偶尔吸",
        99: "不限",
      },

      is_alcoholic: [],
      is_alcoholic_text: "不限",
      is_alcoholicObjectShow: false,
      is_alcoholicMap: {
        0: "滴酒不沾",
        1: "偶尔小酌",
        2: "应酬才喝",
        3: "好酒量经常喝",
      },

      is_pet: [],
      is_pet_text: "不限",
      is_petObjectShow: false,
      is_petMap: {
        0: "不喜欢养",
        1: "目前不养，可以接受",
        2: "养有宠物",
      },

      marriage_type: [],
      marriage_type_text: "不限",
      marriage_typeObjectShow: false,
      marriage_typeMap: {
        0: "嫁娶",
        1: "两顾",
        2: "上门",
        3: "都可以接受",
      },

      zodiac_sign: [],
      zodiac_sign_text: "不限",
      zodiac_signObjectShow: false,
      zodiac_signMap: {
        0: "水瓶座",
        1: "双鱼座",
        2: "白羊座",
        3: "金牛座",
        4: "双子座",
        5: "巨蟹座",
        6: "狮子座",
        7: "处女座",
        8: "天秤座",
        9: "天蝎座",
        10: "射手座",
        11: "摩羯座",
        99: "不限",
      },

      blood_type: [],
      blood_type_text: "不限",
      blood_typeObjectShow: false,
      blood_typeMap: {
        0: "不清楚",
        1: "A型血",
        2: "B型血",
        3: "O型血",
        4: "AB型血",
        5: "RH型血",
        6: "ABO型血",
        7: "其他",
        99: "不限",
      },
      living_with_parents: "",
      living_with_parents_text: "不限",
      living_with_parentsObjectShow: false,
      living_with_parentsMap: {
        0: "愿意",
        1: "不愿意",
        2: "视情况而定",
        3: "尊重伴侣意见",
      },
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    habitation_address_activeIndex(newValue) {
      // 当选中变化时，更新habitation_address和text

      //当选中id包含 4502 时，值就为全柳州
      console.log("newValue:", newValue);
      const specialMap = new Map([
        ["4502", "全柳州"],
        ["4501", "全南宁"],
        ["4503", "全桂林"],
        ["4509", "全玉林"],
      ]);
      specialMap.forEach((value, key) => {
        if (newValue.join(",").includes(key)) {
          this.habitation_address = [key];
          this.habitation_address_text = value;
          return;
        }
      });

      this.habitation_address = newValue;

      let textParts = []; // 用于存储每一级的文本
      // 遍历newValue数组中的每个对象
      for (let i = 0; i <= newValue.length - 1; i++) {
        let id = newValue[i];
        console.log("id:", id);
        for (let element of this.habitation_address_items) {
          for (let child of element.children) {
            if (child.id === id) {
              console.log("child:", child.text);
              textParts.push(child.text);
              break; // 这将退出内层循环
            }
          }
        }
      }
      console.log("textParts:", textParts);
      this.habitation_address_text = textParts.join(",");
    },
    habitation_address_sub_activeIndex(newValue) {
      // 当选中habitation_address_text变化时，更新habitation_address_text
      console.log("newValue:", newValue);
      this.habitation_address_sub_activeIndex = newValue;
    },
  },
  // 方法集合
  methods: {
    openPopup() {
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false; // 关闭弹窗
    },
    showPopup(id) {
      // 重置所有以'Show'结尾的属性为false
      for (let key in this.$data) {
        if (key.endsWith("ObjectShow")) {
          this[key] = false;
        }
      }

      // 根据传入的id设置对应的显示标志为true
      const showPropName = id + "ObjectShow";
      console.log("showPropName:", showPropName);
      if (Object.prototype.hasOwnProperty.call(this, showPropName)) {
        this[showPropName] = true;
        console.log("showPropName:", showPropName);
      }

      // 设置弹出窗口的可见性
      this.isPopupVisible = true;
    },
    habitation_addressConfirm() {
      this.closePopup();
    },
    hr_addressOnClick() {
      this.hr_address_text = "不限";
      this.hr_address = [];
      this.closePopup();
    },
    hr_address_onAreaChange(object, values) {
      console.log("hr_address_onAreaChange:", values);
      let textParts = []; // 用于存储每一级的文本
      values.forEach((item) => {
        console.log("item:", item);
        this.hr_address.push(item.code);
        textParts.push(item.name);
      });
      this.hr_address_text = textParts.join(",");
      console.log("hr_address_text:", this.hr_address_text);
    },
    hr_address_confirm(result) {
      console.log("hr_address_confirm:", result);
      let textParts = []; // 用于存储每一级的文本
      result.forEach((item) => {
        console.log("item:", item);
        this.hr_address.push(item.code);
        textParts.push(item.name);
      });
      this.hr_address_text = textParts.join(",");
      console.log("hr_address_text:", this.hr_address_text);
      this.closePopup();
    },

    popup_typeConfirm(name) {
      const typeArray = this.$data[name];
      const typeArrayMap = this.$data[name + "Map"];
      let text = "";

      if (typeArray.includes("99")) {
        text = "不限";
      } else {
        text = typeArray.map((type) => typeArrayMap[type]).join(",");
      }

      this.$data[`${name}_text`] = text;

      // 执行其他操作，如关闭弹窗
      this.closePopup();
    },

    onSearchId() {
      console.log("搜索");
    },
    async onConfirm() {
      console.log("确认");

      const params = {
        sex: this.sex,
        year: this.joinArray(this.year),
        height: this.joinArray(this.height),
        weight: this.joinArray(this.weight),
        habitationAddress: this.joinArray(this.habitation_address),
        hrAddress: this.joinArray(this.hr_address),
        marriageHistory: this.joinArray(this.marriage_history),
        workUnitType: this.joinArray(this.work_unit_type),
        job: this.joinArray(this.job),
        education: this.joinArray(this.education),
        annualIncome: this.joinArray(this.annual_income),
        housePurchase: this.joinArray(this.house_purchase),
        carPurchase: this.joinArray(this.car_purchase),
        isSmoker: this.joinArray(this.is_smoker),
        isAlcoholic: this.joinArray(this.is_alcoholic),
        isPet: this.joinArray(this.is_pet),
        marriageType: this.joinArray(this.marriage_type),
        zodiacSign: this.joinArray(this.zodiac_sign),
        bloodType: this.joinArray(this.blood_type),
        livingWithParents: this.joinArray(this.living_with_parents),
      }; //请求参数
      const result = await reqSaveRequire(params);
      console.log("保存客户信息:", result.code);
      if (result.code === 0) {
        console.log("成功:", result.data);
        Toast.success('保存成功');

      }

      this.wait = false;



    },

    //将字符串转为数组
    splitText(text) {
      return text ? text.split(",") : [];
    },
    // 将数组转为字符串
    joinArray(array) {
      // 使用 `slice()` 方法创建新数组副本
      const cleanArray = array.slice();
      return array ? cleanArray.join(",") : "";
    },
    //把文本中的,替换为 至
    replaceText(text) {
      return text ? text.replace(/,/g, "至") : "";
    },
    //把数据库选的值通过Map转换成文本
    convertToDictionaryText(dictionaryMap, object) {
      if (object == null) return "";
      let return_txt = "";
      const m_array = object.split(",");
      m_array.forEach((item) => {
        const mappedValue = dictionaryMap[Number(item.toString())]; // 从映射中获取值
        if (mappedValue !== undefined) {
          // 确保映射的值是存在的
          // 使用三元运算符判断 return_txt 是否为空，来决定是否添加逗号
          return_txt += return_txt ? "," + mappedValue : mappedValue;
        }
      });
      return return_txt;
    },
    //居住地转文本
    mapAreaListToText2(dataArray, dataText) {
      let textObject = [];
      if (dataText == null || dataText === "") return "不限";
      let datas = dataText.split(",");
      console.log("datas:", datas);
      console.log("dataArray:", dataArray);
      datas.forEach((element) => {
        // 假设 element 是完整的城市代码字符串，例如 '110100'
        let cityName = dataArray.city_list[element + "00"];
        if (cityName) {
          // 如果找到了匹配的城市名称，则添加到 textObject 中
          textObject.push(cityName);
        } else {
          // 如果没有找到匹配项查找区
          let countyName = dataArray.county_list[element + "00"];
          if (countyName) {
            textObject.push(countyName);
          }
        }
      });
      console.log("textObject:", textObject.join(","));
      return textObject.join(",");
    },

    //家乡地址代码转换文本地址
    mapAreaListToText(dataArray, dataText) {
      let textObject = [];
      if (dataText == null || dataText === "") return "不限";
      let datas = dataText.split(",");
      console.log("datas:", datas);
      console.log("dataArray:", dataArray);
      datas.forEach((element) => {
        // 假设 element 是完整的城市代码字符串，例如 '110100'
        let provinceName = dataArray.province_list[element];
        if (provinceName) {
          // 如果找到了匹配的城市名称，则添加到 textObject 中
          textObject.push(provinceName);
        } else {
          let cityName = dataArray.city_list[element];
          if (cityName) {
            // 如果找到了匹配的城市名称，则添加到 textObject 中
            textObject.push(cityName);
          } else {
            // 如果没有找到匹配项查找区
            let countyName = dataArray.county_list[element];
            if (countyName) {
              textObject.push(countyName);
            }
          }
        }
      });
      console.log("textObject:", textObject.join(","));
      return textObject.join(",");
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  async created() {
    //页面创建完毕查询返显客户信息

    //从全局配置中获取uid
    console.log("this.$store:", this.$store);
    let userUid = this.$store.getters.getUserInfo.member.uid;
    console.log("uid:", userUid);

    const params = {
      uid: userUid
    }; //请求参数
    const result = await reqSelRequire(params);
    console.log("查询客户信息:", result.code);
    if (result.code === 0) {
      console.log("成功:", result.data);
      //将数据赋值给当前页面数据对象
      if (result.data.year !== null && result.data.year !== "") { this.yearText = this.replaceText(result.data.year); }
      if (result.data.weight !== null && result.data.weight !== "") { this.weightText = this.replaceText(result.data.weight); }
      if(result.data.height !== null && result.data.height !== ""){this.heightText = this.replaceText(result.data.height);}
      this.work_unit_type_text = this.convertToDictionaryText(
        this.work_unit_typeMap,
        result.data.workUnitType
      );
      this.job_text = this.convertToDictionaryText(
        this.jobMap,
        result.data.job
      );
      this.education_text = this.convertToDictionaryText(
        this.educationMap,
        result.data.education
      );
      this.annual_income_text = this.convertToDictionaryText(
        this.annual_incomeMap,
        result.data.annualIncome
      );
      this.house_purchase_text = this.convertToDictionaryText(
        this.house_purchaseMap,
        result.data.housePurchase
      );
      this.car_purchase_text = this.convertToDictionaryText(
        this.car_purchaseMap,
        result.data.carPurchase
      );
      this.marriage_history_text = this.convertToDictionaryText(
        this.marriage_historyMap,
        result.data.marriageHistory
      );
      //地址需要特殊处理转换方法
      this.hr_address_text = this.mapAreaListToText(
        this.areaList,
        result.data.hrAddress
      );
      this.habitation_address_text = this.mapAreaListToText2(
        this.areaList,
        result.data.habitationAddress
      );
      //this.is_parent_text = this.convertToDictionaryText(this.isParentMap,result.data.isParent);
      //this.brothers_number_text = this.convertToDictionaryText(this.brothersNumberMap,result.data.brothersNumber);

      this.living_with_parents_text = this.convertToDictionaryText(
        this.living_with_parentsMap,
        result.data.livingWithParents
      );
      this.is_smoker_text = this.convertToDictionaryText(
        this.is_smokerMap,
        result.data.isSmoker
      );
      this.is_alcoholic_text = this.convertToDictionaryText(
        this.is_alcoholicMap,
        result.data.isAlcoholic
      );
      this.marriage_type_text = this.convertToDictionaryText(
        this.marriage_typeMap,
        result.data.marriageType
      );
      this.is_pet_text = this.convertToDictionaryText(
        this.is_petMap,
        result.data.isPet
      );
      this.zodiac_sign_text = this.convertToDictionaryText(
        this.zodiac_signMap,
        result.data.zodiacSign
      );
      this.blood_type_text = this.convertToDictionaryText(
        this.blood_typeMap,
        result.data.bloodType
      );
      //将数据赋值给当前页面数据对象
      this.sex = result.data.sex.toString();
      console.log("this.sex:", this.sex);
      this.year = this.splitText(result.data.year);
      this.weight = this.splitText(result.data.weight);
      this.height = this.splitText(result.data.height);
      this.work_unit_type = this.splitText(result.data.workUnitType);
      this.job = this.splitText(result.data.job);
      this.education = this.splitText(result.data.education);
      this.annual_income = this.splitText(result.data.annualIncome);
      this.house_purchase = this.splitText(result.data.housePurchase);
      this.car_purchase = this.splitText(result.data.carPurchase);
      this.marriage_history = this.splitText(result.data.marriageHistory);
      this.hr_address = this.splitText(result.data.hrAddress);
      this.habitation_address = this.splitText(result.data.habitationAddress);
      //this.is_parent =  this.splitText(result.data.isParent);
      //this.brothers_number =  this.splitText(result.data.brothersNumber);
      this.living_with_parents = this.splitText(result.data.livingWithParents);
      console.log("this.living_with_parents", result.data.livingWithParents);
      console.log("this.living_with_parents", this.living_with_parents);
      this.is_smoker = this.splitText(result.data.isSmoker);
      this.is_alcoholic = this.splitText(result.data.isAlcoholic);
      this.marriage_type = this.splitText(result.data.marriageType);
      this.is_pet = this.splitText(result.data.isPet);
      this.zodiac_sign = this.splitText(result.data.zodiacSign);
      this.blood_type = this.splitText(result.data.bloodType);

      // 由于sex是整数，我们不需要检查length。相反，我们可以检查它是否是一个有效的数字。
      if (typeof result.data.sex === "number" && !isNaN(result.data.sex)) {
        this.sex_is_disabled = true;
      }
    }


  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.EditRequire {
  background-color: white;
  border-radius: 15px;
}

.m_title {
  text-align: center;
  //加粗
  font-weight: bold;
  margin-top: 10px;
}
</style>