// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import { areaList } from "@vant/area-data";
import { reqSelRequire, reqSelUserInfo, reqSaveMatch, reqDelMatch, reqSelMatchCheck, reqSelCollectCheck, reqSaveCollect, reqDelCollect } from "@/api/api";
import { Swipe, SwipeItem } from "vant"; import { Lazyload } from "vant";
import { Col, Row } from "vant";
import { Image as VanImage } from "vant";
import { Tag } from "vant";
import { ImagePreview } from "vant";

import { Dialog } from 'vant';
import { Icon } from 'vant';
import { Toast } from 'vant';
import { ShareSheet } from 'vant';
import { weChatShare } from '@/common/util/js/wechat'



Vue.use(ShareSheet);
Vue.use(Toast);
Vue.use(Icon);
Vue.use(Icon);
Vue.use(Tag);
Vue.use(VanImage);
Vue.use(Col);
Vue.use(Row);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Dialog);
Vue.use(Lazyload);
export default {
	//name填入本组件名字
	name: "InfoOne",
	// components: {《组件名称》},
	// import引入的组件需要注入到对象中才能使用
	components: {},
	data() {
		// 这里存放数据
		return {
			images: [],
			uid: "",
			self_introduction: "",

			personality: [],
			interest: [],
			//性格类型大全
			personalityMap: {
				0: "开朗",
				1: "活泼",
				2: "热情",
				3: "外向",
				4: "善良",
				5: "乐观",
				6: "勇敢",
				7: "幽默",
				8: "聪慧",
				9: "达理",
				10: "稳重",
				11: "沉静",
				12: "谨慎",
				13: "温和",
				14: "实在",
				15: "真诚",
				16: "孤单",
				17: "冲动",
				18: "热血",
				19: "傲娇",
				20: "内向",
				21: "耿直",
				22: "机智",
				23: "务实",
			},

			interestMap: {
				0: "躺平族",
				1: "内卷王",
				2: "历史迷",
				3: "颜值控",
				4: "夜猫子",
				5: "技术宅",
				6: "学霸光环",
				7: "学渣附体",
				8: "社恐份子",
				9: "吃货达人",
				10: "佛系青年",
				11: "斜杠青年",
				12: "千年书虫",
				13: "体育达人",
				14: "手作匠人",
				15: "科技极客",
				16: "公益先锋",
				17: "时尚达人",
				18: "游戏大神",
				19: "宠物达人",
				20: "舞林高手",
				21: "购物狂魔",
				22: "拍照达人",
				23: "王者战五渣",
				24: "文艺小清新",
				25: "旅行爱好者",
				26: "音乐发烧友",
				27: "环保主义者",
				28: "养生小能手",
				29: "美食探索者",
				30: "社交牛逼症",
				31: "涂鸦艺术家",
				32: "天命打工人",
				33: "剧本杀之手",
				34: "二次元穿越者",
				35: "狼人血染之王",
				36: "脱口秀段子王",
				37: "精致的猪猪孩",
				38: "重生之三世在看剧",
			},

			file_number: "",
			nick_name: "",
			real_name: "",
			sex: "",
			sexMap: {
				2: "女",
				1: "男",
			},
			sexShow: false, // 关闭弹出框

			birth: "",
			birthShow: false,

			weight: "",
			height: "",
			company_name: "",
			work_unit_type: "", //工作单位类型
			work_unit_typeShow: false,
			work_unit_typeMap: {
				0: "央企/国企",
				1: "事业单位",
				2: "私企",
				3: "公务员",
				4: "个体老板",
				5: "政府机关",
				6: "教师",
				7: "在校学生",
				8: "上市公司",
				9: "医院",
				10: "律师机构",
				11: "退休",
				12: "部队/武警",
				13: "待业",
				14: "其他",
			},
			job: "", //职业标签
			jobShow: false,
			jobMap: {
				0: "公务员",
				1: "公司职员",
				2: "教师",
				3: "医生",
				4: "护士",
				5: "律师",
				6: "经营个体户",
				7: "媒体人",
				8: "金融人",
				9: "自由人",
				10: "农业从业者",
				11: "企业高管",
				12: "军人",
				13: "科研人员",
				14: "工程师技术人员",
				15: "艺术类",
				98: "其他",
			},
			education: "",
			educationShow: false,
			educationMap: {
				0: "初中及以下",
				1: "高中/职高",
				2: "专科",
				3: "本科",
				4: "硕士",
				5: "博士",
			},
			annual_income: "",
			annual_incomeShow: false,
			annual_incomeMap: {
				0: "3万元",
				1: "5万元",
				2: "8万元",
				3: "10万元",
				4: "12万元",
				5: "15万元",
				6: "20万元及以上",
			},

			house_purchase: "",
			house_purchaseShow: false,
			house_purchaseMap: {
				0: "无",
				1: "目前无,有能力购买",
				2: "有",
				3: "多套",
			},

			car_purchase: "",
			car_purchaseShow: false,
			car_purchaseMap: {
				0: "无",
				1: "目前无,有能力购买",
				2: "有",
				3: "多辆",
			},

			marriage_history: "",
			marriage_historyShow: false,
			marriage_historyMap: {
				0: "未婚",
				1: "离异未育",
				2: "离异已育/未婚已育",
				3: "丧偶",
			},

			is_child_status: "",
			is_child_statusShow: false,
			is_child_statusMap: {
				0: "无",
				1: "小孩为男孩跟自己",
				2: "小孩为女孩跟自己",
				3: "小孩跟对方",
				4: "其他情况",
			},
			child_year: "", //小孩年龄
			hr_address: "", //户籍所在地
			hr_addressShow: false,
			habitation_address: "", //居住地
			habitation_addressShow: false,
			is_parent: "",
			is_parentShow: false,
			is_parentMap: {
				0: "父母健在",
				1: "父母离异",
				2: "只有父亲健在",
				3: "只有母亲健在",
				4: "自己一人",
			},
			brothers_number: "",
			brothers_numberShow: false,
			brothers_numberMap: {
				0: "独生子女",
				1: "1个",
				2: "2个",
				3: "3个",
				4: "4个",
				5: "5个",
				6: "6个",
				7: "7个",
				8: "8个",
				9: "9个",
			},
			living_with_parents: "",
			living_with_parentsShow: false,
			living_with_parentsMap: {
				0: "愿意",
				1: "不愿意",
				2: "视情况而定",
				3: "尊重伴侣意见",
			},
			is_smoker: "",
			is_smokerShow: false,
			is_smokerMap: {
				0: "不吸",
				1: "烟民",
				2: "应酬才吸",
				3: "偶尔吸",
			},
			is_alcoholic: "",
			is_alcoholicShow: false,
			is_alcoholicMap: {
				0: "滴酒不沾",
				1: "偶尔小酌",
				2: "应酬才喝",
				3: "好酒量经常喝",
			},
			marriage_type: "",
			marriage_typeShow: false,
			marriage_typeMap: {
				0: "嫁娶",
				1: "两顾",
				2: "上门",
				3: "都可以接受",
			},
			is_pet: "",
			is_petShow: false,
			is_petMap: {
				0: "不喜欢养",
				1: "目前不养,可以接受",
				2: "养有宠物",
			},

			zodiac_sign: "",
			zodiac_signShow: false,
			zodiac_signMap: {
				0: "水瓶座",
				1: "双鱼座",
				2: "白羊座",
				3: "金牛座",
				4: "双子座",
				5: "巨蟹座",
				6: "狮子座",
				7: "处女座",
				8: "天秤座",
				9: "天蝎座",
				10: "射手座",
				11: "摩羯座",
			},
			blood_type: "",
			blood_typeShow: false,
			blood_typeMap: {
				0: "不清楚",
				1: "A型血",
				2: "B型血",
				3: "O型血",
				4: "AB型血",
				5: "RH型血",
				6: "ABO型血",
				7: "其他",
			},

			wait: false, // 保存中提示信息状态是否展示
			areaList, //省市区数据

			rquire_sex: "",
			rquire_sex_is_disabled: false,
			rquire_year: [],
			rquire_yearText: "",
			rquire_yearObjectShow: false,

			rquire_height: [],
			rquire_heightText: "",
			rquire_heightObjectShow: false,

			rquire_weight: [],
			rquire_weightText: "",
			rquire_weightObjectShow: false,

			rquire_habitation_address: [],
			rquire_habitation_address_text: "",
			rquire_habitation_addressObjectShow: false,
			rquire_habitation_address_activeIndex: [4502],
			rquire_habitation_address_sub_activeIndex: 0,
			rquire_habitation_address_items: [{
				text: "柳州",
				children: [{
					text: "全柳州",
					id: 4502,
				},
				{
					text: "只限市区",
					id: 450201,
				},
				{
					text: "城中区",
					id: 450202,
				},
				{
					text: "鱼峰区",
					id: 450203,
				},
				{
					text: "柳南区",
					id: 450204,
				},
				{
					text: "柳北区",
					id: 450205,
				},
				{
					text: "柳江区",
					id: 450206,
				},
				{
					text: "柳城县",
					id: 450222,
				},
				{
					text: "鹿寨县",
					id: 450223,
				},
				{
					text: "融安县",
					id: 450224,
				},
				{
					text: "融水苗族自治县",
					id: 450225,
				},
				{
					text: "三江侗族自治县",
					id: 450226,
				},
				],
			},
			{
				text: "桂林",
				children: [{
					text: "全桂林",
					id: 4503,
				},
				{
					text: "只限市区",
					id: 450301,
				},
				{
					text: "秀峰区",
					id: 450302,
				},
				{
					text: "叠彩区",
					id: 450303,
				},
				{
					text: "象山区",
					id: 450304,
				},
				{
					text: "七星区",
					id: 450305,
				},
				{
					text: "雁山区",
					id: 450311,
				},
				{
					text: "临桂区",
					id: 450312,
				},
				{
					text: "阳朔县",
					id: 450321,
				},
				{
					text: "灵川县",
					id: 450323,
				},
				{
					text: "全州县",
					id: 450324,
				},
				{
					text: "兴安县",
					id: 450325,
				},
				{
					text: "永福县",
					id: 450326,
				},
				{
					text: "灌阳县",
					id: 450327,
				},
				{
					text: "龙胜各族自治县",
					id: 450328,
				},
				{
					text: "资源县",
					id: 450329,
				},
				{
					text: "平乐县",
					id: 450330,
				},
				{
					text: "恭城瑶族自治县",
					id: 450332,
				},
				{
					text: "荔浦市",
					id: 450381,
				},
				],
			},
			{
				text: "南宁",
				children: [{
					text: "全南宁",
					id: 4501,
				},
				{
					text: "只限市区",
					id: 450101,
				},
				{
					text: "兴宁区",
					id: 450102,
				},
				{
					text: "青秀区",
					id: 450103,
				},
				{
					text: "江南区",
					id: 450105,
				},
				{
					text: "西乡塘区",
					id: 450107,
				},
				{
					text: "良庆区",
					id: 450108,
				},
				{
					text: "邕宁区",
					id: 450109,
				},
				{
					text: "武鸣区",
					id: 450110,
				},
				{
					text: "隆安县",
					id: 450123,
				},
				{
					text: "马山县",
					id: 450124,
				},
				{
					text: "上林县",
					id: 450125,
				},
				{
					text: "宾阳县",
					id: 450126,
				},
				{
					text: "横县",
					id: 450127,
				},
				],
			},
			{
				text: "玉林",
				children: [{
					text: "全玉林",
					id: 4509,
				},
				{
					text: "只限市区",
					id: 450901,
				},
				{
					text: "玉州区",
					id: 450902,
				},
				{
					text: "福绵区",
					id: 450903,
				},
				{
					text: "容县",
					id: 450921,
				},
				{
					text: "陆川县",
					id: 450922,
				},
				{
					text: "博白县",
					id: 450923,
				},
				{
					text: "兴业县",
					id: 450924,
				},
				{
					text: "北流市",
					id: 450981,
				},
				],
			},
			],

			rquire_hr_address: [],
			rquire_hr_address_text: "不限",
			rquire_hr_addressObjectShow: false,

			rquire_marriage_history: [],
			rquire_marriage_history_text: "不限",
			rquire_marriage_historyObjectShow: false,
			rquire_marriage_historyMap: {
				0: "未婚",
				1: "离异未育",
				2: "离异已育/未婚已育",
				3: "丧偶",
				4: "不限",
			},

			rquire_work_unit_type: [],
			rquire_work_unit_type_text: "不限",
			rquire_work_unit_typeObjectShow: false,
			//用于初始化列表
			rquire_work_unit_typeMap: {
				99: "不限",
				0: "央企/国企",
				1: "事业单位",
				2: "私企",
				3: "公务员",
				4: "个体老板",
				5: "政府机关",
				6: "学校",
				7: "在校学生",
				8: "上市公司",
				9: "医院",
				10: "律师机构",
				11: "退休",
				12: "部队/武警",
				13: "待业",
				14: "其他",
			},
			rquire_education: [],
			rquire_education_text: "不限",
			rquire_educationObjectShow: false,
			rquire_educationMap: {
				0: "初中及以下",
				1: "高中/职高",
				2: "专科",
				3: "本科",
				4: "硕士",
				5: "博士",
			},

			rquire_job: [],
			rquire_job_text: "不限",
			rquire_jobArray: [],
			rquire_jobMap: {
				0: "公务员",
				1: "公司职员",
				2: "教师",
				3: "医生",
				4: "护士",
				5: "律师",
				6: "记者",
				7: "媒体人",
				8: "金融人",
				9: "自由人",
				10: "农业从业者",
				11: "企业高管",
				12: "军人",
				13: "科研人员",
				14: "工程师",
				15: "艺术类",
				16: "创业者",
				98: "其他",
				99: "不限",
			},
			rquire_jobObjectShow: false,

			rquire_annual_income: [],
			rquire_annual_income_text: "不限",
			rquire_annual_incomeObjectShow: false,
			rquire_annual_incomeMap: {
				0: "3万元",
				1: "5万元",
				2: "8万元",
				3: "10万元",
				4: "12万元",
				5: "15万元",
				6: "20万元及以上",
				99: "不限",
			},

			rquire_house_purchase: [],
			rquire_house_purchase_text: "不限",
			rquire_house_purchaseObjectShow: false,
			rquire_house_purchaseMap: {
				0: "无",
				1: "目前无,有能力购买",
				2: "有",
				3: "多套",
				99: "不限",
			},

			rquire_car_purchase: [],
			rquire_car_purchase_text: "不限",
			rquire_car_purchaseObjectShow: false,
			rquire_car_purchaseMap: {
				0: "无",
				1: "目前无,有能力购买",
				2: "有",
				3: "多辆",
				99: "不限",
			},

			rquire_is_smoker: [],
			rquire_is_smoker_text: "不限",
			rquire_is_smokerObjectShow: false,
			rquire_is_smokerMap: {
				0: "不吸",
				1: "烟民",
				2: "应酬才吸",
				3: "偶尔吸",
				99: "不限",
			},

			rquire_is_alcoholic: [],
			rquire_is_alcoholic_text: "不限",
			rquire_is_alcoholicObjectShow: false,
			rquire_is_alcoholicMap: {
				0: "滴酒不沾",
				1: "偶尔小酌",
				2: "应酬才喝",
				3: "好酒量经常喝",
			},

			rquire_is_pet: [],
			rquire_is_pet_text: "不限",
			rquire_is_petObjectShow: false,
			rquire_is_petMap: {
				0: "不喜欢养",
				1: "目前不养,可以接受",
				2: "养有宠物",
			},

			rquire_marriage_type: [],
			rquire_marriage_type_text: "不限",
			rquire_marriage_typeObjectShow: false,
			rquire_marriage_typeMap: {
				0: "嫁娶",
				1: "两顾",
				2: "上门",
				3: "都可以接受",
			},

			rquire_zodiac_sign: [],
			rquire_zodiac_sign_text: "不限",
			rquire_zodiac_signObjectShow: false,
			rquire_zodiac_signMap: {
				0: "水瓶座",
				1: "双鱼座",
				2: "白羊座",
				3: "金牛座",
				4: "双子座",
				5: "巨蟹座",
				6: "狮子座",
				7: "处女座",
				8: "天秤座",
				9: "天蝎座",
				10: "射手座",
				11: "摩羯座",
				99: "不限",
			},

			rquire_blood_type: [],
			rquire_blood_type_text: "不限",
			rquire_blood_typeObjectShow: false,
			rquire_blood_typeMap: {
				0: "不清楚",
				1: "A型血",
				2: "B型血",
				3: "O型血",
				4: "AB型血",
				5: "RH型血",
				6: "ABO型血",
				7: "其他",
				99: "不限",
			},
			rquire_living_with_parents: "",
			rquire_living_with_parents_text: "不限",
			rquire_living_with_parentsObjectShow: false,
			rquire_living_with_parentsMap: {
				0: "愿意",
				1: "不愿意",
				2: "视情况而定",
				3: "尊重伴侣意见",
			},

			likeColor: "",
			isMatchCheck: true,
			collectColor: "",
			showShare: false,
			ShareOptions: [
				{ name: '微信', icon: 'wechat' },
				{ name: '分享海报', icon: 'poster' },
				{ name: '复制链接', icon: 'link' },
				{ name: '二维码', icon: 'qrcode' },
			],


		};
	},
	// 监听属性 类似于data概念
	computed: {
		interestTagStyle() {
			return this.interest.length > 3 ? {
				marginTop: "4px",
			} : {};
		},
		personalityTagStyle() {
			return this.personality.length > 3 ? {
				marginTop: "4px",
			} : {};
		},
	},
	// 监控data中的数据变化
	watch: {},
	// 方法集合
	methods: {
		photoClick(url) {
			console.log(url);
			let imgArr = [url];
			ImagePreview(imgArr);
		},

		/**
		 * 出生转年龄
		 */
		calculateAge(birthDate) {
			// 创建一个 Date 对象来表示输入的出生日期
			const birthDateObj = new Date(birthDate);

			// 获取今天的日期
			const today = new Date();

			// 获取年份、月份和日期的差异
			let age = today.getFullYear() - birthDateObj.getFullYear();
			const monthDiff = today.getMonth() - birthDateObj.getMonth();
			const dayDiff = today.getDate() - birthDateObj.getDate();

			// 如果今天的月份小于出生月份，或者月份相同但今天的日期小于出生日期，那么年龄需要减一
			if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
				age--;
			}

			return age;
		},
		/**
		 * 全称省份转简称
		 */
		getProvinceAbbreviation(fullName) {
			const provinceAbbrMap = {
				北京市: "北京",
				天津市: "天津",
				上海市: "上海",
				重庆市: "重庆",
				河北省: "河北",
				山西省: "山西",
				辽宁省: "辽宁",
				吉林省: "吉林",
				黑龙江省: "黑龙江",
				江苏省: "江苏",
				浙江省: "浙江",
				安徽省: "安徽",
				福建省: "福建",
				江西省: "江西",
				山东省: "山东",
				河南省: "河南",
				湖北省: "湖北",
				湖南省: "湖南",
				广东省: "广东",
				海南省: "海南",
				四川省: "四川",
				贵州省: "贵州",
				云南省: "云南",
				陕西省: "陕西",
				甘肃省: "甘肃",
				青海省: "青海",
				台湾省: "台湾",
				内蒙古自治区: "内蒙古",
				广西壮族自治区: "广西",
				西藏自治区: "西藏",
				宁夏回族自治区: "宁夏",
				新疆维吾尔自治区: "新疆",
				香港特别行政区: "香港",
				澳门特别行政区: "澳门",
			};

			//分割省份全称
			var shen = fullName.split("-")[0];
			//替换
			var result = fullName.replace(shen, provinceAbbrMap[shen]);
			console.log(result);
			if (result == undefined || result == null) {
				return fullName;
			}
			return result;
		},
		async getUserInfo(uid) {
			//页面创建完毕查询返显客户信息  
			const params = {
				uid: uid
			}; //请求参数
			const result = await reqSelUserInfo(params);
			if (result.code === 0) {
				//将数据赋值给当前页面数据对象
				const data = result.data;
				console.log("成功:", data);
				//赋值图片地址
				if (
					data.member_img.header &&
					data.member_img.header.length > 0
				) {
					this.images.push(this.$config.imgUrl + data.member_img.header);
					console.log(this.$config.imgUrl + data.member_img.header);
				}
				if (
					data.member_img.photo1 &&
					data.member_img.photo1.length > 0
				) {
					this.images.push(this.$config.imgUrl + data.member_img.photo1);
					console.log(this.$config.imgUrl + data.member_img.photo1);
				}
				if (
					data.member_img.photo2 &&
					data.member_img.photo2.length > 0
				) {
					this.images.push(this.$config.imgUrl + data.member_img.photo2);
				}
				if (
					data.member_img.photo3 &&
					data.member_img.photo3.length > 0
				) {
					this.images.push(this.$config.imgUrl + data.member_img.photo3);
				}
				if (
					data.member_img.photo4 &&
					data.member_img.photo4.length > 0
				) {
					this.images.push(this.$config.imgUrl + data.member_img.photo4);
				}
				if (
					data.member_img.photo5 &&
					data.member_img.photo5.length > 0
				) {
					this.images.push(this.$config.imgUrl + data.member_img.photo5);
				}

				//this.self_introduction = data.member.member_info.selfIntroduction;
				//赋值性格类型
				//按照,分割字符串插入到数组里 
				if (data.member_info.personality) {
					this.personality = data.member_info.personality.split(",");
				}

				//赋值兴趣爱好
				if (data.member_info.interest) {
					this.interest = data.member_info.interest.split(",");
				}

				//赋值基本信息
				//将数据赋值给当前页面数据对象
				this.uid = data.member_info.uid;
				this.file_number = data.member_info.fileNumber;
				this.nick_name = data.member_info.nickName;
				this.real_name = data.member_info.realName;
				this.sex = data.member_info.sex;
				this.birth = this.calculateAge(new Date(data.member_info.birth));

				this.weight = data.member_info.weight;
				this.height = data.member_info.height;
				this.company_name = data.member_info.companyName;
				this.work_unit_type = data.member_info.workUnitType;
				this.job = data.member_info.job;
				this.education = data.member_info.education;
				this.annual_income = data.member_info.annualIncome;
				this.house_purchase = data.member_info.housePurchase;
				this.car_purchase = data.member_info.carPurchase;
				this.marriage_history = data.member_info.marriageHistory;
				this.is_child_status = data.member_info.isChildStatus;
				this.child_year = data.member_info.childYear;
				this.hr_address = data.member_info.hrAddress;
				this.habitation_address = data.member_info.habitationAddress;
				this.is_parent = data.member_info.isParent;
				this.brothers_number = data.member_info.brothersNumber;
				this.living_with_parents = data.member_info.livingWithParents;
				this.is_smoker = data.member_info.isSmoker;
				this.is_alcoholic = data.member_info.isAlcoholic;
				this.marriage_type = data.member_info.marriageType;
				this.is_pet = data.member_info.isPet;
				this.zodiac_sign = data.member_info.zodiacSign;
				this.blood_type = data.member_info.bloodType;

				if (data.member_info.realName.length > 0) {
					this.realName_is_disabled = true;
				}
				// 由于sex是整数，我们不需要检查length。相反，我们可以检查它是否是一个有效的数字。
				if (
					typeof data.member_info.sex === "number" &&
					!isNaN(data.member_info.sex)
				) {
					this.sex_is_disabled = true;
				}

				//自我介绍
				this.self_introduction = data.member_info.selfIntroduction;
			}


		},
		async getUserRquire(uid) {
			//页面创建完毕查询返显客户信息 
			const params = {
				uid: uid
			}; //请求参数
			const result = await reqSelRequire(params);
			if (result.code === 0) {
				console.log(result.data);
				//将数据赋值给当前页面数据对象 
				const data = result.data;
				console.log(data.year);
				this.rquire_yearText = this.replaceText(data.year);
				console.log(this.rquire_yearText);
				this.rquire_weightText = this.replaceText(data.weight);
				this.rquire_heightText = this.replaceText(data.height);
				this.rquire_work_unit_type_text = this.convertToDictionaryText(
					this.rquire_work_unit_typeMap,
					data.workUnitType
				);
				this.rquire_job_text = this.convertToDictionaryText(
					this.rquire_jobMap,
					data.job
				);
				this.rquire_education_text = this.convertToDictionaryText(
					this.rquire_educationMap,
					data.education
				);
				this.rquire_annual_income_text = this.convertToDictionaryText(
					this.rquire_annual_incomeMap,
					data.annualIncome
				);
				this.rquire_house_purchase_text = this.convertToDictionaryText(
					this.rquire_house_purchaseMap,
					data.housePurchase
				);
				this.rquire_car_purchase_text = this.convertToDictionaryText(
					this.rquire_car_purchaseMap,
					data.carPurchase
				);
				this.rquire_marriage_history_text = this.convertToDictionaryText(
					this.rquire_marriage_historyMap,
					data.marriageHistory
				);

				//地址需要特殊处理转换方法
				this.rquire_hr_address_text = this.mapAreaListToText(
					this.areaList,
					data.hrAddress
				);
				this.rquire_habitation_address_text = this.mapAreaListToText2(
					this.areaList,
					data.habitationAddress
				);
				//this.rquire_is_parent_text = this.convertToDictionaryText(this.rquire_isParentMap,data.member.isParent);
				//this.brothers_number_text = this.convertToDictionaryText(this.rquire_brothersNumberMap,data.member.brothersNumber);

				this.rquire_living_with_parents_text = this.convertToDictionaryText(
					this.rquire_living_with_parentsMap,
					data.livingWithParents
				);
				this.rquire_is_smoker_text = this.convertToDictionaryText(
					this.rquire_is_smokerMap,
					data.isSmoker
				);
				this.rquire_is_alcoholic_text = this.convertToDictionaryText(
					this.rquire_is_alcoholicMap,
					data.isAlcoholic
				);
				this.rquire_marriage_type_text = this.convertToDictionaryText(
					this.rquire_marriage_typeMap,
					data.marriageType
				);
				this.rquire_is_pet_text = this.convertToDictionaryText(
					this.rquire_is_petMap,
					data.isPet
				);
				this.rquire_zodiac_sign_text = this.convertToDictionaryText(
					this.rquire_zodiac_signMap,
					data.zodiacSign
				);
				this.rquire_blood_type_text = this.convertToDictionaryText(
					this.rquire_blood_typeMap,
					data.bloodType
				);
				//将数据赋值给当前页面数据对象
				this.rquire_sex = data.sex.toString();
				this.rquire_year = this.splitText(data.year);
				this.rquire_weight = this.splitText(data.weight);
				this.rquire_height = this.splitText(data.height);
				this.rquire_work_unit_type = this.splitText(data.workUnitType);
				this.rquire_job = this.splitText(data.job);
				this.rquire_education = this.splitText(data.education);
				this.rquire_annual_income = this.splitText(data.annualIncome);
				this.rquire_house_purchase = this.splitText(data.housePurchase);
				this.rquire_car_purchase = this.splitText(data.carPurchase);
				this.rquire_marriage_history = this.splitText(data.marriageHistory);
				this.rquire_hr_address = this.splitText(data.hrAddress);
				this.rquire_habitation_address = this.splitText(data.habitationAddress);
				//this.rquire_is_parent =  this.splitText(data.member.isParent);
				//this.rquire_brothers_number =  this.splitText(data.member.brothersNumber);
				this.rquire_living_with_parents = this.splitText(
					data.livingWithParents
				);
				console.log("this.rquire_living_with_parents", data.livingWithParents);
				console.log("this.rquire_living_with_parents", this.rquire_living_with_parents);
				this.rquire_is_smoker = this.splitText(data.isSmoker);
				this.rquire_is_alcoholic = this.splitText(data.isAlcoholic);
				this.rquire_marriage_type = this.splitText(data.marriageType);
				this.rquire_is_pet = this.splitText(data.isPet);
				this.rquire_zodiac_sign = this.splitText(data.zodiacSign);
				this.rquire_blood_type = this.splitText(data.bloodType);

				// 由于sex是整数，我们不需要检查length。相反，我们可以检查它是否是一个有效的数字。
				if (typeof data.sex === "number" && !isNaN(data.sex)) {
					this.rquire_sex_is_disabled = true;
				}

			}


		},


		//将字符串转为数组
		splitText(text) {
			return text ? text.split(",") : [];
		},
		// 将数组转为字符串
		joinArray(array) {
			// 使用 `slice()` 方法创建新数组副本
			const cleanArray = array.slice();
			return array ? cleanArray.join(",") : "";
		},
		//把文本中的,替换为 至
		replaceText(text) {
			return text ? text.replace(/,/g, "至") : "";
		},
		//把数据库选的值通过Map转换成文本
		convertToDictionaryText(dictionaryMap, object) {
			if (object == null) return "";
			let return_txt = "";
			const m_array = object.split(",");
			m_array.forEach((item) => {
				const mappedValue = dictionaryMap[Number(item.toString())]; // 从映射中获取值
				if (mappedValue !== undefined) {
					// 确保映射的值是存在的
					// 使用三元运算符判断 return_txt 是否为空，来决定是否添加逗号
					return_txt += return_txt ? "," + mappedValue : mappedValue;
				}
			});
			return return_txt;
		},
		//居住地转文本
		mapAreaListToText2(dataArray, dataText) {
			let textObject = [];
			if (dataText == null || dataText === "") return "不限";
			let datas = dataText.split(",");
			console.log("datas:", datas);
			console.log("dataArray:", dataArray);
			datas.forEach((element) => {
				// 假设 element 是完整的城市代码字符串，例如 '110100'
				let cityName = dataArray.city_list[element + '00'];
				if (cityName) {
					// 如果找到了匹配的城市名称，则添加到 textObject 中
					textObject.push(cityName);
				} else {
					// 如果没有找到匹配项查找区
					let countyName = dataArray.county_list[element + '00'];
					if (countyName) {
						textObject.push(countyName);
					}
				}
			});
			console.log("textObject:", textObject.join(","));
			return textObject.join(",");
		},

		//家乡地址代码转换文本地址
		mapAreaListToText(dataArray, dataText) {
			let textObject = [];
			if (dataText == null || dataText === "") return "不限";
			let datas = dataText.split(",");
			console.log("datas:", datas);
			console.log("dataArray:", dataArray);
			datas.forEach((element) => {
				// 假设 element 是完整的城市代码字符串，例如 '110100'
				let provinceName = dataArray.province_list[element];
				if (provinceName) {
					// 如果找到了匹配的城市名称，则添加到 textObject 中
					textObject.push(provinceName);
				} else {
					let cityName = dataArray.city_list[element];
					if (cityName) {
						// 如果找到了匹配的城市名称，则添加到 textObject 中
						textObject.push(cityName);
					} else {
						// 如果没有找到匹配项查找区
						let countyName = dataArray.county_list[element];
						if (countyName) {
							textObject.push(countyName);
						}
					}
				}
			});
			console.log("textObject:", textObject.join(","));
			return textObject.join(",");
		},
		likeMethod() {
			// 1. 提取通用验证逻辑
			const validateUser = () => {
				if (!this.$store.state.userInfo?.member_info?.uid) {
					return Dialog.confirm({
						message: "您未登录，请先登录！"
					}).then(() => {
						this.$store.dispatch("recordUserInfo", null);
						this.$router.push("/login");
					}).catch(() => null);
				}
				return Promise.resolve(true);
			};

			// 2. 统一处理API请求
			const handleMatchRequest = async (isLikeAction) => {
				try {
					const uid = Number(this.$route.query.uid);
					if (isNaN(uid)) throw new Error('无效的用户ID');

					const apiMethod = isLikeAction ? reqSaveMatch : reqDelMatch;
					const successMsg = isLikeAction
						? '线上发送匹配请求成功，请等待对方回应'
						: '已取消匹配';

					const { code, msg } = await apiMethod({ matchUid: uid });
					if (code === 0) {
						this.likeColor = isLikeAction ? "#ff5f6d" : "";
						Toast.success(successMsg);
					} else {
						Toast.fail(msg || '操作失败');
					}
				} catch (error) {
					console.error('请求错误:', error);
					Toast.fail(error.message || '网络请求异常');
				}
			};

			// 3. 主流程
			validateUser().then(valid => {
				if (!valid) return;

				const isLike = this.likeColor === "";
				const confirmConfig = {
					title: isLike ? '发送喜欢' : '取消线上匹配',
					message: isLike
						? '您确认消耗一次线上匹配机会发送喜欢吗？'
						: '您是否确认取消线上匹配请求？已消耗匹配的次数不会返还'
				};

				Dialog.confirm(confirmConfig)
					.then(() => handleMatchRequest(isLike))
					.catch(() => null);
			});
		},
		async isMatchCheckMethod() {
			const params = {
				matchUid: this.$route.query.uid,
			}; //请求参数
			const result = await reqSelMatchCheck(params);
			if (result.code === 0) {
				const data = result.data;
				if (data?.isStatus) {
					this.likeColor = "#ff5f6d";
					console.log("已匹配");
					if (data?.isStatus === 3) {
						this.isMatchCheck = false;
					}

				} else {
					this.likeColor = "";
					console.log("未匹配");

				}
			} else {
				Toast.fail(result.msg || '网络请求异常');
			}
		},
		collectMethod() {
			// 1. 提取通用验证逻辑
			const validateUser = () => {
				if (!this.$store.state.userInfo?.member_info?.uid) {
					return Dialog.confirm({
						message: "您未登录，请先登录！"
					}).then(() => {
						this.$store.dispatch("recordUserInfo", null);
						this.$router.push("/login");
					}).catch(() => null);
				}
				return Promise.resolve(true);
			};

			// 2. 统一处理API请求
			const handleMatchRequest = async (isCollectAction) => {
				try {
					const uid = Number(this.$route.query.uid);
					if (isNaN(uid)) throw new Error('无效的用户ID');

					const apiMethod = isCollectAction ? reqSaveCollect : reqDelCollect;
					const successMsg = isCollectAction
						? '已收藏'
						: '已取消收藏';

					const { code, msg } = await apiMethod({ collectUid: uid });
					if (code === 0) {
						this.collectColor = isCollectAction ? "#ff5f6d" : "";
						Toast.success(successMsg);
					} else {
						Toast.fail(msg || '操作失败');
					}
				} catch (error) {
					console.error('请求错误:', error);
					Toast.fail(error.message || '网络请求异常');
				}
			};

			// 3. 主流程
			validateUser().then(valid => {
				if (!valid) return;
				const isCollect = this.collectColor === "";
				handleMatchRequest(isCollect);
			});
		},
		/**
		 * 异步检查是否收藏的方法
		 *
		 * @returns 无返回值
		 */
		async isCollectCheckMethod() {
			const params = {
				collectUid: this.$route.query.uid,
			}; //请求参数
			const result = await reqSelCollectCheck(params);
			if (result.code === 0) {
				const data = result.myCollection;
				if (data > 0) {
					this.collectColor = "#ff5f6d";
					console.log("已收藏");
					if (data?.isStatus === 3) {
						this.isMatchCheck = false;
					}

				} else {
					this.collectColor = "";
					console.log("未收藏");

				}
			} else {
				Toast.fail(result.msg || '网络请求异常');
			}
		},
		onclickButtonShare() {
			this.showShare = true;
		},
		onShareSelect(option) {
			Toast(option.name);
			this.showShare = false;
			console.log(option.name == "微信");
			if (option.name == "微信") {
				this.shareWeiXin();
			}
		},
		//分享到微信
		async shareWeiXin() {

			try {
				await weChatShare.initWeChatConfig();

				// 添加ready回调确保SDK就绪
				weChatShare.wx.ready(() => {
					// 设置自定义分享内容
					let m_interest = "";

					m_interest = this.sexMap[this.sex] + ","
						+ this.birth + ","
						+ this.marriage_historyMap[this.marriage_history] + ","
						+ this.height + "cm,"
						+ this.weight + "kg,"
						+ this.educationMap[this.education] + ","
						+ this.jobMap[this.job] + ","
						+ this.work_unit_typeMap[this.work_unit_type];

					console.log("m_interest", m_interest);
					console.log("this.$config.imgUrl + this.images[0]", this.$config.imgUrl + this.images[0]);
					weChatShare.setCustomShare({
						title: '《脱单邀请函》推荐嘉宾：' + m_interest,
						link: window.location.href.split('#')[0], // 关键参数
						desc: '红线知音，红线寻觅知音',
						imgUrl: this.images[0] // 使用完整路径
					});

					// 添加成功提示
					Toast('点击右上角菜单分享');
				});

			} catch (error) {
				Toast.fail('分享初始化失败');
				console.error('微信分享错误:', error);
			}

		},



	},

	// 生命周期 - 创建完成（可以访问当前this实例）
	created() {
		//判断未登录提示无权限查看照片
		//console.log("this.$store.state.userInfo", this.$store.state.userInfo.member_info.uid);
		if (this.$store.state.userInfo?.member_info?.uid == null || this.$store.state.userInfo.member_info.uid == "" || this.$store.state.userInfo.member_info.uid == undefined) {
			Dialog.alert({
				message: '您未登录,请登录后再查看照片!',
			}).then(() => {
				// on close

			});
		} else {
			this.isMatchCheckMethod();
			this.isCollectCheckMethod();
		}

		//从传入的参数中获取到用户id
		let uid = this.$route.query.uid;
		this.getUserInfo(uid);
		this.getUserRquire(uid)
	},
	// 生命周期 - 挂载完成（可以访问DOM元素）
	mounted() { },
	beforeCreate() { }, // 生命周期 - 创建之前
	beforeMount() { }, // 生命周期 - 挂载之前
	beforeUpdate() { }, // 生命周期 - 更新之前
	updated() { }, // 生命周期 - 更新之后
	beforeDestroy() { }, // 生命周期 - 销毁之前
	destroyed() { }, // 生命周期 - 销毁完成
	activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
};