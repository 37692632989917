/*
包含n个mutation的type名称常量
 */

export const RECEIVE_USER_INFO = 'receive_user_info' // 接收用户信息
export const RESET_USER_INFO = 'reset_user_info' // 重置用户信息

export const RECEIVE_WEIXIN_USER_INFO = 'receive_weixin_user_info' // 接收用户信息
export const RESET_WEIXIN_USER_INFO = 'reset_weixin_user_info' // 重置微信用户信息

export const RECEIVE_TOKEN = 'receive_token' // 接收token信息
export const RESET_TOKEN = 'reset_token' // 重置token信息

export const RECEIVE_PERMISSION = 'receive_permission' // 接收permission信息
export const RESET_PERMISSION = 'reset_permission' // 重置permission信息
 
 
export const RECEIVE_MEMBERPURSE = 'receive_memberPurse' // 接收memberPurse用户钱包信息
export const RESET_MEMBERPURSE = 'reset_memberPurse' // 重置memberPurse用户钱包信息


export const RECEIVE_ADDRESS = 'receive_address' // 接收地址
export const RECEIVE_CATEGORYS = 'receive_categorys' // 接收食品分类数组
export const RECEIVE_SHOPS = 'receive_shops' // 接收商家数组
export const RECEIVE_GOODS = 'receive_goods' // 接收商品数组
export const RECEIVE_RATINGS = 'receive_ratings' // 接收商家评价数组
export const RECEIVE_INFO = 'receive_info' // 接收商家信息

export const INCREMENT_FOOD_COUNT = 'increment_food_count' // 增加food中的count
export const DECREMENT_FOOD_COUNT = 'decrement_food_count' // 减少food中的count
export const CLEAR_CART = 'clear_cart' // 清空购物车

export const RECEIVE_SEARCH_SHOPS = 'receive_search_shops' // 接收搜索的商家数组




