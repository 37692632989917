<!-- 活动详细页面 -->
<template>
  <div class="activityInfo">
    <van-swipe :autoplay="3000">
      <van-swipe-item>
        <van-image width="100%" height="200px" src="https://img01.yzcdn.cn/vant/cat.jpeg" />
      </van-swipe-item>
    </van-swipe>
    <div class="activityInfo_title">{{ activityInfo.title }}</div>

    <div class="activityInfo_info_div">
      <div class="activityInfo_title_info">
        {{ activityInfo.activityDetails }}
      </div>
      <div class="activityInfo_info_div_info">
        报名时间：{{ formatTime(activityInfo.startTime) }} 至
        {{ formatTime(activityInfo.endTime) }}
      </div>
      <div class="activityInfo_info_div_info">
        活动时间：{{ formatTime(activityInfo.eventStartTime) }} 至
        {{ formatTime(activityInfo.eventEndTime) }}
      </div>
      <div class="activityInfo_info_div_info">
        活动地点：{{ activityInfo.activityAddress }}
      </div>
      <div class="activityInfo_info_div_info">
        活动名额:{{
          activityInfo.limit_people ? activityInfo.limit_people + "人" : "不限"
        }}，已报名{{ people }}人
      </div>
      <div class="activityInfo_info_div_info">
        主办方:{{ activityInfo.organizer }}
      </div>
      <div class="activityInfo_info_div_info">
        总费用{{ activityInfo.totalFee }}元
        <span v-if="activityInfo.deposit > 0">，定金{{ activityInfo.deposit }}元</span>
      </div>
      <div class="activityInfo_info_div_info">
        参加条件：{{ permission_purchase_txt }}
      </div>
    </div>
    <div class="activityInfo_title_info">活动详细内容</div>
    {{ activityInfo.activityDetails || "红线知音，缘遇知音" }}
    <div>
      <van-submit-bar :price=getMoney(activityInfo.totalFee) :disabled="button_disabled" :button-text="botton_txt"
        tip-icon="info-o" @submit="onSubmit">
        <template #default>
          <van-grid>
            <van-grid-item icon="home-o" to="/" text="首页" />
            <van-grid-item icon="share-o" @click="showShare = true" text="分享" />
          </van-grid>
        </template>
      </van-submit-bar>

      <van-share-sheet v-model="showShare" title="立即分享给好友" :options="shareOptions" @select="onShare" />
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import { Image as VanImage } from "vant";
import { Swipe, SwipeItem } from "vant";
import { SubmitBar } from "vant";
import { ShareSheet } from "vant";
import { Grid, GridItem } from "vant";
import {
  reqSelActivity,
  reqSelActivityEnrollment,
  reqSaveActivityEnrollment,
  reqSelActivityEnrollmentPeopleRedis,
  reqSaveOrder,
} from "@/api/api";
import util from "@/common/util/js/util";
import { permission_purchaseMap } from "@/common/constants/constants.js";
import { Toast } from "vant";
import { Dialog } from "vant";

// 全局注册
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(ShareSheet);
Vue.use(SubmitBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(VanImage);
Vue.use(Grid);
Vue.use(GridItem);
export default {
  //name填入本组件名字
  name: "activityInfoPage",
  // components: {《组件名称》},
  // import引入的组件需要
  // 注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      button_disabled: false,
      showShare: false,
      botton_txt: "报名",
      shareOptions: [
        { name: "微信", icon: "wechat" },
        { name: "分享海报", icon: "poster" },
      ],
      activityInfo: {},
      permission_purchase_txt: "无限制条件",
      isManualReview: false,
      people: 0, //报名人数
      activityId: 0,
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    onShare(option) {
      console.log(option);
      this.showShare = false;
    },
    async getData(activityId) {
      this.getDataSelActivity(activityId); //查询活动详细信息
      //登录后查询是否报名
      if (this.$store.state.token) {
        this.getDataSelActivityEnrollment(activityId); //查询自己是否报名
      }
    },
    async getDataSelActivityEnrollment(activityId) {
      //查询活动详细信息
      const params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        activityId: activityId,
      }; //请求参数
      const result = await reqSelActivityEnrollment(params);
      console.log("查询客户信息:", result.code);
      if (result.code === 0) {
        console.log("成功:", result.data);
        //把查到数据添加到当前页面数据对象中,转码
        if (result.data.records.length > 0) {
          result.data.records.forEach((element) => {
            console.log("element:", element);
            //0待审核，1审核通过，2审核拒绝
            if (element.activityEnrollmentDto.enrollmentStatus == 0) {
              this.botton_txt = "待审核";
            } else if (element.activityEnrollmentDto.enrollmentStatus == 1) {
              this.botton_txt = "审核通过";
            } else if (element.activityEnrollmentDto.enrollmentStatus == 2) {
              this.botton_txt = "未通过";
            }
            console.log("element.activityEnrollmentDto.enrollmentStatus:", element.activityEnrollmentDto.enrollmentStatus);
          });
        }
      }
    },
    async getDataSelActivity(activityId) {
      //查询活动详细信息
      const params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        activityId: activityId,
      }; //请求参数
      const result = await reqSelActivity(params);
      console.log("查询客户信息:", result.code);
      if (result.code === 0) {
        console.log("成功:", result.data);
        //把查到数据添加到当前页面数据对象中,转码
        if (result.data.records.length > 0) {
          result.data.records.forEach((element) => {
            //赋值唯一标识
            element.key = element.activityId;
            //判断是否有权限报名
            this.checkPermission(element.permission)
              ? (this.button_disabled = false)
              : (this.button_disabled = true);
            //用,分割权限字符串，转为数组
            var m_permission = element.permission.split(","); // 定义一个变量来存储分割后的权限字符串数组
            this.permission_purchase_txt = "";
            m_permission.forEach((temp) => {
              if (permission_purchaseMap[temp]) {
                this.permission_purchase_txt =
                  this.permission_purchase_txt +
                  permission_purchaseMap[temp] +
                  ",";
              }
            });
            this.permission_purchase_txt =
              this.permission_purchase_txt.substring(
                0,
                this.permission_purchase_txt.length - 1
              );
            this.isManualReview = element.manualReview !== 0; // 当值不等于0时返回true
            this.activityInfo = element;
          });
        }
      }
    },
    async saveActivityEnrollment(activityId) {
      Toast.loading({
        message: "提交报名中...",
        forbidClick: true,
      });

      //按钮失效
      this.button_disabled = false;

      //查询活动详细信息
      const params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        activityId: activityId,
        remark: "",
      }; //请求参数
      const result = await reqSaveActivityEnrollment(params);
      console.log("成功:", result.data);
      // 手动清除 Toast报名中
      if (result.code === 0) {
        var orderType = 1;
        if (this.activityInfo.deposit > 0) {
          orderType = 2;
        }
        const params_order = {
          enrollmentId: result.data,
          orderType: orderType,
          activityId: activityId,
        }; //请求参数
        const result_order = await reqSaveOrder(params_order); //保存订单信息
        Toast.clear();
        if (result_order.code !== 0) {
          Toast.fail(result_order.msg);
        } else {
          console.log("成功:", result_order.data);
          this.botton_txt = "报名成功";
          this.button_disabled = false;
          let toast_msg = "提交报名成功，请等待审核结果！";
          if (this.isManualReview) {
            toast_msg = "提交报名成功，请等待管理员审核！";
          } else {
            toast_msg = "提交报名成功，无需审核！";
          }
          if (this.activityInfo.totalFee > 0) {
            toast_msg = "将跳转到支付参加费用页面！";
          }
          Toast.success(toast_msg);
          //若是有定金，则跳转到定金支付页面
          if (this.activityInfo.depositAmount > 0 || this.activityInfo.totalFee > 0) {
            //跳转到结算金额页面
            this.$router.push({
              path: "/menu/order/pay",
              query: { orderId: result_order.data },
            });
          }
        }
      } else {
        Toast.fail(result.msg);
      }
    },
    async selActivityEnrollmentPeopleRedis(activityId) {
      //查询活动详细信息
      const params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        activityId: activityId,
      }; //请求参数
      const result = await reqSelActivityEnrollmentPeopleRedis(params);
      console.log("查询客户信息:", result.code);
      if (result.code === 0) {
        console.log("成功:", result.data);
        //把查到数据添加到当前页面数据对象中,转码
        this.people = result.data;
      }
    },

    formatTime(time) {
      return util.timeStamp(time); // 在方法中引用 util
    },
    onSubmit(content) {
      console.log("submit", content);
      Dialog.confirm({
        title: "报名活动",
        message: "您确定要报名吗？",
      })
        .then(() => {
          // on confirm
          this.saveActivityEnrollment(this.activityId);
        })
        .catch(() => {
          // on cancel
        });
      //提交报名信息
    },
    checkPermission(activity_permission) {
      let user_permission = this.$store.state.permission; // 获取用户权限
      let activity_permission_list = activity_permission.split(",");

      if (!user_permission || user_permission.length === 0) {
        console.log("用户权限未定义或为空，直接返回");
        this.botton_txt = "暂无权限";
        return;
      }
      if (activity_permission_list.length === 1 && activity_permission_list[0] === "login") {
        this.botton_txt = "报名";
        return true;
      }

      for (let i = 0; i < user_permission.length; i++) {
        console.log("用户权限", user_permission[i]);
        activity_permission_list.forEach(element => {
          if (user_permission[i] === element) {
            console.log("用户有权限报名", element);
            return true;
          }
        });

      }
      return false;
    },
    //把金额元变成分
    getMoney(money) {
      return money * 100;
    },
    showDialog() {

      Dialog.alert({
        message: "您未登录，请先登录！",
      }).then(() => {
        // on confirm
        this.$store.dispatch("recordUserInfo", null);
        this.$route.query.redirect = this.$route.fullPath;
        this.$router.push("/login");
      });
    },





  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    //从传入的参数中获取到活动id
    this.activityId = this.$route.query.activityId;
    console.log(this.activityId);
    this.getData(this.activityId);
    if (!this.$store.state.token) {
      this.showDialog();
      return;
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.activityInfo_info_div {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}

.activityInfo_info_div_info {
  margin-top: 8px;
  font-size: 18px;
}

.activityInfo_title {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 28px;
  font-weight: 600;
}

.activityInfo_title_info {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  width: auto;
}

.activityInfo_title_info::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  /* 边框高度 */
  background: linear-gradient(10deg, #f4071b 30%, #ffa082 70%);
}
</style>