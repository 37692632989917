<!-- 认证页面主页 -->
<template>
  <div class="auth_page">
    <div>
      <van-grid :column-num="4">
        <van-grid-item>
          <template #default>
            <div class="icon_div">
              <van-image width="40" height="40" :src="require('@/pages/user/authPage/img/renzheng-shenfen-ok.png')
                " />
            </div>
            <div class="m_fk_sub_text m_fk_sub">身份认证</div>
            <div class="m_fk_sub_text m_fk_sub"  >
              <router-link to="/user/realAuth/userInfo">{{sfState}}</router-link>
            </div>
             
          </template>
        </van-grid-item>

        <van-grid-item>
          <template #default>
            <div class="icon_div">
              <van-image width="40" height="40" :src="require('@/pages/user/authPage/img/renzheng-geshui-ok.png')
                " />
            </div>
            <div class="m_fk_sub_text m_fk_sub">个税认证</div>
            <div class="m_fk_sub_text m_fk_sub">
              <router-link :to="{path:'/user/auth/authImg',query:{ authType: 1 }}">{{gsState}}</router-link>
            </div>
             
          </template>
        </van-grid-item>

        <van-grid-item>
          <template #default>
            <div class="icon_div">
              <van-image width="40" height="40" :src="require('@/pages/user/authPage/img/renzheng-fangzi-ok.png')
                " />
            </div>
            <div class="m_fk_sub_text m_fk_sub">房屋认证</div>
            <div class="m_fk_sub_text m_fk_sub">
              <router-link :to="{path:'/user/auth/authImg',query:{ authType: 4 }}">{{houseState}}</router-link>
            </div>
             
          </template>
        </van-grid-item>

        <van-grid-item>
          <template #default>
            <div class="icon_div">
              <van-image width="40" height="40" :src="require('@/pages/user/authPage/img/renzheng-chezi-ok.png')
                " />
            </div>
            <div class="m_fk_sub_text m_fk_sub">车辆认证</div>
            <div class="m_fk_sub_text m_fk_sub">
              <router-link :to="{path:'/user/auth/authImg',query:{ authType: 3 }}">{{carState}}</router-link>
            </div>
            
          </template>
        </van-grid-item>


        <van-grid-item>
          <template #default>
            <div class="icon_div">
              <van-image width="40" height="40" :src="require('@/pages/user/authPage/img/xueli.svg')" />
            </div>
            <div class="m_fk_sub_text m_fk_sub">学历认证</div>
            <div class="m_fk_sub_text m_fk_sub">
              <router-link :to="{path:'/user/auth/authImg',query:{ authType: 2 }}">{{xlState}}</router-link>
            </div> 
          </template>
        </van-grid-item> 



      </van-grid>
    </div>

     
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue"; 
import { Toast } from "vant";
import { Grid, GridItem } from "vant";
import { authMap } from "@/common/constants/constants.js";
import { reqSelAuthOrderLog } from "@/api/api";
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Toast);
export default {
  //name填入本组件名字
  name: "AuthIndex",
  // components: {《组件名称》},
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      carState: "未认证", //车子认证状态
      houseState: "未认证", //房子认证状态
      sfState: "未认证", //身份认证状态
      gsState: "未认证", //个税认证状态
      xlState: "未认证", //学历认证状态
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    //获取用户认证状态
    async getData(authType) { 
      const params = {
        authType: authType, //认证类型 
      }; //请求参数
      const result = await reqSelAuthOrderLog(params);
      console.log("查询客户信息:", result.code);
      if (result.code === 0) {
        console.log("成功:", result.data);
        //把查到数据添加到当前页面数据对象中,转码
        if (result.data?.data?.length > 0) {
            result.data.records.forEach((element) => {
                if(element.authType == 1) {
                  this.gsState = authMap[element.status];
                  }else if(element.authType == 2) {
                  this.xlState = authMap[element.status];
                }else if(element.authType == 3) {
                  this.carState = authMap[element.status];
                }else if(element.authType == 4) {
                  this.houseState = authMap[element.status];
                }else if(element.authType == 0) {
                  this.sfState = authMap[element.status];
                }
            });
        }
      }
    }, 
           
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getData(0);
    this.getData(1);
    this.getData(2);
    this.getData(3);
    this.getData(4);
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类</style>