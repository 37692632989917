import wx from 'weixin-js-sdk'			//微信sdk依赖 
import { reqSelWeiXinShareConfig } from "@/api/api";


class WeChatShare {
  constructor() {
    this.wx = wx;
    this.isConfigured = false;
  }

  // 初始化微信配置
  async initWeChatConfig() {
    const url ={
      url: window.location.href.split('#')[0],
    };
    try {
      const res = await reqSelWeiXinShareConfig(url);
      if (!res.data) {
        throw new Error('微信配置获取失败');
      }
      const data = res.data;
      const appId =data.appId;
      const timestamp = data.timestamp;
      const nonceStr = data.nonceStr;
      const signature = data.signature;


      this.wx.config({
        debug: process.env.NODE_ENV !== 'production', // 生产环境关闭调试
        appId,
        timestamp,
        nonceStr,
        signature,
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'onMenuShareAppMessage',
          'onMenuShareTimeline'
        ]
      });

      this.wx.ready(() => {
        this.isConfigured = true;
        console.log('微信SDK配置完成');
      });

      this.wx.error(res => {
        console.error('微信SDK配置失败:', res);
        throw new Error('微信初始化失败');
      });

    } catch (error) {
      console.error('获取微信配置失败:', error);
      throw error;
    }
  }

  // 通用分享设置
  setShareConfig(config) {
    if (!this.isConfigured) {
      console.warn('请先初始化微信配置');
      return;
    }

    const defaultConfig = {
      title: '《脱单邀请函》',
      link: window.location.href,
      desc: '红线知音，红线寻觅知音',
      imgUrl: 'http://hongxian.cc/img/logo/logo.jpg'
    };

    const shareData = { ...defaultConfig, ...config };

    // 朋友分享
    this.wx.updateAppMessageShareData(shareData);
    // 朋友圈分享
    this.wx.updateTimelineShareData(shareData);
    // 自定义分享
    this.wx.onMenuShareAppMessage(shareData);
    this.wx.onMenuShareTimeline(shareData);
  }

  // 自定义页面分享
  setCustomShare(options) {
    this.setShareConfig({
      title: options.title,
      desc: options.desc,
      link: options.link || window.location.href,
      imgUrl: options.imgUrl
    });
  }
}

// 单例模式导出
export const weChatShare = new WeChatShare();