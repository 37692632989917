<!-- 活动模块 -->
<template>
  <div class="actity">
    <van-tabs v-model="actitytabs" animated>
      <van-tab v-for="day in dayObj" :key="day.day_text">
        <template #title>
          <div class="text_center">{{ day.week_text }}</div>
          <div class="text_center">{{ day.day_text }}</div>
        </template>

        <div class="card">
          <van-card v-for="item in day.activityList" :key="item.key" @click="goToInfo(item.activityId)">
            <template #thumb>
              <div class="tag_top">{{ item.activityType }}</div>
              <div class="tag_top_right">¥{{ item.totalFee }}</div>
              <van-image width="100%" height="200px" radius="1.2rem" :src="item.activityImageUrl" />
              <div class="card_title_position_left">
                <div class="card_title_heading">{{ item.title }}</div>
                <div class="card_title_subtitle">
                  {{ formatTime(item.eventStartTime) }}
                </div>
              </div>
              <div class="card_title_position_right">
                <div class="card_title_ren">
                  {{ item.limit_people ? item.limit_people + "人" : "不限" }}
                  <br />
                  名额
                </div>
              </div>
            </template>
          </van-card>
        </div>
      </van-tab>
    </van-tabs>
    <van-button class="my_button" @click="goToMyActivity" round type="info">我报名的</van-button>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { Card } from "vant";
import { Image as VanImage } from "vant";
import { reqSelActivity } from "@/api/api";
import logo from "@/pages/home/img/logo.jpg";
import util from "@/common/util/js/util";
Vue.use(VanImage);
Vue.use(Card);
Vue.use(Tab);
Vue.use(Tabs);
export default {
  //name填入本组件名字
  name: "actityPage",
  // components: {《组件名称》},
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      actitytabs: 0,
      //activityList: [],
      pageSize: 10,
      currentPage: 1,
      dayObj: {},
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    //获取当天0点时间戳

    async getData() {
      //let startTime = util.getTodayStartTime(); // 获取当天0点时间戳
      let endTime = util.getSevenDaysLaterTimestamp(); // 获取7天后时间戳
      const params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        endTime: endTime,
      }; //请求参数
      const result = await reqSelActivity(params);
      console.log("查询客户信息:", result.code);
      if (result.code === 0) {
        console.log("成功:", result.data);
        //处理星期，日期,获取7天对象
        this.dayObj = this.getSevenDaysDateArray();
        //把查到数据添加到当前页面数据对象中,转码
        if (result.data.records.length > 0) { 
           
          result.data.records.forEach((element) => {
            //赋值唯一标识
            element.key = element.activityId;

            if (element.activityImageUrl == null || element.activityImageUrl == "" ) {
              element.activityImageUrl =logo;
            }else{
              element.activityImageUrl = this.$config.imgUrl + element.activityImageUrl;
            } 

            //处理时间戳为年月日格式
            console.log(element.startTime);
            const start_text = util.formatDateToYearMonthDay(element.startTime); // 处理时间戳为年月日格式

            this.dayObj.forEach((dayObject) => {
              console.log(dayObject.day + "，" + start_text);
              console.log(dayObject.day === start_text);
              const sjc = util.dateToTimestampText(dayObject.day); //标题日期时间戳

              console.log(element.endTime + "，" + sjc);
              console.log(element.endTime >= sjc);
              //当天开始，加上去当天活动列表中，结束时间戳大于那天也要加列表

              //对比两个时间戳是不是同一天
              if (
                dayObject.day === start_text || util.areTimestampsSameDay(element.startTime, sjc)
                ||( 
                  element.endTime >= sjc)
                 
              ) {
                console.log("添加活动："+element.title);   
                dayObject.activityList.push(element);
              }
            });

            // this.activityList.push(element);
          });
          console.log("七天数据", this.dayObj);
        }else{
          console.log("暂无活动");
        }
      }
    },
    formatTime(time) {
      return util.timeStamp(time); // 在方法中引用 util
    },
    goToInfo(id) {
      this.$router.push({
        path: "/menu/activity/activityInfo",
        query: { activityId:id },
      });
    },
    goToMyActivity() {
      this.$router.push({
        path: "/menu/activity/myActivity",
      });
    },

    //获取从今天开始7天的日期数组(月日中文格式1月1日)
    getSevenDaysDateArray() {
      const dateArray = [];
      const today = new Date(); // 获取今天日期

      for (let i = 0; i < 7; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() + i); // 获取从今天开始的第 i 天

        // 格式化日期为 "YYYY年MM月DD日"
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1);
        //const month_bulin = String(date.getMonth() + 1).padStart(2, "0");//月份补零
        const day = String(date.getDate()); // 日期
        //const day_bulin = String(date.getDate()).padStart(2, "0"); // 日期补零
        const formattedDate = `${month}月${day}日`;

        //计算出星期几
        const days = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];
        const dayOfWeek = days[date.getDay()];
        //创建一个临时对象，用于存储日期和星期几
        var m_temp = {};
        m_temp.day = `${year}年${month}月${day}日`;
        m_temp.day_text = formattedDate;
        m_temp.week_text = dayOfWeek; 
        m_temp.activityList = []; //活动列表 
        dateArray.push(m_temp);
      }
      return dateArray;
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    //获取数据
    this.getData();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.van-card:not(:first-child) {
  margin-top: 0 !important;
  /* 使用 !important 提高优先级 */
}

.van-card__thumb {
  position: relative;
  /* 设置 position 属性 */
  z-index: 1;
  width: 100% !important;
  height: 200px !important;
}

.van-card__footer {
  position: relative;
  /* 设置 position 属性 */
  z-index: 2;
  text-align: initial !important;
  flex: auto !important;
  width: auto !important;
  height: 50px;
}

.footer_left_down {
  position: absolute;
  width: 75%;
  left: 0;
  /* 确保左侧对齐 */
  top: 0;
  /* 确保顶部对齐 */
  bottom: 0;
  /* 确保底部对齐 */
  z-index: 1;
  /* 设置较低的 z-index */
}

.footer_right_down {
  /* 注意类名拼写错误已更正 */
  position: absolute;
  width: 25%;
  right: 0;
  /* 确保右侧对齐 */
  top: 0;
  /* 确保顶部对齐 */
  bottom: 0;
  /* 确保底部对齐 */
  text-align: right;
  z-index: 1;
  /* 设置较低的 z-index */
}

.tag_top {
  position: absolute;
  z-index: 2;
  /* 设置较高的 z-index */
  top: 0;
  /* 确保顶部对齐 */
  left: 0;
  /* 确保左侧对齐 */
  font-size: 1.5em;
  background: linear-gradient(135deg, #ff5f6d 20%, #ffa0823d);
  padding: 0.3rem 0.6rem;
  border-radius: 1.2rem;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  color: #fff;
}

.tag_top_right {
  position: absolute;
  z-index: 2;
  /* 设置较高的 z-index */
  top: 0;
  /* 确保顶部对齐 */
  right: 0;
  /* 确保左侧对齐 */
  font-size: 1.5em;
  background: linear-gradient(135deg, #ff5f6d 20%, #ffa0823d);
  padding: 0.3rem 0.6rem;
  border-radius: 5rem;
  color: #fff;
  margin: 8px;
}

.card_title_position_left {
  position: absolute;
  width: auto;
  left: 0;
  /* 确保左侧对齐 */
  bottom: 2.5px;
  /* 确保底部对齐 */
  z-index: 2;
  /* 设置较高的 z-index */
  background: linear-gradient(135deg, #ff5f6d 20%, rgb(255 160 130 / 0%));
  color: #fff;
  border-bottom-left-radius: 1.2rem;
  border-top-right-radius: 0.5rem;
}

.card_title_position_right {
  position: absolute;
  width: 25%;
  right: 0;
  /* 确保左侧对齐 */
  bottom: 5px;
  /* 确保底部对齐 */
  z-index: 2;
  /* 设置较高的 z-index */
  background: linear-gradient(335deg, #ff5f6d 30%, #7232dd21 90%);
  color: #fff;
  border-bottom-right-radius: 1.2rem;
  border-top-left-radius: 1.2rem;
}

.card_title_heading {
  margin-left: 10px;
  font-size: 2em;
  font-weight: 600;
}

.card_title_subtitle {
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 1.5em;
}

.card_title_ren {
  font-size: 1.5em;
  text-align: center;
}

.my_button {
  //悬浮按钮，悬浮在右下角
  position: fixed;
  right: 20px;
  bottom: 130px;
  z-index: 999;
}

.text_center {
  text-align: center;
}
</style>