<!--  -->
<template>
  <div class="">
    <van-grid>
      <van-grid-item text="" />
      <van-grid-item icon="contact-o" text="普通会员" /> 
      <van-grid-item :icon="require('./img/vip.svg')" text="vip线上会员" />
      <van-grid-item :icon="require('./img/svip.svg')" text="SVIP线下会员" />
    </van-grid>
    <van-grid>
      <van-grid-item text="红娘线下一对一匹配" />
      <van-grid-item icon="cross" class="cross_color" />
      <van-grid-item icon="cross" class="cross_color" />
      <van-grid-item icon="success" class="success_color" />
    </van-grid>
    <van-grid>
      <van-grid-item text="牵线会员锁定(锁定对象，牵线期间双方不会平台会员联系)" />
      <van-grid-item icon="cross" class="cross_color" />
      <van-grid-item icon="cross" class="cross_color" />
      <van-grid-item icon="success" class="success_color" />
    </van-grid>
    <van-grid>
      <van-grid-item text="会员专属颜色" />
      <van-grid-item icon="cross" class="cross_color" />
      <van-grid-item icon="success" class="success_color" />
      <van-grid-item icon="success" class="success_color" />
    </van-grid>
    <van-grid>
      <van-grid-item text="线上匹配" />
      <van-grid-item icon="cross" class="cross_color" />
      <van-grid-item icon="success" class="success_color" />
      <van-grid-item icon="success" class="success_color" />
    </van-grid>
    <van-grid>
      <van-grid-item text="活动权限(发布活动，参加限制活动，抢先报名) " />
      <van-grid-item icon="cross" class="cross_color" />
      <van-grid-item icon="success" class="success_color" />
      <van-grid-item icon="success" class="success_color" />
    </van-grid>
    <van-grid>
      <van-grid-item text="名片展示收藏访客隐身" />
      <van-grid-item icon="cross" class="cross_color" />
      <van-grid-item icon="success" class="success_color" />
      <van-grid-item icon="success" class="success_color" />
    </van-grid>
    <van-grid>
      <van-grid-item text="会员资料 会员授权采集征信信息" />
      <van-grid-item icon="cross" class="cross_color" />
      <van-grid-item icon="success" class="success_color" />
      <van-grid-item icon="success" class="success_color" />
    </van-grid>
    <van-grid>
      <van-grid-item text="价格" />
      <van-grid-item text="" />
      <van-grid-item text="原价900元/季度，折扣优惠300元/季度" />
      <van-grid-item text="3000元/季度" />
    </van-grid>
    <van-grid>
      <van-grid-item text="" />
      <van-grid-item text="" />

    <van-grid-item>
      <template #default>
        <router-link to="/home/vip/vip">
          <div class="min_font"></div>
          <van-button type="primary">开通VIP</van-button>
        </router-link>
      </template>
    </van-grid-item>
    <van-grid-item>
      <template #default>
        <router-link to="/home/vip/vip">
          <van-button type="primary">开通SVIP</van-button>
        </router-link>
      </template>
    </van-grid-item> 
  </van-grid>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import { Grid, GridItem } from "vant";
import { Image as VanImage } from "vant";
import { Dialog } from "vant";
import { Button } from 'vant';

Vue.use(Button);
Vue.use(VanImage);
Vue.use(Grid);
Vue.use(GridItem);
export default {
  //name填入本组件名字
  name: "vipPage",
  // components: {《组件名称》},
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {};
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    showDialog() {
      Dialog.alert({
        message: "点击确认立即前往VIP开通页面",
      }).then(() => {
        // on confirm
        this.$router.push("/home/vip/introduce");
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类

.success_color {
  color: #07c160;
}

.cross_color {
  color: #ee0a24;
}
</style>